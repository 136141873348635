import { Box, FormControlLabel, Grid,RadioGroup,Skeleton,Typography,useMediaQuery } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'
import CustomLogoButton from 'ui-components/formik/CustomLogoButton'
import CustomInputField from 'ui-components/formik/CustomInputField'
import placeHolderImage from "assets/placeholder_image.png"
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AddStartup, FetchAllCities, FetchAllCountries, FetchAllCountriesWithPhoneCodes, FetchAllPopularCities, FetchAllPopularCountries, FetchAllStages, FetchAllStates } from '../../../api'
import CustomLabel from 'ui-components/CustomLabel'
import moment from 'moment'
import { useTheme } from '@emotion/react'
import CustomValidationText from 'ui-components/CustomValidationText'
import CustomRadio from 'ui-components/CustomRadio'

const StartupInfo = () => {
    const formik = useFormikContext()
    const theme = useTheme();
  return (
    <Grid container xs={12} gap={0} sx={{ overflowX: "hidden" }}>
        <Grid item xs={12} sm={12}>
            <CustomLogoButton label="LOGO" handleUpdateFile={(file) => { formik.setFieldValue("startup_logo", file) }} name={"startup_logo"} value={formik.values.startup_logo} placeholder={placeHolderImage} Pheight={100} Pwidth={100} />
            {/* <CustomUploadButton label="LOGO" handleUpdateFile={(evt) => { formik.setFieldValue("startup_logo", evt.target.files[0]) }} selectedFile={formik.values.startup_logo} /> */}
        </Grid>
        {/* <Grid item xs={12} sm={12} marginTop={2}>
        <CustomInputField value={formik.values.startup_name} label={"NAME"} placeholder={"Enter startup name"} name="startup_name" onChange={(e) => formik.setFieldValue("startup_name", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
    </Grid> */}
        <Grid item xs={12} sm={12} marginTop={2}>
            <CustomInputField value={formik.values.entity_name} label={"LEGAL ENTITY NAME"} placeholder={"Enter legal entity name"} name="entity_name" onChange={(e) => formik.setFieldValue("entity_name", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
        </Grid>
        <Grid item xs={12} paddingBottom={4}>
            {/* {console.log(formik.values?.formation_date,"formik.values?.formation_date")} */}
            <CustomLabel>FORMATION DATE</CustomLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label=""
                    sx={{ width: "100%", background: "#fff", marginBottom: 0 }}
                    // Parse the ISO string to a Moment object
                    value={formik.values?.formation_date ? moment(formik.values.formation_date) : null}
                    disableFuture={true}
                    onChange={(date) => {
                        // if (date?.isValid()) {
                        // Convert Moment object back to ISO string
                        formik.setFieldValue('formation_date', date != null ? date.toISOString() : null);
                        // }
                    }}
                    renderInput={(params) => (
                        <CustomInputField
                            sx={{ padding: "10px 14px" }}
                            disabled
                            fullWidth
                            {...params}
                        />
                    )}
                    inputFormat="DD/MM/YYYY"
                    autoComplete="off"
                />
            </LocalizationProvider>
            {formik.errors?.formation_date && <CustomValidationText value={formik.errors.formation_date} />}
            {/* )
        } */}
        </Grid>
    </Grid>
  )
}

export default StartupInfo
