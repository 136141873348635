

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    data: null
}

const startupDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STARTUP_DETAILS:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}

export default startupDetailsReducer
