import { Box, Button, CircularProgress, Divider, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Modal, Paper, Select, Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import linkedInIcon from '../../../assets/linkedin_icon.png'
import { B300 as CustomButton, BLUEBORDER, WHITEBG } from 'ui-components/CustomButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from '@mui/x-date-pickers'
import startupInformationIcon from "assets/startup_information.png"
import referralDetailsIcon from "assets/referral_details.png"
import startupDetailsIcon from "assets/startup_details.png"
import AddIcon from "assets/add_button.png"
import EditIcon from "assets/edit.png"
import DeleteIcon from "assets/delete.png"
import { FieldArray, Form, FormikProvider, useFormik, useFormikContext } from 'formik'
// import RedirectionPopup from './popups/login/RedirectionPopup'
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import CustomUrlField from 'ui-components/CustomUrlField'
import CustomInputField from 'ui-components/formik/CustomInputField'
import CustomBackgroundComponent from 'ui-components/CustomBackgroundComponent'
import CustomLabel from 'ui-components/CustomLabel'
import moment from 'moment'
import * as Yup from "yup"
import toast from 'react-hot-toast'
import { AddStartup, FetchAllCategories, FetchAllCurrencyLists, FetchAllRoundTypes, FetchAllSectors, FetchAllStages, FetchAllSubCategories } from 'api'
import Cookies from 'js-cookie'
import { useTheme } from '@emotion/react'
import CustomLogoButton from 'ui-components/formik/CustomLogoButton'
import CustomRichTextEditor from 'ui-components/formik/CustomRichTextEditor'
import CustomInfoIcon from 'ui-components/CustomInfoIcon'
import CustomToast from 'ui-components/CustomToast'
import placeHolderImage from "assets/placeholder_image.png"
import AuthWrapper1 from 'ui-components/AuthWrapper1'
import CustomHeaderIcon from 'ui-components/CustomHeaderIcon'
import { containerRef, currencyInputProps, getFileCategory, getFileIcon, handleFormSubmit } from 'utilities'
import CustomValidationText from 'ui-components/CustomValidationText'
import CustomAutoComplete from 'ui-components/CustomAutoComplete'
import { ReactComponent as gpsIcon } from "assets/gps.svg"
import { ReactComponent as UploadDocumentsIcon } from "assets/rounds_icons/upload_documents_icon.svg";
import { styled } from '@mui/styles'
import { LocalMallOutlined, MemoryOutlined, MiscellaneousServicesOutlined, SkipNextRounded, StorefrontOutlined } from '@mui/icons-material'
import StartupInfo from './StartupInfo'
import SelectCategory from './SelectCategory'
import RoundDetails from './RoundDetails'
import StartupDetails from './StartupDetails'
import StartupOnlinePresence from './StartupOnlinePresence'
import FounderInformation from './FounderInformation'
import CoFounderInformation from './CoFounderInformation'
import ReferralDetails from './ReferralDetails'
import { ConnectedFocusError, FocusError } from 'focus-formik-error'
import StartupProfile from './StartupProfile'
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const referral_types = [
    {
        value: "Friends",
        label: "Friends",
    },
    {
        value: "Incubator",
        label: "Incubator",
    },
    {
        value: "LinkedIn",
        label: "LinkedIn",
    },
    {
        value: "Google",
        label: "Google",
    },
    {
        value: "Other",
        label: "Other",
    },
];

const inputProps = {
    maxLength: 1, // Restrict input to a single character
    inputMode: 'numeric', // Use numeric keyboard on mobile devices
    pattern: "[0-9]*", // Only allow digits 0-9
    // title: "Please enter a number between 0 and 9",
    style: {
        textAlign: 'center', // Center the text in the input
    },
    min: 0,
    onKeyDown: (event) => {
        // Prevent the minus sign (-) from being entered
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Get the input value
        const inputValue = event.key;

        // Allow only numeric characters (0-9) and certain control keys
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];

        // Check if the pressed key is in the allowedKeys array
        if (!allowedKeys.includes(inputValue)) {
            // If not, prevent the key from being entered
            event.preventDefault();
        }
    },
    onInput: (event) => {
        // Get the input value
        const inputValue = event.target.value;

        // Remove non-numeric characters using a regular expression
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        // Update the input field value with the filtered numeric value
        event.target.value = numericValue;
    },
    onKeyDown: (event) => {
        // Prevent the input of minus sign (key code 189)
        if (event.keyCode === 189 || event.key === "-") {
            event.preventDefault();
        }
    },
}

const StartupInformation = ({ progress, setProgress, step, formationDate, setFormationDate, formationDateError, currentStep, setCurrentStep, isLoading, isUpdating }) => {
    const formik = useFormikContext();
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isTablet = useMediaQuery(theme.breakpoints.up('sm'))

    // const [currentStep, setCurrentStep] = React.useState(step ? step : 1);
    // const { mutateAsync: AddStartupAsync, status: AddStartupStatus, error: AddStartupError, isLoading: isVerifying, data: AddStartupResponse } = AddStartup();
    const { data: startup_stages, isRefetching: isRefetchingStages, isLoading: isLoadingStages, refetch: refetchStages } = FetchAllStages()
    const { data: round_types, isRefetching: isRefetchingRoundTypes, isLoading: isLoadingRoundTypes, refetch: refetchRoundTypes } = FetchAllRoundTypes();

    const { data: startup_sectors, isRefetching: isRefetchingSectors, isLoading: isLoadingSectors, refetch: refetchSectors } = FetchAllSectors()
    const [currentSector, setCurrentSector] = React.useState('');
    const [searchSector, setSearchSector] = React.useState('');
    const handleSearchSectorChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchSector(value);
        // refetchCities();
    };
    const handleSelectionSectorChange = (event, selected) => {
        // console.log(value, "value")
        formik.setFieldValue("sector_id", selected ? selected?.value : '');
        setCurrentSector(selected)
        event.preventDefault();
    };
    const handleSearchUpdateSector = () => {
        refetchSectors();
    }

    const [isPressed, setIsPressed] = useState(true)

    const [currentRoundType, setCurrentRoundType] = React.useState('');
    const [searchRoundType, setSearchRoundType] = React.useState('');
    const handleSearchRoundTypeChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchRoundType(value);
        // refetchCities();
    };
    const handleSelectionRoundTypeChange = (event, selected) => {
        // console.log(selected, "value")
        formik.setFieldValue("round_type", selected ? selected?.value : '');
        setCurrentRoundType(selected)
        event.preventDefault();
    };
    const handleSearchUpdateRoundType = () => {
        refetchSectors();
    }

    const [selectedCategory, setSelectedCategory] = React.useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentSubCategory, setCurrentSubCategory] = React.useState('');
    const [searchSubCategory, setSearchSubCategory] = React.useState('');
    const { data: startup_categories, isRefetching: isRefetchingCategories, isLoading: isLoadingCategories, refetch: refetchCategories } = FetchAllCategories()
    const { data: startup_sub_categories, isRefetching: isRefetchingSubCategories, isLoading: isLoadingSubCategories, refetch: refetchSubCategories } = FetchAllSubCategories({ category: selectedCategory })

    // Effect to set selected category based on formik values
    React.useEffect(() => {
        if (!isLoading && !isLoadingCategories && formik?.values && Array.isArray(startup_categories)) {
            const category = startup_categories.find(item => item?._id === formik?.values?.category_id);
            if (category) {
                setSelectedCategory(category.name);  // Or whichever property you want to use
            }
        }
    }, [isLoading, isLoadingCategories, formik?.values, startup_categories, formik?.values?.category_id]);

    // console.log(formik.values, "rrrrr")
    // Effect to refetch subcategories when selectedCategory changes
    React.useEffect(() => {
        if (!isRefetchingCategories && !isLoadingCategories && selectedCategory) {
            refetchSubCategories();
        }
    }, [selectedCategory, isRefetchingCategories || isLoadingCategories]);

    React.useEffect(() => {
        if (formik.values.sector_id && startup_sub_categories) {
            const matchedSubCategory = startup_sub_categories.find(subCat => subCat._id === formik.values.sector_id);
            if (matchedSubCategory) {
                setCurrentSubCategory({ label: matchedSubCategory?.sub_category, value: matchedSubCategory?._id, description: matchedSubCategory?.description });
            }
        }
    }, [formik.values.sector_id, startup_sub_categories]);

    const handleSearchSubCategoryChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchSubCategory(value);
        // refetchCities();
    };
    const handleSelectionSubCategoryChange = (event, sector) => {
        // console.log(value, "value")
        formik.setFieldValue("sector_id", sector ? sector?.value : '');
        setCurrentSubCategory(sector)
        event.preventDefault();
    };
    const handleSearchUpdateSubCategory = () => {
        refetchSubCategories();
    }

    const scrollbarRef = React.useRef(null);

    const scrollTo = (fieldName) => {
        setTimeout(() => {
            if (scrollbarRef.current) {
                const targetRef = document.getElementsByName(fieldName)[0];

                if (!targetRef) {
                    console.error(`Element with name "${fieldName}" not found`);
                    return;
                }

                // Scroll the target element into view within the custom scrollbar
                targetRef.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }, 100);
    };

    // React.useEffect(() => {
    //     if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
    //         const firstErrorKey = Object.keys(formik.errors).find(key => formik.touched[key]);
    //         if (firstErrorKey) {
    //             // Delay the scroll slightly to ensure DOM has updated
    //             setTimeout(() => {
    //                 scrollTo(firstErrorKey);
    //             }, 50);
    //         }
    //     }
    // }, [formik.isSubmitting, formik.errors]);

    React.useEffect(() => {
        if (formik.values.round_type && round_types) {
            const matchedRoundType = round_types.find(subCat => subCat._id === formik.values.round_type);
            if (matchedRoundType) {
                setCurrentRoundType({ label: matchedRoundType?.name, value: matchedRoundType?._id, description: matchedRoundType?.sub_title });
            }
        }
    }, [formik.values.round_type, round_types]);
    const { data: currencyList, isLoading: isLoadingCurrency } =
        FetchAllCurrencyLists();
    React.useEffect(() => {
        if (!isLoadingCurrency && formik.values) {
            formik.setFieldValue("currency_id", formik.values?.currency_id ? formik.values?.currency_id : currencyList[0]?._id)
            formik.setFieldValue("currency", currencyList?.find(item => formik.values?.currency_id === item?._id)?.code || currencyList[0]?.code);
        }
    }, [isLoadingCurrency, formik.values]);

    // const scrollToFirstVisibleValidation = () => {
    //     // Query all elements with the class "validation-message"
    //     const validationMessages = document.querySelectorAll('.validation-message');
    //     // console.log(validationMessages, "dddd")

    //     // Find the first visible validation message
    //     for (const message of validationMessages) {
    //         if (message && message.offsetParent !== null) {
    //             message.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //             break;
    //         }
    //     }
    // };

    return (
        <>
            {/* <AuthWrapper1>
                <Grid xs={12} container display={"flex"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }} sx={{
                    maxWidth: '1440px !important',
                    marginInline: 'auto',
                    height: '100%',
                    maxHeight: { xs: 600, sm: 768, md: 800, lg: 960 }
                }}>
                    <Grid item xs={12} sm={12} md={6.6} display={"grid"} sx={{ padding: { xs: "1rem 1rem 1rem 1rem", sm: "1rem 2rem", md: "2rem 2rem 1rem 2rem", lg: "2rem 4rem 2rem 5rem" }, height: '100%' }} gap={"1rem"}> */}

            <FocusError formik={formik} />
            <Grid xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"column"} sx={{ maxHeight: "80vh", overflow: "hidden" }}>
                <CustomHeaderIcon customIcon={currentStep >= 6 ? (currentStep === 10 ? startupInformationIcon : currentStep === 11 ? startupInformationIcon : currentStep === 12 ? referralDetailsIcon : startupDetailsIcon) : startupInformationIcon} />
                <Typography variant='title' marginTop={1} sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" }, position: 'relative' }} textAlign={{ xs: "center", md: "start" }} >
                    {currentStep > 6 ?
                        (currentStep === 7 ? "Round Details" : currentStep === 10 ? "Founder Information" : currentStep === 11 ? <span>Co-founder Information</span> : currentStep === 12 ? "Referral Details" : "Startup Details") : currentStep === 6 ? "Select Category of Your Startup" : "Startup Information"}
                    {(currentStep === 5 || currentStep === 11) && <Typography
                        variant='span'
                        onClick={() => {
                            setCurrentStep(currentStep + 1);
                            Cookies.set("step", currentStep + 1)
                            // setSearchParams({ page: currentStep + 1 });
                        }}
                        sx={{
                            cursor: "pointer",
                            opacity: isPressed ? 1 : 0.2,
                            position: 'absolute',
                            right: 0,
                            top: { xs: "-4rem", md: 0 },
                            marginRight: "1rem",
                            display: "flex",
                            alignItems: 'center'
                            // marginBottom: "1rem",
                        }}
                        onMouseDown={() => setIsPressed(false)}
                        onMouseUp={() => setIsPressed(true)}
                        color={theme?.palette.customColors.b300}
                        fontSize="1rem"
                    >
                        <span>Skip</span><SkipNextRounded />
                    </Typography>}
                </Typography>
                {currentStep === 4 && <>
                    <Typography marginBottom={2} color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginTop={1}>Provide the following information for your startup</Typography>
                </>}
                {currentStep === 5 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1} marginBottom={2}>Provide the following information for your startup</Typography>}
                {currentStep === 6 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1} marginBottom={2}>Provide sector information for your startup</Typography>}
                {/* {currentStep === 6 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>Provide stage information for your startup</Typography>} */}
                {currentStep === 7 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1} marginBottom={2}>Provide the following information for your current round</Typography>}
                {currentStep === 8 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1} marginBottom={2}>Provide the following information for your startup</Typography>}
                {currentStep === 9 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1} marginBottom={2}>Share your online presence</Typography>}
                {currentStep === 10 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1} marginBottom={2}>Provide details about founder(s)</Typography>}
                {currentStep === 11 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1} marginBottom={0}>Provide details about co-founder(s)</Typography>}
                {currentStep === 12 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1} marginBottom={2}>Tell us how did you hear about Backrr</Typography>}
                {/* {currentStep === 6 &&
                                        <>
                                            <label style={{ marginTop: '0rem', fontSize: '0.875rem', color: '#0f1319', marginBottom: '8px' }} >
                                                WHAT IS THE SECTOR OF YOUR STARTUP?
                                                {formik.errors.sector_id && formik.touched.sector_id && (
                                                    <Typography
                                                        component={isDesktop ? "span" : isTablet ? "span" : "p"}
                                                        style={{
                                                            color: "rgb(239, 83, 80)",
                                                            fontWeight: "400",
                                                            fontSize: "0.75rem",
                                                            lineHeight: "1.66",
                                                            letterSpacing: "0.03333em",
                                                            textAlign: "left",
                                                            marginTop: "3px",
                                                            marginRight: "14px",
                                                            marginBottom: "0",
                                                            marginLeft: "14px",
                                                        }}
                                                    >
                                                        {formik.errors.sector_id}
                                                    </Typography>
                                                )}
                                            </label>
                                        </>
                                    } */}

                <Box
                    ref={scrollbarRef}
                    //  containerRef={containerRef}
                    style={{
                        minHeight: "35vh", height: '100%',
                        paddingRight: isDesktop ? "0.5rem" : 0,
                        //  maxHeight: 'calc(100vh - 205px)',
                        overflowX: 'hidden', marginTop: ("0"), paddingBottom: currentStep === 5 || currentStep === 6 ? "0.5rem" : '0.5rem'
                    }} className="scrollbar-container" id="onboarding-scroller">

                    {/* <Grid container id="NormalScrollbar" sx={{overflowY: 'auto' , minHeight: "35vh" , height:"100%", maxHeight: {xs: "100%", md: "47.5vh", lg: "47.5vh", xl: "47.5vh"}, alignContent: 'flex-start'}}> */}
                    {currentStep === 4 &&
                        <>
                            <StartupProfile />
                        </>}
                    {currentStep === 5 &&
                        <>
                            <StartupInfo />
                        </>}
                    {currentStep === 6 && (
                        <>
                            <SelectCategory />
                        </>
                    )}


                    {currentStep === 7 && <>
                        <RoundDetails />
                    </>}
                    {currentStep === 8 && <>
                        <StartupDetails />
                    </>}
                    {currentStep === 9 && <>
                        <StartupOnlinePresence />
                    </>}
                    {currentStep === 10 && <>
                        {/* {console.log(formik.values?.founder, "founder")} */}
                        <FounderInformation />
                    </>}
                    {currentStep === 11 && <>
                        <CoFounderInformation />

                    </>}
                    {currentStep === 12 && <>
                        <ReferralDetails />
                    </>}
                    {/* </Grid> */}
                </Box>
            </Grid>
            <Grid item container maxHeight={"5.5vh"} xs={12} display={"flex"} alignItems={"flex-end"} justifyContent={"center"} gap={3}>
                <Grid xs={5} md={5.5} lg={5.65} item>
                    <BLUEBORDER sx={{ textTransform: "none", padding: 2, borderWidth: 1, borderRadius: "8px", height: '56.5px' }} onClick={(e) => {
                        e.preventDefault();
                        if (currentStep === 4) {
                            navigate("/thank-you")
                        } else {
                            setCurrentStep(currentStep - 1);
                            Cookies.set("step", currentStep - 1)
                            window.scrollTo(0, 0);
                        }

                    }} fullWidth>Back</BLUEBORDER>
                </Grid>
                <Grid xs={5} md={5.5} lg={5.65} item>
                    <CustomButton sx={{ padding: 2, borderRadius: "8px", height: '56.5px' }} fullWidth onClick={(e) => {
                        handleFormSubmit(e, formik)
                    }}>{isUpdating ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} /> : currentStep === 12 ? "Submit" : "Next"}</CustomButton>
                </Grid>
            </Grid>
            {/* </Grid>
                    <CustomBackgroundComponent />
                </Grid>
            </AuthWrapper1> */}
        </>
    )
}

export default StartupInformation