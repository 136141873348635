import { Box, Grid,useMediaQuery } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'
import CustomPhone from 'ui-components/CustomPhone'
import CustomInputField from 'ui-components/formik/CustomInputField'
import { AddStartup, FetchAllCities, FetchAllCountries, FetchAllCountriesWithPhoneCodes, FetchAllPopularCities, FetchAllPopularCountries, FetchAllStages, FetchAllStates } from '../../../api'
import CustomAutoComplete from 'ui-components/CustomAutoComplete'
import CustomLabel from 'ui-components/CustomLabel'
import { ReactComponent as gpsIcon } from "../../../assets/gps.svg"
import { useTheme } from '@emotion/react'


const SelectCountry = ({currentCountry, setCurrentCountry ,searchCountry ,setSearchCountry }) => {
    const formik = useFormikContext()
    const theme = useTheme();
    // const [searchCountry, setSearchCountry] = React.useState("")
    const [search, setSearch] = React.useState("")
    const [searchState, setSearchState] = React.useState("")
    const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: searchCountry })
    // const [currentCountry, setCurrentCountry] = React.useState(countries?.find(item => item?._id == formik?.values?.country_id))
    const { data: states, isRefetching: isRefetchingStates, isLoading: isLoadingStates, refetch: refetchStates } = FetchAllStates({ search: searchState, countryID: currentCountry !== null ? countries?.find(item => item?._id == formik.values?.country_id)?.value : currentCountry?.value })
    const [currentState, setCurrentState] = React.useState(states?.find(item => item?.value === formik?.values?.state_id)?.label || '')
    const { data: cities, isRefetching: isRefetchingCities, isLoading: isLoadingCities, refetch: refetchCities } = FetchAllCities({ search, stateID: currentState !== null ? states?.find(item => item?._id == formik.values?.state_id)?.value : currentState?.value, isPopular: 0 });
    const [currentCity, setCurrentCity] = React.useState(cities?.find(item => item?.value === formik?.values?.city_id)?.label || '');
    const { data: popular_countries, isRefetching: isRefetchingPopularCountries, isLoading: isLoadingPopularCountries, refetch: refetchPopularCountries } = FetchAllPopularCountries()

    const handleSearchCountryChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchCountry(value);
        // refetchCities();
    };
    const handleSelectionCountryChange = (event, value) => {
        // console.log(value, "value")
        formik.setFieldValue("country_id", value ? value?._id : '');
        setCurrentCountry(value)
        formik.setFieldValue("state_id", null);
        setCurrentState(null)
        setCurrentCity(null);
        formik.setFieldValue("city_id", null)
        setSearchState("")
        setSearch("")
        refetchData()
        event.preventDefault();
    };
    const handleSearchUpdateCountry = () => {
        refetchCountries();
    }
    const handleUpdateCountryName = (country) => {
        formik.setFieldValue("country_id", country?._id)
        // console.log(country, "country")
        setCurrentCountry(country)
        formik.setFieldValue("state_id", null);
        setCurrentState(null)
        setCurrentCity(null);
        formik.setFieldValue("city_id", null)
        setSearchState("")
        setSearch("")
    }
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesUpXs = useMediaQuery(theme.breakpoints.up('xs'));
    const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
    const dimensions = matchUpLg ? { width: "5rem", height: "5rem", marginTop: 'auto' } : matchesUpMd ? { width: "4.5rem", height: "4.5rem", marginTop: 'auto' } : matchesUpMd ? { width: "6rem", height: "6rem", marginTop: 'auto' } : { width: "4rem", height: "4rem", marginTop: 'auto' }
    const refetchData = (type) => {
        if (type === "city") {
            refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 0)
            })
        }
        else {
            formik.values?.country_id != undefined && refetchStates().then(res => {
                setCurrentState(res?.data?.filter(item => item?._id == formik.values.state_id)[0]?.label || '');
                setSearchState("");
                // setSearchState(res?.data?.find(item => item?.value == formik.values.state_id)?.label || '');
            })

            formik.values?.state_id != undefined && refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response?.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 200)
            })

        }
        // refetchPopularCities()
    }

  return (
    <>
      {/* {console.log(countries,"countries")} */}
      {!isLoadingCountries && !isRefetchingCountries ? <CustomAutoComplete
        options={!isLoadingCountries && !isRefetchingCountries ? countries : []}
        label="SEARCH COUNTRY"
        name={"country_id"}
        noOptionsText={!isLoadingCountries && !isRefetchingCountries ? "No Options" : "Searching..."}
        placeholder="Type a country name"
        icon={gpsIcon}
        handleSearchChange={handleSearchCountryChange}
        handleSelectionChange={handleSelectionCountryChange}
        search={searchCountry}
        value={currentCountry}
        defaultValue={countries?.find(item => item?._id == formik?.values?.country_id)}
        loading={isRefetchingCountries}
        handleSearchUpdate={handleSearchUpdateCountry}
    /> : <>
        <Box className="skeleton" sx={{ height: 17.6, width: '100%', marginBottom: 1 }}></Box>
        <Box className="skeleton" sx={{ height: 50.6, width: '100%', marginBottom: 2 }}></Box>
        {/* <Box className="skeleton" sx={{ height: 17.6, width: '100%', marginBottom: 1 }}></Box> */}
    </>}
    <CustomLabel>POPULAR COUNTRIES</CustomLabel>
    <Grid item container xs={12} gap={2} display={"flex"} justifyContent={"space-between"} paddingBottom={{ xs: "0rem", lg: "0.8rem" }}>
        {popular_countries?.map((country, index) => <Grid key={index} onClick={() => handleUpdateCountryName(country)} item xs={5.5} sm={3.7} md={3.65} lg={3.65} flexGrow={1}
        >
            <button onClick={(e) => e.preventDefault()} style={{
                minHeight: {xs: "4.5rem", sm: "3.1rem"},
                maxHeight: "7rem",
                height: "100%",
                padding: "0.5rem 0.2rem 0rem 0.5rem",
                background: formik.values.country_id === country?._id ? "#FFFAE6" : "#FFF",
                fontSize: "0.875rem",
                cursor: 'pointer',
                border: `1px solid ${formik.values.country_id === country?._id ? "#FFC900" : "#A1A3A7"}`,
                borderRadius: "8px",
                overflow: "hidden",
                display: "flex",
                width: '100%',
                justifyContent: "space-between"
            }}>

                <span style={{ textAlign: 'left' }}>{country?.label}</span>
                <img loading='lazy' rel="preload" src={country?.image_url} style={{ ...country?.size, ...dimensions, marginLeft: 'auto' }} />
            </button>
        </Grid>
        )}
    </Grid>
    </>
  )
}

export default SelectCountry
