import { useTheme } from '@emotion/react'
import { Box, Grid } from '@mui/material'
import { FetchAllStartupFeedbacks } from 'api/profile'
import React from 'react'
import { useSelector } from 'react-redux'
import AsyncTableListing from 'ui-components/AsyncTableListing'

const ViewFeedback = () => {
    const theme = useTheme()
    const details = useSelector((state) => state?.startupDetails?.data);
    const column = [
        { Header: 'Name', accessor: 'name', minWidth: 150, align: 'start', fixed: true },
        { Header: 'Location', accessor: 'location', minWidth: 150, align: 'center' },
        { Header: 'Date', accessor: 'formatted_date', minWidth: 150, align: 'center' },
        { Header: 'Problem', accessor: 'problem', minWidth: 150, align: 'center' },
        { Header: 'Market', accessor: 'market', minWidth: 150, align: 'center' },
        { Header: 'Product', accessor: 'product', minWidth: 150, align: 'center' },
        { Header: 'Team', accessor: 'team', minWidth: 150, align: 'center' },
        { Header: 'Traction', accessor: 'traction', minWidth: 150, align: 'center' },
        { Header: 'Investible', accessor: 'investible', minWidth: 50, align: 'center' },
        { Header: 'Feedback', accessor: 'feedback', minWidth: 200, align: 'center', tooltip: 'feedback' },
    ];

    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(10);
    const [sort, setSort] = React.useState(-1);
    const [search, setSearch] = React.useState(-1);

    // const data = [
    //     {
    //         name: 'John Doe',
    //         location: 'New York',
    //         date: '01-NOV-2024',
    //         problem: 1.5,
    //         market: 4.5,
    //         product: 4,
    //         team: 3.5,
    //         traction: 4,
    //         investible: 'Yes',
    //         feedback: 'Good progress overall.'
    //     },
    //     {
    //         email: 'jane.smith@example.com',
    //         location: 'Los Angeles',
    //         date: '15-NOV-2024',
    //         problem: 4,
    //         market: 4,
    //         product: 5,
    //         team: 4.5,
    //         traction: 3.5,
    //         investible: 'No',
    //         feedback: 'Need to improve traction.'
    //     },
    //     {
    //         name: 'Alice Johnson',
    //         location: 'Chicago',
    //         date: '20-NOV-2024',
    //         problem: 3.5,
    //         market: 4,
    //         product: 4.5,
    //         team: 4,
    //         traction: 5,
    //         investible: 'Yes',
    //         feedback: 'Excellent product and team.'
    //     },
    //     {
    //         email: 'bob.brown@mail.com',
    //         location: 'Houston',
    //         date: '25-NOV-2024',
    //         problem: 4.5,
    //         market: 3,
    //         product: 4,
    //         team: 4,
    //         traction: 3,
    //         investible: 'Yes',
    //         feedback: 'Average market understanding.'
    //     },
    //     {
    //         name: 'Charlie White',
    //         location: 'Miami',
    //         date: '30-NOV-2024',
    //         problem: 3,
    //         market: 4.5,
    //         product: 4.5,
    //         team: 4,
    //         traction: 4,
    //         investible: 'Yes',
    //         feedback: 'Strong market potential.'
    //     },
    // ];

    // const { data: feedbacksData, isLoading: isLoadingFeedbacks, mutateAsync: mutateAsyncFeedbacks } = FetchAllStartupFeedbacks()
    const { data: feedbacksData, isLoading: isLoadingFeedbacks, isRefetching } = FetchAllStartupFeedbacks({ id: details ? details?._id : '', page, limit, sort })

    // console.log(feedbacksData, 'feedbacksData')
    // React.useEffect(() => {
    //     details && mutateAsyncFeedbacks({ startup_id: details ? details?._id : '', page, limit, sort, search })
    // }, [details])

    const formattedFeedbacksData = feedbacksData?.data && feedbacksData?.data?.length ? feedbacksData?.data?.map(item => ({
        // ...item,
        ...item?.startup_feedbacks?.ratings,
        name: item?.name ? item?.name?.length > 0 ? item?.name : item?.email : item?.email,
        feedback: item?.startup_feedbacks?.feedback,
        investible: item?.startup_feedbacks?.investible ? "Yes" : "No",
        formatted_date: item?.formatted_date,
        location: `${item?.city ? item?.city + ', ' : ''}${item?.state ? item?.state + ', ' : ''}${item?.country ? item?.country : ''}`
    })) : []





    return (
        <>
            <Grid container xs={12} pt={2} pb={0} spacing={2} display={"flex"} justifyContent={"space-between"} marginLeft={0} alignContent={"flex-start"} >
                {isLoadingFeedbacks && isRefetching == false ?
                    <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                        <Box className='skeleton' minHeight={"70vh"} sx={{ borderRadius: "8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

                        </Box>
                    </Grid>
                    :
                    <Box width={"100%"} paddingLeft={"2rem"} maxWidth={"100%"} overflow={"hidden"} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                        <AsyncTableListing
                            // isLoading={isLoadingFeedbacks}
                            columns={column}
                            data={formattedFeedbacksData}
                            page={page}
                            setPage={setPage}
                            limit={limit}
                            setLimit={setLimit}
                            count={feedbacksData?.count}
                            // tabValue={value}
                            // data={[]}
                            // title={"Investors"}
                            // chipText={`${data?.length}${isSmallScreen ? "" : " Investors"}`}
                            // actions={Actions}
                            // rowActions={rowActions}
                            sx={{ borderRadius: '8px', border: `1px solid ${theme.palette.customColors.b50}` }}
                        // refetchListing={refetchListing}
                        />
                    </Box>
                }

                {/* </Grid>} */}
                {/* <Grid item xs={2} lg={2}> */}

            </Grid>
        </>
    )
}

export default ViewFeedback
