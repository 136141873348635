import { Box, FormControlLabel, Grid,MenuItem,RadioGroup,Select,Skeleton,Typography,useMediaQuery } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import { AddStartup, FetchAllCategories, FetchAllCurrencyLists, FetchAllRoundTypes, FetchAllSectors, FetchAllStages, FetchAllSubCategories } from 'api'
import CustomLabel from 'ui-components/CustomLabel'
import { useTheme } from '@emotion/react'
import CustomValidationText from 'ui-components/CustomValidationText'
import { LocalMallOutlined, MemoryOutlined, MiscellaneousServicesOutlined, StorefrontOutlined } from '@mui/icons-material'
import CustomAutoComplete from 'ui-components/CustomAutoComplete'
import { ReactComponent as UploadDocumentsIcon } from "assets/rounds_icons/upload_documents_icon.svg";
import { B300 as CustomButton, BLUEBORDER, WHITEBG } from 'ui-components/CustomButton'
import { containerRef, currencyInputProps, getFileCategory, getFileIcon } from 'utilities'
import { styled } from '@mui/styles'
import CustomInputField from 'ui-components/formik/CustomInputField'
import { VisuallyHiddenInput } from 'ui-components/VisuallyHiddenInput'

const RoundDetails = ({isLoading}) => {
    const formik =useFormikContext()
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
    const { data: round_types, isRefetching: isRefetchingRoundTypes, isLoading: isLoadingRoundTypes, refetch: refetchRoundTypes } = FetchAllRoundTypes();
    const { data: startup_sectors, isRefetching: isRefetchingSectors, isLoading: isLoadingSectors, refetch: refetchSectors } = FetchAllSectors()
    const { data: currencyList, isLoading: isLoadingCurrency } =
    FetchAllCurrencyLists();

    React.useEffect(() => {
        if (!isLoadingCurrency && formik.values) {
          formik.setFieldValue("currency_id", formik.values?.currency_id ? formik.values?.currency_id : currencyList[0]?._id)
          formik.setFieldValue("currency", currencyList?.find(item => formik.values?.currency_id === item?._id)?.code || currencyList[0]?.code);
        }
      }, [isLoadingCurrency, formik.values])

        const [currentRoundType, setCurrentRoundType] = React.useState('');
    const [searchRoundType, setSearchRoundType] = React.useState('');
    const handleSearchRoundTypeChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchRoundType(value);
        // refetchCities();
    };
    const handleSelectionRoundTypeChange = (event, selected) => {
        // console.log(selected, "value")
        formik.setFieldValue("round_type", selected ? selected?.value : '');
        setCurrentRoundType(selected)
        event.preventDefault();
    };
    const handleSearchUpdateRoundType = () => {
        refetchSectors();
    }
    React.useEffect(() => {
        if (formik.values.round_type && round_types) {
            const matchedRoundType = round_types.find(subCat => subCat._id === formik.values.round_type);
            // console.log(matchedRoundType, "matchedRoundType")
            if (matchedRoundType) {
                setCurrentRoundType({ label: matchedRoundType?.name, value: matchedRoundType?._id, description: matchedRoundType?.sub_title });
            }
        }
    }, [formik.values.round_type, round_types]);
  return (
    <Grid container xs={12} gap={0} paddingBottom={0} paddingRight={1}>
                                                {/* <Grid xs={12} item container style={{ display: 'flex', alignItems: 'flex-start' }}> */}
        <Grid item xs={12} sm={12}>
            <CustomLabel>UPLOAD PITCH DECK</CustomLabel>
            <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    background:
                        theme?.palette.customColors
                            ?.whiteBG,
                    padding: "0.8rem 1rem",
                    borderRadius: "8px",
                    border: `1px solid ${theme.palette.customColors.g75}`,
                }}
                height={"min-content"}
            >
                <Grid display={"flex"} alignItems={"center"} gap={1} onClick={() => { formik.values?.pitch_deck != null && formik.values?.pitch_deck && formik.values?.pitch_deck != "" && window.open(formik.values?.pitch_deck) }}>
                    <Box
                        width={isSmallScreen ? 35 : 40}
                        height={isSmallScreen ? 35 : 40}
                        bgcolor={theme?.palette?.customColors?.indigo100}
                        borderRadius={"50%"}
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"center"}
                    >
                        <img
                            width={20} height={20}
                            src={getFileIcon("pitch_deck.pdf")}
                        />
                    </Box>
                    <span>
                        {formik.values?.pitch_deck?.name ? formik.values?.pitch_deck?.name : formik.values?.pitch_deck != null ? "pitch_deck.pdf" : "Pitch Deck PDF Only (Max: 50 MB)"}
                        {/* ? formik.values.pitch_deck.name ?? formik.values.pitch_deck.filename
                                : "Pitch Deck PDF Only (Max: 5 MB)"
                            } */}
                    </span>
                </Grid>
                <Grid
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",

                    }}
                >
                    {(formik.values?.pitch_deck?.name || formik.values?.pitch_deck?.filename) && (
                        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

                            {/* <img style={{ position: 'absolute', top: 0, right: 0, height: 16, width: 16, cursor: 'pointer' }} src={ClearIcon} /> */}
                        </Box>
                    )}
                    {/* {console.log(document?._id, "document?._id")} */}
                    {<WHITEBG
                        component="label"
                        role={undefined}
                        tabIndex={-1}
                        sx={{
                            minWidth: "0 !important",
                            border: `1px solid #D0D5DD !important`,
                            padding: '0.7rem'
                        }}
                    >
                        <UploadDocumentsIcon
                            style={{
                                width: 20,
                                height: 20,
                                stroke: "#344054"
                            }}
                            onClick={() => {

                            }
                            }
                        />
                        <VisuallyHiddenInput
                            accept=".pdf"
                            type="file"
                            name={`pitch_deck`}
                            id={`pitch_deck`}
                            onChange={(e) => {
                                const file = e.target.files[0];
                                formik.setFieldValue(`pitch_deck`, file)
                            }
                            }
                        />
                    </WHITEBG>}
                </Grid>
            </Grid>
            {/* <CustomUploadButton name={"pitch_deck"} label={"UPLOAD PITCH DECK"} handleUpdateFile={(file) => { formik.setFieldValue("pitch_deck", file) }} selectedFile={formik.values.pitch_deck} accept='.pdf' typeSpecifyText='PDF Only (Max: 5 MB)' customUploadIcon={getFileIcon("pdf")} /> */}
            {formik.errors.pitch_deck && <CustomValidationText value={formik.errors.pitch_deck} />}
        </Grid>
        <Grid item xs={12} sm={12} marginTop={1.5}>
            <CustomInputField value={formik.values?.round_name} label={"Round NAME"} placeholder={"Enter round name"} name="round_name" onChange={(e) => formik.setFieldValue("round_name", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} sx={{ marginBottom: 0 }} fullWidth />
        </Grid>
        <Grid item xs={12} mt={1.5}>
            <CustomAutoComplete
                options={!isLoadingRoundTypes && !isRefetchingRoundTypes && Array.isArray(round_types) ? round_types?.map(item => ({ label: item?.name, value: item?._id })) : []}
                label="Round Type"
                name={"round_type"}
                noOptionsText={!isLoadingRoundTypes && !isRefetchingRoundTypes ? "No Options" : "Loading..."}
                placeholder="Search a round type"
                icon={null}
                handleSearchChange={handleSearchRoundTypeChange}
                handleSelectionChange={handleSelectionRoundTypeChange}
                search={searchRoundType}
                value={currentRoundType}
                defaultValue={!isLoadingRoundTypes && !isRefetchingRoundTypes ? round_types?.find(item => item?._id == formik?.values?.round_type) : null}
                loading={isRefetchingRoundTypes}
                handleSearchUpdate={handleSearchUpdateRoundType}
            />
        </Grid>
        <Grid item xs={12} height={"min-content"}>
            <CustomInputField
                // inputProps={{ ...currencyInputProps }}
                InputProps={{
                    ...currencyInputProps,
                    startAdornment: (
                        <Box sx={{ padding: "0 0 0 1rem" }}>
                            {formik.values.currency}
                        </Box>
                    ),
                    endAdornment: (
                        !isLoadingCurrency && currencyList && <Select
                            className="selecttt"
                            sx={{
                                "::before": { border: "none !important" },
                                border: "none !important",
                            }}
                            defaultValue={formik.values?.currency_id}
                            value={formik.values?.currency_id}
                            onChange={(e) => {
                                // console.log(currencyList?.find(item => item?._id === e.target.value),"edefe")
                                formik.setFieldValue(
                                    "currency",
                                    currencyList?.find(item => item?._id === e.target.value)?.code
                                );
                                formik.setFieldValue(
                                    "currency_id",
                                    e.target.value
                                )
                            }
                            }
                        >
                            {/* <MenuItem value={"$"}>USD</MenuItem>
{<MenuItem value={"₹"}>INR</MenuItem>} */}
                            {currencyList?.map((item) => (
                                <MenuItem type="button" value={item?._id}>
                                    {item?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    ),
                }}
                name={`total_round_size`}
                label={"TOTAL ROUND SIZE"}
                placeholder={"Enter Total round size"}
                value={formik.values?.total_round_size}
                onChange={(e) =>
                    formik.setFieldValue(
                        `total_round_size`,
                        e.target.value
                    )
                }
                fullWidth
            />
            {/* {formik.errors.currency_id} */}
        </Grid>
        {/* </Grid> */}
    </Grid>
  )
}

export default RoundDetails
