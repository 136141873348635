import { useTheme } from '@emotion/react'
import { Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { B300, G300, } from '../../ui-components/CustomButton'
import { useNavigate } from 'react-router-dom';
import AuthWrapper1 from '../../ui-components/AuthWrapper1';
import BackrrLogoImage from '../../ui-components/BackrrLogoImage'
import Cookies from "js-cookie"

const RedirectionScreen = (current) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isTableorLargerScreen = useMediaQuery(theme.breakpoints.up('sm'))
    //theme.palette.background.paper
    // "#FFFFFF"
    return (
        <>
            <AuthWrapper1>
                <Grid sx={{ display: "grid", placeContent: 'center', height: "100vh" }}>
                    <Paper sx={{
                        backgroundColor: theme.palette.background.paper, boxShadow: 3, padding: '2rem', flex: 1, overflowY: "auto", overflowX: "hidden", maxHeight: "578px", borderRadius: "48px",
                        // scale: {xs: "0.8", sm: "0.9", lg: "1"},
                        scale: "0.9",
                        maxWidth: "450px"
                    }}>
                        <Grid container xs={12} display={'grid'} sx={{ placeItems: "center", placeContent: "center", gap: "2rem" }}>
                            {/* Header */}
                            <Grid item display={"grid"} sx={{ placeItems: "center", placeContent: "center", gap: 2 }}>
                                <BackrrLogoImage />
                                <Typography variant='caption' sx={{ fontSize: "1rem", fontWeight: 700, textAlign: 'center' }}>Thank you for setting up your account. </Typography>
                            </Grid>
                            {/* Content */}
                            <Grid sx={{ border: '1px solid #ADA2ED', background: "#EBE8FB", padding: "1.5rem", borderRadius: "8px" }}>
                                <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
                                    What's next?
                                </Typography>
                                <ol class="MuiBreadcrumbs-ol" style={{ margin: 0, paddingLeft: isTableorLargerScreen ? "1.5rem" : "1rem" }}>
                                    <li class="MuiBreadcrumbs-li" style={{ fontSize: '1rem', fontWeight: 400, marginBlock: "10px" }}>
                                        Complete your startup profile which will be visible to potential investors.
                                    </li>
                                    <li class="MuiBreadcrumbs-li css-1rqbcrs-MuiBreadcrumbs-ol" style={{ fontSize: '1rem', fontWeight: 400 }}>
                                        Upload your pitch deck and share it with any investors by link.
                                    </li>
                                </ol>
                            </Grid>
                            {/* Footer */}
                            <Grid display={"grid"} gap={2} xs={12} sx={{ width: "100%" }}>
                                <B300 fullWidth={true} sx={{ padding: 2, textTransform: "none" }} onClick={() => { Cookies.set("step", 4); navigate("/onboarding"); }
                                }>
                                    {/* navigate("/onboarding?page=4") */}
                                    Create Startup Profile
                                </B300>
                                <B300 fullWidth={true} sx={{ padding: 2, textTransform: "none" }} onClick={() => {Cookies.set("step", 4); navigate("/upload-pitch-deck")}}>
                                    Upload Pitch Deck
                                </B300>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </AuthWrapper1>
        </>
    )
}

export default RedirectionScreen