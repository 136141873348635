import { useTheme } from '@emotion/react';
import { ContentCopyOutlined, CopyAllOutlined, FileCopy } from '@mui/icons-material'
import { Autocomplete, capitalize, Chip, CircularProgress, Dialog, Divider, Grid, IconButton, InputAdornment, Modal, Paper, TextField, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { ReactComponent as CloseIcon } from "assets/close_icon.svg";
import { FetchRoundAllInvestorsEmails, FetchRoundInvestorsData } from 'api/round';
import EmailAutocomplete from 'common-components/EmailAutocomplete';
import { Field, FormikProvider, useFormik } from 'formik';
import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomAutoComplete from 'ui-components/CustomAutoComplete';
import { B300 } from 'ui-components/CustomButton';
import CustomValidationText from 'ui-components/CustomValidationText';
import CustomInputField from 'ui-components/formik/CustomInputField';
import ModalComponent from 'ui-components/ModalComponent';
import { handleCopyClick } from 'utilities/index';
import * as Yup from "yup";
const useStyles = makeStyles({
    customInput: {
        // '&  > div.MuiInputBase-root': {
        //     padding: 0
        // }
    }
});

const ShareDocumentsPopup = ({ open, handleClose, handleSubmission, isSharingDocuments = false }) => {
    const theme = useTheme();
    const { id: round_id } = useParams();
    const classes = useStyles();
    const details = useSelector((state) => state?.startupDetails?.data);
    const { data: personData, refetch: refetchListingLisiting, isLoading: isLoadingRoundInvestors } = FetchRoundAllInvestorsEmails({ id: round_id });
    // console.log(personData?.data?.map(item => ({name: item?.name, email: item?.email})), "personData")
    const formik = useFormik({
        initialValues: {
            emails: [],
            copy_link: '',
        },
        validationSchema: Yup.object({
            emails: Yup.array().required("Please select at least one investor").min(1, "Please select at least one investor"),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            const payload = {
                "startup_id": details?._id,
                "startup_round_id": round_id,
                "email": values?.emails?.map(item => item?.email)
            }
            // console.log(payload)
            await handleSubmission({ payload });
            formik.setFieldValue("emails", [])
        },
        enableReinitialize: true,
    });

    const [copied, setCopied] = React.useState(false)

    return (
        <ModalComponent open={open} onClose={handleClose} style={{ maxWidth: 640, width: "100%" }}>
            <Paper
                sx={{
                    background: theme.palette.customColors.whiteBG,
                    borderRadius: "8px",
                }}
            >
                <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
                    <CloseIcon width={40} height={40} onClick={handleClose} />
                </IconButton>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit} onKeyDown={(e) => {
                        if (e.key === "Tab" || (e.key === "Enter" && !e.shiftKey)) e.preventDefault();
                    }}>
                        <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontSize: "1.5rem",
                                        fontWeight: 700,
                                        lineHeight: "28px",
                                        color: theme.palette.customColors.g300,
                                    }}
                                >
                                    Share Documents
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12} container justifyContent={"space-between"}>
                                    <Grid item xs={12}>
                                        <CustomInputField
                                            name="copy_link"
                                            label={"Copy Invite Link"}
                                            value={details?.backrr_base_url + "/d"}
                                            sx={{ marginBottom: 1 }}
                                            InputProps={{
                                                disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment position="end" sx={{ position: 'absolute', right: '18px' }}>
                                                        <IconButton disableRipple edge="end" onClick={() => handleCopyClick(details?.backrr_base_url + "/d", copied, setCopied)} sx={{ borderRadius: '5px' }}>
                                                            <Tooltip open={copied} title={copied ? "Copied to Clipboard" : null}>
                                                                <Typography color={copied ? "inherit" : theme.palette.customColors.b300}>
                                                                    Copy
                                                                </Typography>
                                                            </Tooltip>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }
                                            }
                                        />

                                    </Grid>
                                    <Grid position={'relative'} item xs={12}
                                        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                    >
                                        <Divider sx={{ flex: 1 }} />
                                        <Typography sx={{ width: "2rem", textAlign: "center" }}>OR</Typography>
                                        <Divider sx={{ flex: 1 }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field name="emails" placeholder="Search by email or name" label={"Share with investors"} options={!isLoadingRoundInvestors ? personData?.data?.map(item => ({ name: item?.name, email: item?.email })) : []} component={EmailAutocomplete} inputStyle={{ marginBottom: 0 }} />
                                        {formik.errors.emails && <CustomValidationText value={formik.errors.emails} />}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <B300
                                    type="submit"
                                    fullWidth
                                    sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem", height: '52px' }}
                                    disabled={isSharingDocuments}
                                >
                                    {isSharingDocuments ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.2rem'} /> : "Share"}
                                </B300>
                            </Grid>
                        </Grid>
                    </form>
                </FormikProvider>
            </Paper>
        </ModalComponent>
    )
}

export default ShareDocumentsPopup