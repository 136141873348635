import React from 'react'
import backrrLogo from '../assets/Backrr_logo_symbol_white.webp'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@emotion/react'

const BackrrLogoImage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isSmallLaptopScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeLaptopScreen = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <img style={{ width: isLargeLaptopScreen ? "4.5rem" : "3rem", height: isLargeLaptopScreen ? "4.5rem": "3rem", marginInline:  isSmallScreen || isMediumScreen ? "auto" : 0}} src={backrrLogo} />
  )
}

export default BackrrLogoImage