import React from 'react';
import CustomTable from '../../../ui-components/CustomTable';
import { B300, B400, BLUEBORDER, WHITEBG } from '../../../ui-components/CustomButton';
import { Box, Button, Card, Grid, IconButton, Menu, MenuItem, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as MoreOptionsIcon } from "../../../assets/rounds_icons/more_solid_icon.svg";
import { useTheme } from '@emotion/react';
import { ReactComponent as UploadOutlinedIcon } from "../../../assets/upload-cloud-icon-outlined.svg"
import { getFileCategory, getFileIcon } from '../../../utilities';
import ClearIcon from '../../../assets/doc_icons/close_icon.svg'
import { ReactComponent as UploadDocumentsIcon } from "../../../assets/rounds_icons/upload_documents_icon.svg";
import { DeleteRoundDocument, FetchRoundDocuments, ShareDocuments } from '../../../api';
import toast from 'react-hot-toast';
import CustomToast from '../../../ui-components/CustomToast';
import { ReactComponent as DeleteIcon } from "../../../assets/rounds_icons/delete_icon.svg";
// import { ReactComponent as EditIcon } from "../../../assets/rounds_icons/edit_icon.svg";
import NoData from '../../../ui-components/NoData';
import { ReactComponent as EditIcon } from "../../../assets/rounds_icons/edit_icon.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { Add, Link, Share } from '@mui/icons-material';
import PerfectScrollbar from "react-perfect-scrollbar"
import ConfirmationDialog from '../../../ui-components/popups/ConfirmationPopup';
import ShareDocumentsPopup from '../popups/ShareDocumentsPopup';

const CardHeader = ({ title, actionText, action: Actions }) => {
  return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"}>
    <Typography fontWeight={700} fontSize={"1.25rem"}>
      {title}
    </Typography>
    {<Actions />}
  </Box>
}

const RoundDocuments = ({ }) => {
  const { id: round_id } = useParams();
  const {
    mutateAsync: deleteRoundDocumentAsync,
    isLoading: isdeletingRoundDocument,
    status: deleteRoundDocumentStatus,
    error: deleteRoundDocumentError,
    data: deleteRoundDocumentResponse
  } = DeleteRoundDocument();
  const { data: roundsDocumentData, isLoading: isLoadingRoundsDocumentData, refetch: refetchRoundDocuments } = FetchRoundDocuments({ round_id });
  const {
    mutateAsync: ShareDocumentsAsync,
    isLoading: isSharingDocuments,
    status: ShareDocumentsStatus,
    error: ShareDocumentsError,
    data: ShareDocumentsResponse
  } = ShareDocuments();

  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesUpXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));

  const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [openInvitePopup, setOpenInvitePopup] = React.useState(false);

  const handleInvitePopupOpen = () => {
    setOpenInvitePopup(true);
  };

  const handleInvitePopupClose = () => {
    setOpenInvitePopup(false);
  };

  const handleInviteInvestor = () => {
    handleInvitePopupClose();
  };

  const columns = React.useMemo(
    () => [
      { Header: 'File Name', accessor: 'filename', minWidth: 200 },
      { Header: 'File Size', accessor: 'size', minWidth: 100 },
      { Header: 'Last updated', accessor: 'updated_date', minWidth: 100 },
      { Header: 'Uploaded by', accessor: 'uploaded_by', minWidth: 100 },
      { Header: '', accessor: 'actions', minWidth: 50 },
    ],
    []
  );

  // Popper more menu
  const [anchorEl, setAnchorEl] = React.useState(null);

  const Actions = ({ index = 0 }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openMenu, setOpenMenu] = React.useState(false);

    const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
      setOpenMenu(true);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
      setOpenMenu(false);
    };

    return (
      <Box display={"flex"} gap={1} position={"relative"}>
        {isNotLargeScreen && roundsDocumentData?.length > 0 ? (
          <div style={{ position: 'relative' }}>
            <IconButton
              sx={{ padding: 0 }}
              id={`more-details-button-${index}`}
              size="small"
              aria-controls={openMenu ? 'more-details-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onClick={(e) => handleClickMenu(e)}
            >
              <MoreOptionsIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id={`more-details-menu-${index}`}
              open={openMenu}
              MenuListProps={{
                'aria-labelledby': `more-details-button-${index}`,
              }}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  padding: "12px",
                  borderRadius: "8px",
                  "& > ul.MuiList-root": { padding: "0 !important" },
                  bgcolor: theme.palette.customColors.g400,
                  color: theme.palette.customColors.whiteBG,
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 11,
                    width: 10,
                    height: 10,
                    bgcolor: theme.palette.customColors.g900,
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {roundsDocumentData?.length > 0 && <MenuItem
                sx={{
                  borderBottom: `1px solid ${theme.palette.customColors.g700}`,
                  padding: '0.625rem 1rem',
                }}
                onClick={handleCloseMenu}
              >
                Download All
              </MenuItem>}
              <MenuItem
                sx={{
                  borderBottom: `1px solid ${theme.palette.customColors.g700}`,
                  padding: '0.625rem 1rem',
                }}
                onClick={() => { handleCloseMenu(); navigate(`/rounds/edit_round/${round_id}?page=5`); }}
              >
                Upload
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <>
            {roundsDocumentData?.length > 0 && <WHITEBG
              sx={{
                minWidth: "0 !important",
                border: `1px solid #D0D5DD !important`,
                padding: "8px 14px"
              }}
            >
              Download All
            </WHITEBG>}
            <B400
              sx={{
                background: theme.palette.customColors.indigo700,
                padding: "0.625rem 1rem"
              }}
              startIcon={
                <UploadOutlinedIcon style={{ width: 20, height: 20 }} />
              }
              onClick={() => { handleCloseMenu(); navigate(`/rounds/edit_round/${round_id}?page=5`); }}
            >
              Upload
            </B400>
          </>
        )}
      </Box>
    );
  };


  const RowActions = () => {
    return (
      <Box display={"flex"} gap={1}>
        <Button sx={{ minWidth: "1rem" }}>
          <MoreOptionsIcon />
        </Button>
      </Box>
    );
  };

  const [currentDocument, setCurrentDocument] = React.useState(null);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);

  const handleOpenDeletePopup = (document, index) => {
    setCurrentDocument(document)
    setOpenDeletePopup(true);
  }

  const handleCloseDeletePopup = () => {
    setCurrentDocument(null);
    setOpenDeletePopup(false);
  }

  const handleDelete = () => {
    deleteRoundDocumentAsync({ round_id: round_id, payload: { document_id: currentDocument?.document_id } }, {
      onSuccess: (succ) => {
        toast.success(
          <CustomToast message={"Document Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
          {
            duration: 2000,
            position: "top-right",
          }
        );
        refetchRoundDocuments();
        handleCloseDeletePopup();
      },
      onError: (err) => {
        toast.error(
          <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
          {
            duration: 2000,
            position: "top-right",
          }
        );
      }
    });
  }

  const handleCopyClick = (copyLink) => {
    // Perform the copy action (e.g., copying text to clipboard)
    navigator.clipboard.writeText(copyLink);
  };

  const [openSharePopup, setOpenSharePopup] = React.useState(false);

  const handleOpenSharePopup = () => {
    setOpenSharePopup(true);
  }

  const handleCloseSharePopup = () => {
    setOpenSharePopup(false);
  }

  const handleShare = async ({ payload }) => {
    await ShareDocumentsAsync(payload, {
      onSuccess: (succ) => {
        toast.success(
          <CustomToast message={succ?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
          {
            duration: 2000,
            position: "top-right",
          }
        );
        handleCloseSharePopup();
      },
      onError: (err) => {
        toast.error(
          <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
          {
            duration: 2000,
            position: "top-right",
          }
        );
        handleCloseSharePopup()
      }
    })
  }

  const DocumentActions = () => {
    return <Grid item display={"flex"} gap={2}>
      <WHITEBG
        component="label"
        role={undefined}
        tabIndex={-1}
        sx={{
          minWidth: "0 !important",
          border: `1px solid #7a7d83 !important`,
          display: "flex",
          gap: '0.5rem',
          paddingInline: {xs: `0.5rem`, sm: `0.5rem 0.875rem`}
        }}
        onClick={() => { navigate(`/rounds/edit_round/${round_id}?page=5`) }}
      >
        <Add
          style={{
            width: 20,
            height: 20,
          }} />
        {<Typography sx={{display: {xs: "none", sm: "block"}}}>
          Add Document
        </Typography>}
      </WHITEBG>
      <WHITEBG
        component="label"
        role={undefined}
        tabIndex={-1}
        sx={{
          minWidth: "0 !important",
          border: `1px solid #7a7d83 !important`,
          display: "flex",
          gap: '0.5rem',
          paddingInline: {xs: `0.5rem`, sm: `0.5rem 0.875rem`}
        }}
        onClick={handleOpenSharePopup}
      >
        <Share
          style={{
            width: 20,
            height: 20,
          }} />
        {<Typography sx={{display: {xs: "none", sm: "block"}}}>
          Share
        </Typography>}
      </WHITEBG>
    </Grid>
  }

  return (
    <>
      <ShareDocumentsPopup open={openSharePopup} handleClose={handleCloseSharePopup} handleSubmission={handleShare} isSharingDocuments={isSharingDocuments} />
      <ConfirmationDialog open={openDeletePopup} handleClose={handleCloseDeletePopup} handleSubmission={handleDelete} heading={"Confirm Delete"} ActionText={"Delete"} subheading={"Are you sure you want to delete this document?"} isLoading={isdeletingRoundDocument} />
      <CardHeader title={"Documents"}


        action={DocumentActions} />
      {isLoadingRoundsDocumentData && !roundsDocumentData ? <Grid item xs={12} height={"100vh"} sx={{ width: '100%', bgColor: theme.palette.customColors.yellow_bg }}>
        <Box className='skeleton' height={"calc(100% - 400px)"} marginTop={0.2}>

        </Box>
      </Grid> : roundsDocumentData?.length > 0 ?
        <PerfectScrollbar style={{ height: "calc(100vh - 250px)" }}>
          <Grid paddingTop={1} container gap={2} xs={12} sx={{ justifyContent: { xs: 'space-between', lg: 'start' } }}>
            {/* <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden', width: '100%' }}> */}

            {roundsDocumentData?.map(
              (document, index) => {
                return (
                  <>
                    {/* <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      display={"flex"}
                      alignItems={"baseline"}
                      justifyContent={"space-between"}
                      spacing={1}
                      position={"relative"}
                      key={index}
                    > */}

                    <Grid
                      item
                      xs={12}
                      sm={5.8}
                      md={5.85}
                      lg={3.8}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        background:
                          theme?.palette.customColors
                            ?.whiteBG,
                        padding: "0.8rem 1rem",
                        borderRadius: "8px",
                        border: `1px solid ${theme.palette.customColors.g75}`,
                      }}
                      height={"min-content"}
                    >
                      <Grid
                        display={"flex"}
                        alignItems={"center"}
                        gap={1}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Box width={isSmallScreen ? 35 : 40} height={isSmallScreen ? 35 : 40} bgcolor={theme?.palette?.customColors?.indigo100} borderRadius={"50%"} display={"flex"} alignItems="center" justifyContent={"center"}>
                          <img
                            width={20} height={20}
                            onClick={() => {
                              window.open(document?.file_url)
                            }}
                            src={getFileCategory(
                              document?.document?.name || document?.filename)}
                          />
                        </Box>
                        <Typography variant="body1" onClick={() => {
                          window.open(document?.file_url)
                        }}>
                          {document?.title}
                        </Typography>

                        {document?.title != "Pitch Deck Round" &&
                          <Tooltip title="Edit">
                            <EditIcon
                              style={{
                                width: 20,
                                height: 20,
                                stroke: "#344054"
                              }}
                              onClick={() => {
                                navigate(`/rounds/edit_round/${round_id}?page=5`)
                              }
                              }
                            />
                          </Tooltip>
                        }
                      </Grid>

                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",

                        }}
                      >
                        {document?.title === "Pitch Deck Round" && <WHITEBG
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          sx={{
                            minWidth: "0 !important",
                            border: `1px solid #D0D5DD !important`,
                            padding: '0.7rem'
                          }}
                        ><Tooltip title="Copy">
                            <Link
                              style={{
                                width: 20,
                                height: 20,
                                // stroke: "#344054"
                              }}
                              onClick={() => {
                                //  navigate(`/rounds/edit_round/${round_id}?page=5`)
                                handleCopyClick(document?.file_url)
                              }
                              }
                            />
                          </Tooltip>
                        </WHITEBG>}


                        {<WHITEBG
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          onClick={() => handleOpenDeletePopup(document, index)}
                          sx={{
                            minWidth: "0 !important",
                            border: `1px solid #D0D5DD !important`,
                            padding: '0.7rem'
                          }}
                        ><Tooltip title="Delete">
                            <DeleteIcon
                              style={{
                                width: 20,
                                height: 20,
                                stroke: "#344054"
                              }}
                            />
                          </Tooltip>
                        </WHITEBG>}
                      </Grid>
                    </Grid>
                    {/* </Grid> */}
                  </>
                );
              }
            )}
            {/* </Card> */}
          </Grid>
        </PerfectScrollbar>
        : <NoData Actions={Actions} sx={{ minHeight: "calc(100% - 330px)" }} />}
    </>
  );
};

export default RoundDocuments;
