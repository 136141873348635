import { useTheme } from "@emotion/react";
import { capitalize, CircularProgress, Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { B300 } from "../../../ui-components/CustomButton";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../../ui-components/formik/CustomInputField";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { InviteInvestorPopupPostApi } from "../../../api";
import * as Yup from "yup";

let emailPattern = /^[^\s@]+@[^\s@]+.[^\s@]+$/;


const InviteInvestorPopup = ({ handleClose, handleSubmit, refetchListing }) => {
  const theme = useTheme();
  const { id: round_id } = useParams();
  const startup_id = useSelector((state) => state.startupDetails.data)?._id;
  const {
    mutateAsync: inviteInvestorAsync,
    isLoading: isInvitingInvestor,
    status: inviteInvestorStatus,
    error: inviteInvestorError,
    data: inviteInvestorResponse
  } = InviteInvestorPopupPostApi();
  const validationObj = {
    first_name: Yup.string().required("Please enter first name"),
    last_name: Yup.string().required("Please enter last name"),
    email: Yup.string()
      .required("Please enter email")
      .test("email", "Invalid email address", (value) => emailPattern.test(value)),
    // Uncomment and adjust the message validation if required
    // message: Yup.string().required("Please enter a message"),
  };
  const validationSchema = Yup.object({ ...validationObj });


  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      message: "",
    },
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // const formData = new FormData();
      // formData.append("email", values.email);
      // formData.append("message", values.message);
      // formData.append("startup_id", startup_id); 
      // formData.append("startup_round_id", round_id); 

      try {
        await inviteInvestorAsync({ payload: { first_name: values?.first_name, last_name: values?.last_name ,email: (values?.email)?.toLowerCase(), message: values?.message, status: 'pipeline' }, _id: round_id }).then(() => {
          refetchListing()
          handleClose()
        });
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "8px",
      }}
    >
      <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Invite Investor
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: theme.palette.customColors.g200,
                }}
              >
                Simply add their email address and send the invitation.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} container justifyContent={"space-between"}>
                <Grid item xs={12} sm={5.8}>
                  <CustomInputField
                    name="first_name"
                    label={"FIRST NAME"}
                    placeholder={"Enter first name"}
                    value={formik.values?.first_name}
                    onChange={(e) => {
                      // e.target.value
                      //   ?.charAt(0)
                      //   ?.toUpperCase() +
                      //   e.target.value?.slice(1)
                      formik.setFieldValue("first_name",
                        capitalize(e.target.value));
                    }}
                    sx={{ marginBottom: formik.errors?.last_name ? 0 : 1 }}
                  />

                </Grid>
                <Grid item xs={12} sm={5.8}>
                  <CustomInputField
                    name="last_name"
                    label={"LAST NAME"}
                    placeholder={"Enter last name"}
                    value={formik.values?.last_name}
                    onChange={(e) => {
                      formik.setFieldValue("last_name",
                        capitalize(e.target.value));
                    }}
                    sx={{ marginBottom: formik.errors?.last_name ? 0 : 1 }}
                  />
                </Grid>
              </Grid>
              <CustomInputField
                name="email"
                label={"EMAIL"}
                placeholder={"Enter email"}
                value={formik.values.email}
                onChange={(e) => {
                  formik.setFieldValue("email", String(e.target.value)?.toLowerCase());
                }}
                sx={{ marginBottom: 1 }}
              />
              <CustomInputField
                name="message"
                label={<> MESSAGE <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span></>}
                placeholder={"Enter message"}
                value={formik.values.message}
                onChange={(e) => {
                  formik.setFieldValue("message", e.target.value);
                }}
                multiline
                minRows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <B300
                type="submit"
                fullWidth
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem", height: '52px' }}
                disabled={isInvitingInvestor}
              >
                {isInvitingInvestor ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.2rem'} /> : "Invite"}
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default InviteInvestorPopup;
