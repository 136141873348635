import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'
import CustomPhone from 'ui-components/CustomPhone'
import CustomInputField from 'ui-components/formik/CustomInputField'
import { FetchAllCountriesWithPhoneCodes } from '../../../api'

const ContactInformation = () => {
  const formik = useFormikContext()
  const { data: countriesWithPhoneCodes, isRefetching: isRefetchingCountriesPC, isLoading: isLoadingCountriesPC, refetch: refetchCountriesPC } = FetchAllCountriesWithPhoneCodes({})
  return (
    <Grid container xs={12} gap={0} marginTop={0} display={"flex"} alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"space-between"}>
      <Grid height={"min-content"} item xs={12} sm={12} md={5.5} lg={5.65}>
        <CustomInputField name={`first_name`} label={"FIRST NAME"} placeholder={"Enter first name"} value={formik.values?.first_name} onChange={(e) => {
          const value = e.target.value.replace(/[^a-zA-Z]/g, ''); // Allows only alphabets
          formik.setFieldValue(`first_name`, value.charAt(0).toUpperCase() + value.slice(1));
        }} fullWidth />
      </Grid>
      <Grid height={"min-content"} item xs={12} sm={12} md={5.5} lg={5.65}>
        <CustomInputField name={`last_name`} label={'LAST NAME'} placeholder={"Enter last name"} value={formik.values?.last_name} onChange={(e) => {
          const value = e.target.value.replace(/[^a-zA-Z]/g, ''); // Allows only alphabets
          formik.setFieldValue(`last_name`, value.charAt(0).toUpperCase() + value.slice(1));
        }} fullWidth />
      </Grid>
      {/* {isLoadingCountriesPC ? <CircularProgress sx={{ color: "#3538CD" }} /> :  */}
      {formik.values.phone_code && <Grid height={"min-content"} item xs={12}>
        <CustomPhone name={`mobile_number`} selectedCode={formik.values.phone_code ? formik.values.phone_code : ""} handleChange={(event) => formik.setFieldValue("phone_code", event.target.value)} countryCodes={countriesWithPhoneCodes ? countriesWithPhoneCodes : []} label='MOBILE' placeholder="Enter your mobile number" onChange={(e) => formik.setFieldValue(`mobile_number`, e.target.value)} />
      </Grid>}
      {/* } */}
    </Grid>
  )
}

export default ContactInformation
