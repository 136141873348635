import { useTheme } from '@emotion/react'
import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'
import CustomUrlField from 'ui-components/CustomUrlField'

const StartupOnlinePresence = () => {
    const formik =useFormikContext()
    const theme = useTheme()
  return (
    <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
    <Grid item xs={12} display={"flex"} gap={0}>
        <CustomUrlField type="text" value={formik.values.website_url} name="website_url" label={"WEBSITE URL"} placeholder="www.backrr.com" onChange={(e) => formik.setFieldValue("website_url", String(e.target.value)?.startsWith("https://") ? String(e.target.value)?.replace("https://", "") : e.target.value)} />
    </Grid>
    <Grid xs={12} item container style={{ display: 'flex', alignItems: 'center' }}>
        <CustomUrlField label={<>STARTUP LINKEDIN PAGE <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span> </>} name="linkedin_url" value={formik.values?.linkedin_url} onChange={(e) => formik.setFieldValue("linkedin_url", String(e.target.value)?.startsWith("https://") ? String(e.target.value)?.replace("https://", "") : e.target.value)} placeholder='www.linkedin.com' />
    </Grid>
</Grid>
  )
}

export default StartupOnlinePresence
