import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Box,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  styled,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  B300,
  B400,
  BLUEBORDER,
  WHITEBG,
} from "../../../ui-components/CustomButton";
import { ReactComponent as gpsIcon } from "../../../assets/gps.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTheme } from "@emotion/react";
import CustomInputField from "../../../ui-components/formik/CustomInputField";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { AddInvestmentForRound, FetchAllRoundStatus } from "../../../api";
import * as Yup from "yup";
import CustomLabel from "../../../ui-components/CustomLabel";
import CustomValidationText from "../../../ui-components/CustomValidationText";
import { ReactComponent as UploadDocumentsIcon } from "../../../assets/rounds_icons/upload_documents_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/rounds_icons/delete_icon.svg";
import { Add } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { containerRef } from "../../../utilities";


const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UpdateRoundStatusPopup = ({ open, handleClose, handleSubmit, isLoading, selectedRound }) => {
  // console.log(selectedRound,"selectedRound")
  const startup_id = useSelector((state) => state.startupDetails.data)?._id;
  const { data: roundStatuses, isLoading: isLoadingRoundStatuses } = FetchAllRoundStatus()

  const [validationObj, setValidationObj] = React.useState({
    status: Yup.string().required("Status is required"),
  });
  const statusValidationSchema = Yup.object({ ...validationObj });
  const formik = useFormik({
    initialValues: {
      status: selectedRound?.round_status_info ? selectedRound?.round_status_info?._id : null,
    },
    validationSchema: statusValidationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const payload = {
        "round_status": values?.status,
        "round_id": selectedRound?._id
      }
      handleSubmit({round: payload})
    },
  });

  const theme = useTheme();

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setValues({ ...formik.values, [name]: value });
  };

  const placeholders = {
    // call_for_money: false,
    status: "Select status",

  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={{gap: '1rem'}}>
        <IconButton
          sx={{ position: "absolute", top: 15, right: 20, padding: 0 }}
        >
          <CloseIcon width={40} height={40} onClick={handleClose} />
        </IconButton>
        <DialogTitle sx={{ marginInline: "auto", marginBottom: 1 }}>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: 700,
              lineHeight: "28px",
              color: theme.palette.customColors.g300,
              paddingLeft: "0.5rem",
              textAlign: 'center'
            }}
          >
            Update Status
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingInline: 0, paddingBottom: 1 }}>
          <PerfectScrollbar
            containerRef={containerRef}
            style={{
              maxHeight: "calc(-250px + 100vh)",
              paddingInline: "1.5rem",
            }}
          >
            <Grid item container xs={12} columnSpacing={2}>
              <Grid item xs={12}>
                <CustomLabel>STATUS</CustomLabel>
                <Select
                  name="status"
                  value={formik.values?.status}
                  onChange={handleChange}
                  placeholder={placeholders?.status}
                  sx={{ marginBottom: "0.5rem" }}
                  fullWidth
                >
                  {roundStatuses?.map((option) => (
                    <MenuItem value={option?._id}>{option?.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </PerfectScrollbar>
        </DialogContent>
        <DialogActions
          sx={{ paddingInline: "1.5rem", paddingBottom: "1.5rem" }}
        >
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          <B300
            type="submit"
            fullWidth
            disabled={isLoading}
            sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
          >
            {isLoading ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.2rem'} /> : "Update"}
          </B300>
          {/* <B300 sx={{ background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", width: '100%' }} onClick={handleSave}>Save</B300> */}
        </DialogActions>
        {/* </Dialog> */}
      </Form>
    </FormikProvider>
  );
};

export default UpdateRoundStatusPopup;
