import { Radio } from "@mui/material";
import { styled } from "@mui/styles";

const CustomRadio = styled(Radio)(({ theme }) => ({
    color: "#e0e0e0",
    paddingBlock: "0 !important"
    ,
    "&.Mui-checked": {
        color: "#7c4dff !important",
    },
    "& .MuiSvgIcon-root": {
        borderRadius: "50%",
    },
}));
export default CustomRadio