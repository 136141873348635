import { FormControlLabel, Grid, Box, RadioGroup, useMediaQuery, } from "@mui/material";
import React from "react";
import CustomInputField from "ui-components/formik/CustomInputField";
import { AddStartup, FetchAllCategories, FetchAllCities, FetchAllCountries, FetchAllCountriesWithPhoneCodes, FetchAllStages, FetchAllStates, FetchAllSubCategories, FetchStartupDetails } from "../../../../api";
import { FieldArray, useFormikContext } from "formik";
import { useTheme } from "@emotion/react";
import CustomLabel from "ui-components/CustomLabel";
import CustomValidationText from "ui-components/CustomValidationText";
import { ReactComponent as UploadDocumentsIcon } from "assets/rounds_icons/upload_documents_icon.svg";
import {
  BLUEBORDER,
  SECONDARY,
  B300 as CustomButton,
  WHITEBG,
} from "ui-components/CustomButton";
import { getFileIcon } from "utilities";
import { VisuallyHiddenInput } from "ui-components/VisuallyHiddenInput";
import CustomRichTextEditor from "ui-components/formik/CustomRichTextEditor";
import { Check } from "@mui/icons-material";

const StartupDetails = () => {
  const formik = useFormikContext()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const matchesUpXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  return (
    <>
      <Grid item xs={12} sm={12}>
        <CustomLabel>UPLOAD PITCH DECK</CustomLabel>
        <Grid
          item
          xs={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            background:
              theme?.palette.customColors
                ?.whiteBG,
            padding: "0.8rem 1rem",
            borderRadius: "8px",
            border: `1px solid ${theme.palette.customColors.g75}`,
          }}
          height={"min-content"}
        >
          <Grid display={"flex"} alignItems={"center"} gap={1} onClick={() => { formik.values?.pitch_deck != null && formik.values?.pitch_deck && formik.values?.pitch_deck != "" && window.open(formik.values?.pitch_deck) }}>
            <Box
              width={isSmallScreen ? 35 : 40}
              height={isSmallScreen ? 35 : 40}
              bgcolor={theme?.palette?.customColors?.indigo100}
              borderRadius={"50%"}
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
            >
              <img
                width={20} height={20}
                src={getFileIcon("pitch_deck.pdf")}
              />
            </Box>
            <span>
              {formik.values?.pitch_deck?.name ? formik.values?.pitch_deck?.name : formik.values?.pitch_deck != null ? "pitch_deck.pdf" : "Pitch Deck PDF Only (Max: 50 MB)"}
              {/* ? formik.values.pitch_deck.name ?? formik.values.pitch_deck.filename
                                                                        : "Pitch Deck PDF Only (Max: 5 MB)"
                                                                    } */}
            </span>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",

            }}
          >
            {(formik.values?.pitch_deck != null) ? (
              <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <Check sx={{ color: "green" }} />
                {/* <img style={{ position: 'absolute', top: 0, right: 0, height: 16, width: 16, cursor: 'pointer' }} src={ClearIcon} /> */}
              </Box>
            ) : (<Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <Check sx={{ color: "gray" }} />
              {/* <img style={{ position: 'absolute', top: 0, right: 0, height: 16, width: 16, cursor: 'pointer' }} src={ClearIcon} /> */}
            </Box>)}
            {/* {console.log(document?._id, "document?._id")} */}
            {<WHITEBG
              component="label"
              role={undefined}
              tabIndex={-1}
              sx={{
                minWidth: "0 !important",
                border: `1px solid #D0D5DD !important`,
                padding: '0.7rem'
              }}
            >
              <UploadDocumentsIcon
                style={{
                  width: 20,
                  height: 20,
                  stroke: "#344054"
                }}
                onClick={() => {

                }
                }
              />
              <VisuallyHiddenInput
                accept=".pdf"
                type="file"
                name={`pitch_deck`}
                id={`pitch_deck`}
                onChange={(e) => {
                  const file = e.target.files[0];
                  formik.setFieldValue(`pitch_deck`, file)
                }
                }
              />
            </WHITEBG>}
          </Grid>
        </Grid>
        {/* <CustomUploadButton name={"pitch_deck"} label={"UPLOAD PITCH DECK"} handleUpdateFile={(file) => { formik.setFieldValue("pitch_deck", file) }} selectedFile={formik.values.pitch_deck} accept='.pdf' typeSpecifyText='PDF Only (Max: 5 MB)' customUploadIcon={getFileIcon("pdf")} /> */}
        {/* {formik.errors.pitch_deck && formik.touched.pitch_deck && (
                                                    <span style={{ color: 'red', lineHeight: '2rem', fontSize: "0.875rem" }}>
                                                        ({formik.errors.pitch_deck})
                                                    </span>
                                                )} */}
      </Grid>
      <Grid item xs={12} sm={12} mt={2}>
        <CustomLabel>HAVE VIDEO PITCH?</CustomLabel>
        <Grid
          item
          xs={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            background:
              theme?.palette.customColors
                ?.whiteBG,
            padding: "0.8rem 1rem",
            borderRadius: "8px",
            border: `1px solid ${theme.palette.customColors.g75}`,
          }}
          height={"min-content"}
        >
          <Grid display={"flex"} alignItems={"center"} gap={1} onClick={() => { formik.values?.elevator_pitch != null && formik.values?.elevator_pitch && formik.values?.elevator_pitch != "" && window.open(formik.values?.elevator_pitch) }}>
            <Box
              width={isSmallScreen ? 35 : 40}
              height={isSmallScreen ? 35 : 40}
              bgcolor={theme?.palette?.customColors?.indigo100}
              borderRadius={"50%"}
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
            >
              <img
                width={20} height={20}
                src={getFileIcon("elevator_pitch.mp4")}
              />
            </Box>
            <span>
              {formik.values?.elevator_pitch?.name ? formik.values?.elevator_pitch?.name : formik.values?.elevator_pitch != null ? "video_pitch.mp4" : "Elevator Pitch MP4, M4V Only (Max: 100 MB)"}
              {/* ? formik.values.elevator_pitch.name ?? formik.values.elevator_pitch.filename
                                                                        : "Pitch Deck PDF Only (Max: 5 MB)"
                                                                    } */}
            </span>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",

            }}
          >
            {(formik.values?.elevator_pitch != null) ? (
              <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <Check sx={{ color: "green" }} />
                {/* <img style={{ position: 'absolute', top: 0, right: 0, height: 16, width: 16, cursor: 'pointer' }} src={ClearIcon} /> */}
              </Box>
            ) : (<Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              <Check sx={{ color: "gray" }} />
              {/* <img style={{ position: 'absolute', top: 0, right: 0, height: 16, width: 16, cursor: 'pointer' }} src={ClearIcon} /> */}
            </Box>)}
            {/* {console.log(document?._id, "document?._id")} */}
            {<WHITEBG
              component="label"
              role={undefined}
              tabIndex={-1}
              sx={{
                minWidth: "0 !important",
                border: `1px solid #D0D5DD !important`,
                padding: '0.7rem'
              }}
            >
              <UploadDocumentsIcon
                style={{
                  width: 20,
                  height: 20,
                  stroke: "#344054"
                }}
                onClick={() => {

                }
                }
              />
              <VisuallyHiddenInput
                accept='video/mp4,video/x-m4v,video/'
                type="file"
                name={`elevator_pitch`}
                id={`elevator_pitch`}
                onChange={(e) => {
                  const file = e.target.files[0];
                  formik.setFieldValue(`elevator_pitch`, file)
                }
                }
              />
            </WHITEBG>}
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={12} sm={12}>
        <CustomInputField labelStyle={{ marginTop: '1rem' }} value={formik.values.problem_solving} name="problem_solving" onChange={(e) => {
          const value = e.target.value;
          if (value.length <= 160) {
            formik.setFieldValue("problem_solving", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))
          }
          else {
            formik.setFieldValue("problem_solving", String(e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))?.substring(0, 160))
          }
        }} label={"WHAT PROBLEM ARE YOU SOLVING?"} placeholder="Enter a description..." multiline minRows={4} maxRows={4} maxLength={160} fullWidth data-problem-solving={160 - formik.values?.problem_solving?.length + `${(160 - formik.values?.problem_solving?.length) > 1 ? " characters" : " character"} left`}
          sx={{
            "::after": {
              content: 'attr(data-problem-solving)',
              position: "absolute",
              bottom: formik.errors.problem_solving ? 30 : 5,
              right: 5,
              background: 'white',
              color: formik.values?.problem_solving?.length === 200 ? "red" : 'graytext',
              padding: "0 5px",
              fontSize: "0.75rem"
            },
          }} />
      </Grid>
      <Grid item xs={12} sm={12} mt={0} paddingBottom={1} height={"min-content"}>
        <CustomRichTextEditor
          name="description"
          label={
            <>
              STARTUP OVERVIEW
              {/* <img src="" alt="" /> */}
            </>
          }

          placeholder={"Enter startup overview"}
          value={formik.values?.description}
          handleChange={(newValue) =>
            formik.setFieldValue(
              "description",
              newValue
            )
          }
          maxLength={2000}
        />
        {formik.errors.description && formik.touched.description && (
          <CustomValidationText value={formik.errors.description} />
        )}
      </Grid>
    </>
  )
}

export default StartupDetails
