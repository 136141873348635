import Cookies from 'js-cookie';

// const clearCacheData = () => {
//     window.caches.keys().then((names) => {
//         names.forEach((name) => {
//             window.caches.delete(name);
//         });
//     });
//   };

export const verifyToken = (err) => {
    // clearCacheData();
    if (err.response.status == 401) {
        Cookies.remove('jwt', { path: '/' });
        Cookies.remove('name', { path: '/' });
        Cookies.remove('user_id', { path: '/' });
        Cookies.remove('startup_id', { path: '/' });
        Cookies.remove('email', { path: '/' });
        Cookies.remove('is_profile_complete', { path: '/' });
        Cookies.remove('userDetails', { path: '/' });
        // Cookies.remove('deal-index', { path: '/' });
        // Cookies.remove('investor-index', { path: '/' });
        // Cookies.remove('master-index', { path: '/' });
        // Cookies.remove('user_id', { path: '/' });
        // Cookies.remove('slug', { path: '/' });
        // Cookies.remove('profile_image', { path: '/' });
        // Cookies.remove('user_role', { path: '/' });
        // localStorage.removeItem('permission');
        // Cookies.remove('permission_id',{ path: '/' });
        // Cookies.remove('syndicate_id',{ path: '/' });
        window.location.href = '/login';
    }
};

export const RetryOnError = (failureCount, error) => (error.response.status != 401 && failureCount < 2 ? true : false);
