import { useTheme } from "@emotion/react";
import { alpha, Box, CircularProgress, Grid, IconButton, Paper, Switch, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { B300 } from "../../../ui-components/CustomButton";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../../ui-components/formik/CustomInputField";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { styled } from "@mui/styles";
import CustomLabel from "../../../ui-components/CustomLabel";
import { AddInvestorPopupPostApi } from "../../../api";
import CustomToast from "../../../ui-components/CustomToast";
import toast from "react-hot-toast";
import { EditInvestorPopupPostApi } from "../../../api/round";
import { currencyInputProps } from "utilities/index";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: "#e0e0e0",
    '&:hover': {
      backgroundColor: alpha("#3538CD", theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: "#3538CD",
  },
}));

let emailPattern = /^[^\s@]+@[^\s@]+.[^\s@]+$/;

const AddInvestorPopup = ({ handleClose, handleSubmit, currency = "₹", value = "pipeline", refetchListing, currentRow }) => {
  // console.log(currentRow,currency, "valuevaluevaluevaluevaluevalue")
  const theme = useTheme();
  const isEditMode = !!currentRow?.investor_id;
  // console.log(isEditMode,"Eidt")
  const { id: round_id } = useParams();
  const startup_id = useSelector((state) => state.startupDetails.data)?._id;
  const {
    mutateAsync: addInvestorAsync,
    isLoading: isUploadingInvestor,
    status: addInvestorStatus,
    error: addInvestorError,
    data: addInvestorResponse
  } = AddInvestorPopupPostApi();
  const {
    mutateAsync: editInvestorAsync,
    isLoading: isUpdatingInvestor,
    status: editInvestorStatus,
    error: editInvestorError,
    data: editInvestorResponse
  } = EditInvestorPopupPostApi();
  const validationObj = {
    email: Yup.string()
      .required("Please enter email")
      .test("email", "Invalid email address", (value) => emailPattern.test(value)),
    // message: Yup.string().required(" message"),
    first_name: Yup.string().required("Please enter first name"),
    last_name: Yup.string().required("Please enter last name"),
    lead_investor: Yup.string().required("Please select lead investor"),
    interested_amount: value !== "pipeline" ? Yup.string().required("Please enter interested amount").test("not-nullable", "Please enter interested amount", (value) => {
      return value != null;
    }) : Yup.string().nullable()
  }
  const validationSchema = Yup.object({ ...validationObj });

  const details = {
    first_name: currentRow?.name?.split(" ")?.length > 0 ? currentRow?.name?.split(" ")[0] : "",
    last_name: currentRow?.name?.split(" ")?.length > 1 ? currentRow?.name?.split(" ")[1] : "",
    status: value,
    ...currentRow
  }

  const formik = useFormik({
    initialValues: isEditMode ? details : {
      email: "",
      message: "",
      first_name: "",
      last_name: "",
      interested_amount: "",
      lead_investor: false,
      currency: "₹",
      kyc: false,
      agreement_signed: false,
      call_for_money: false,
      fund_received: false,
    },
    validationSchema: validationSchema,
    // onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      // console.log(values ,"UUUUUUUddddddddddddddddddddddddddddUUUUUUU")

      // const formData = new FormData();
      // formData.append("email", values.email);
      // formData.append("message", values.message);
      // formData.append("first_name", values.first_name);
      // formData.append("last_name", values.last_name);
      // formData.append("interested_amount", values.interested_amount);
      // formData.append("lead_investor", values.lead_investor);
      // formData.append("startup_id", startup_id); 
      // formData.append("startup_round_id", round_id);

      const payload = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        interested_amount: values?.interested_amount,
        lead_investor: values?.lead_investor,
        email: values?.email,
        kyc: values?.kyc,
        agreement_signed: values?.agreement_signed,
        call_for_money: values?.call_for_money,
        fund_received: values?.fund_received,
      }

      try {
        if (!isEditMode) {
          await addInvestorAsync({ payload: { ...values, status: value }, _id: round_id }).then(() => {
            refetchListing()
            handleClose()
          });
        }
        else {
          await editInvestorAsync({ payload: { ...values }, _id: round_id }).then(() => {
            refetchListing()
            handleClose()
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "8px",
      }}
    >
      <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container padding={"1.5rem"} columnSpacing={2} rowSpacing={1}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                  marginBottom: "1.5rem"
                }}
              >
                {isEditMode ? "Edit" : "Add"} Investor
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: theme.palette.customColors.g200,
                }}
              >
                Simply add their email addresses and send the invitation.
              </Typography> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInputField
                name="first_name"
                label={"FIRST NAME"}
                placeholder={"Enter first name"}
                value={formik.values?.first_name}
                onChange={(e) => {
                  // if (!isEditMode) {
                  formik.setFieldValue("first_name", e.target.value
                    ?.charAt(0)
                    ?.toUpperCase() +
                    e.target.value?.slice(1));
                  // }
                }}
                disabled={currentRow?.user_id}
                sx={{ marginBottom: formik.errors?.first_name ? 0 : 1 }}
              />

            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInputField
                name="last_name"
                label={"LAST NAME"}
                placeholder={"Enter last name"}
                value={formik.values?.last_name}
                onChange={(e) => {
                  // if (!isEditMode) {
                  formik.setFieldValue("last_name", e.target.value
                    ?.charAt(0)
                    ?.toUpperCase() +
                    e.target.value?.slice(1));
                  // }
                }}
                disabled={currentRow?.user_id}
                sx={{ marginBottom: formik.errors?.last_name ? 0 : 1 }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInputField
                name="email"
                label={"EMAIL"}
                disabled={isEditMode}
                placeholder={"Enter email"}
                value={formik.values.email}
                onChange={(e) => {
                  if (!isEditMode) {
                    formik.setFieldValue("email", String(e.target.value)?.toLowerCase());
                  }
                }}
                sx={{ marginBottom: formik.errors?.email ? 0 : 1 }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInputField
                name="interested_amount"
                label={<Box display="flex" alignItems="center" gap="4px">
                  INTERESTED AMOUNT
                </Box>}
                placeholder={"Enter interested amount"}
                value={formik.values?.interested_amount}
                onChange={(e) => {
                  formik.setFieldValue("interested_amount", e.target.value);
                }}
                sx={{ marginBottom: formik.errors?.interested_amount ? 0 : 1 }}
                InputProps={{
                  ...currencyInputProps,
                  startAdornment: (
                    <Box sx={{ padding: "0 0 0 1rem" }}>
                      {currency}
                    </Box>
                  ),
                }}
              />
            </Grid>
            {value != "pipeline" ? <>
              <Grid item xs={6}>
                <CustomInputField
                  name="allocated_amount"
                  label={"ALLOCATED AMOUNT"}
                  placeholder={"Enter allocated amount"}
                  value={formik.values.allocated_amount}
                  onChange={(e) => {
                    formik.setFieldValue("allocated_amount", e.target.value);
                  }}
                  sx={{ marginBottom: formik.errors?.allocated_amount ? 0 : 1 }}
                  InputProps={{
                    ...currencyInputProps,
                    startAdornment: (
                      <Box sx={{ padding: "0 0 0 1rem" }}>
                        {currency}
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <CustomLabel sx={{ marginBottom: 0 }}
                >LEAD INVESTOR</CustomLabel>
                <CustomSwitch checked={formik.values.lead_investor} onChange={(e) => {
                  formik.setFieldValue("lead_investor", e.target.checked);
                }} />
              </Grid>
              <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <CustomLabel sx={{ marginBottom: 0 }} >KYC</CustomLabel>
                <CustomSwitch checked={formik.values.kyc} onChange={(e) => {
                  formik.setFieldValue("kyc", e.target.checked);
                }} />
              </Grid>
              <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <CustomLabel sx={{ marginBottom: 0 }} >AGREEMENTS SIGNED</CustomLabel>
                <CustomSwitch checked={formik.values.agreement_signed} onChange={(e) => {
                  formik.setFieldValue("agreement_signed", e.target.checked);
                }} />
              </Grid>
              <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <CustomLabel sx={{ marginBottom: 0 }} >CALL FOR MONEY</CustomLabel>
                <CustomSwitch checked={formik.values.call_for_money} onChange={(e) => {
                  formik.setFieldValue("call_for_money", e.target.checked);
                }} />
              </Grid>
              <Grid item xs={6} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <CustomLabel sx={{ marginBottom: 0 }} >FUND RECEIVED</CustomLabel>
                <CustomSwitch checked={formik.values.fund_received} onChange={(e) => {
                  formik.setFieldValue("fund_received", e.target.checked);
                }} />
              </Grid>
            </> : <Grid item xs={6} >
              <CustomLabel sx={{ marginBottom: 0 }} >LEAD INVESTOR</CustomLabel>
              <CustomSwitch checked={formik.values.lead_investor} onChange={(e) => {
                formik.setFieldValue("lead_investor", e.target.checked);
              }} />
            </Grid>}
            <Grid item xs={12}>
              <B300
                type="submit"
                fullWidth
                disabled={isUpdatingInvestor || isUploadingInvestor}
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem", marginTop: '1.5rem', height: '52px' }}
              >
                {isUpdatingInvestor || isUploadingInvestor ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.2rem'} /> : isEditMode ? "Update" : "Add"}
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default AddInvestorPopup;
