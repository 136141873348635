import { Box, FormControlLabel, Grid, RadioGroup, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'
import CustomInputField from 'ui-components/formik/CustomInputField'
import { AddStartup, FetchAllCities, FetchAllCountries, FetchAllCountriesWithPhoneCodes, FetchAllPopularCities, FetchAllPopularCountries, FetchAllStages, FetchAllStates } from '../../../api'
import CustomLabel from 'ui-components/CustomLabel'
import { useTheme } from '@emotion/react'
import CustomValidationText from 'ui-components/CustomValidationText'
import CustomRadio from 'ui-components/CustomRadio'

const StartupProfile = () => {
    const formik = useFormikContext()
    const theme = useTheme();
    // React.useEffect(() => {
    //     formik.setFieldValue("raised_round", formik?.values?.raised_round != null ? formik?.values?.raised_round : null);
    // }, [formik.values])
    const { data: startup_stages, isRefetching: isRefetchingStages, isLoading: isLoadingStages, refetch: refetchStages } = FetchAllStages()
    return (
        <>
            <Grid item xs={12} sm={12} height={"min-content"}>
                <CustomInputField
                    name="startup_name"
                    label="BUSINESS NAME"
                    pattern="^[^@$()]*$"  // This regex pattern excludes @, $, (, and )
                    placeholder="Enter business name"
                    value={formik.values?.startup_name}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 30 && /^[^@$()]*$/.test(value)) {
                            formik.setFieldValue(
                                "startup_name",
                                value.charAt(0).toUpperCase() + value.slice(1)
                            );
                        }
                    }}
                    fullWidth
                />

            </Grid>

            <Grid item container xs={12} gap={1} display={"flex"} paddingBottom={2}>
                <Grid item xs={12}>
                    <CustomLabel sx={{ marginBlock: "0rem" }}>WHAT IS THE STAGE OF YOUR STARTUP?</CustomLabel>
                </Grid>

                {!isLoadingStages ? startup_stages?.map((stage, index) => <Grid key={index} onClick={() => formik.setFieldValue("stage_id", stage?._id)} item xs={12} sm={5.8} md={5.6} lg={5.75} flex={1}>
                    <button key={index} onClick={(e) => e.preventDefault()} style={{
                        minHeight: "7rem",
                        height: "100%",
                        maxHeight: '7rem',
                        width: "100%",
                        padding: "1rem 0rem 0rem 0.5rem",
                        background: formik.values.stage_id === stage?._id ? "#FFFAE6" : "#FFF",
                        fontSize: "0.875rem",
                        cursor: 'pointer',
                        border: `1px solid ${formik.values.stage_id === stage?._id ? "#FFC900" : "#A1A3A7"}`,
                        borderRadius: "8px",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "space-between",
                        position: 'relative'
                    }}>
                        <span style={{ position: 'absolute', fontWeight: 600, top: "5px" }}>
                            {stage?.name}
                        </span>
                        {<Typography sx={{ position: 'absolute', top: "2rem", fontSize: { xs: 0, md: '0.875rem' } }}>
                            {index < 2 ? (index === 0 ? "Ideation stage" : "Product development") : index === 2 ? "Product market fit" : index === 3 ? "Scaling up" : 'IPO readiness'}
                        </Typography>}
                        <img rel='preload' src={stage?.image_url} style={{ ...stage?.size, marginLeft: 'auto' }} /></button>
                </Grid>
                )
                    :
                    <Grid item xs={12}><Skeleton height={260} sx={{ transform: "scale(1)" }} width={"100%"} /> </Grid>
                }

                <Grid item xs={12} lg={12} mt={1} height={"min-content"} pb={1}>
                    {formik.errors.stage_id && (
                        <CustomValidationText value={formik.errors.stage_id} style={{ textTransform: 'none', marginTop: 0 }} />
                    )}
                    <CustomLabel sx={{ marginTop: "8px" }}>
                        HAVE YOU RAISED FUNDS EARLIER?
                    </CustomLabel>
                    <RadioGroup
                        sx={{ flexDirection: "row", paddingBottom: 0 }}
                        name="raised_round"
                        value={formik.values?.raised_round != null ? formik.values?.raised_round : null}
                        onChange={(e) =>
                            formik.setFieldValue(
                                `raised_round`,
                                e.target.value
                            )
                        }
                    >
                        <FormControlLabel
                            value={true}
                            control={<CustomRadio disableRipple />}
                            label={"Yes"}
                        />
                        <FormControlLabel
                            value={false}
                            control={<CustomRadio disableRipple />}
                            label={"No"}
                        />
                        {formik.errors.raised_round && formik.touched.raised_round && (
                            <CustomValidationText value={formik.errors.raised_round} style={{ marginLeft: 0 }} />
                        )}
                    </RadioGroup>
                </Grid>
            </Grid>

        </>
    )
}

export default StartupProfile
