import React from 'react';
import { InputAdornment, Select, MenuItem } from '@mui/material';
import CustomInputField from './formik/CustomInputField';
import { useTheme } from '@emotion/react';

// const countryCodes = [
//   { code: '+1', label: 'US' },
//   { code: '+91', label: 'IN' },
// ];

const CustomPhone = ({ label, placeholder, countryCodes, selectedCode, handleChange = () => { }, renderValue = (value) => value?.includes("+") ? value : "+" + value ,...props }) => {
  const theme = useTheme();
  // const [selectedCode, setSelectedCode] = React.useState(countryCodes[0].code);

  // const handleChange = (event) => {
  //   setSelectedCode(event.target.value);
  // };

  return (
    <div>
      <CustomInputField
        label={label}
        placeholder={placeholder}
        // type="number"
        InputProps={{
          inputMode: 'numeric', // Use numeric keyboard on mobile devices
          pattern: "[0-9]*", // Only allow digits 0-9
          // title: "Please enter a number between 0 and 9",
          style: {
            textAlign: 'center', // Center the text in the input
          },
          min: 0,
          onKeyDown: (event) => {
            // Prevent the minus sign (-) from being entered
            if (event.key === '-') {
              event.preventDefault();
            }
          },
          onKeyPress: (event) => {
            // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
            if (event.key === '-') {
              event.preventDefault();
            }
          },
          onKeyPress: (event) => {
            // Get the input value
            const inputValue = event.key;

            // Allow only numeric characters (0-9) and certain control keys
            const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];

            // Check if the pressed key is in the allowedKeys array
            if (!allowedKeys.includes(inputValue)) {
              // If not, prevent the key from being entered
              event.preventDefault();
            }
          },
          onInput: (event) => {
            // Get the input value
            const inputValue = event.target.value;

            // Remove non-numeric characters using a regular expression
            const numericValue = inputValue.replace(/[^0-9]/g, '');

            // Update the input field value with the filtered numeric value
            event.target.value = numericValue;
          },
          onKeyDown: (event) => {
            // Prevent the input of minus sign (key code 189)
            if (event.keyCode === 189 || event.key === "-") {
              event.preventDefault();
            }
          },
          disableUnderline: true,
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                padding: '0px 0',
                height: '49px',
                maxHeight: 'none',
                borderRight: `1px solid ${theme.palette.customColors.g75}`,
                marginRight: '0px',
                display: 'flex',
                alignItems: 'center',
                marginTop: '0px!important'
              }}
            >
              <Select
                value={selectedCode}
                onChange={handleChange}
                variant="standard"
                disableUnderline
                onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
                sx={{ "& > .MuiSelect-select": { background: 'white', paddingBlock: '0.8rem', paddingLeft: '0.85rem', borderRadius: '4px 0 0 4px' }}}
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginLeft: '-8px',
                      marginTop: '10px',
                      width: 'max-content',
                    },
                  },
                }}
                renderValue={renderValue}
              >
                {countryCodes.map((option) => (
                  <MenuItem type='button' key={option.code} value={option.code}>
                    {option.label} ({option.code})
                  </MenuItem>
                ))}
              </Select>
            </InputAdornment>
          )
        }}
        {...props}
      />
    </div>
  );
};

export default CustomPhone;
