import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Box,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  styled,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  B300,
  B400,
  BLUEBORDER,
  WHITEBG,
} from "../../../ui-components/CustomButton";
import { ReactComponent as gpsIcon } from "../../../assets/gps.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTheme } from "@emotion/react";
import CustomInputField from "../../../ui-components/formik/CustomInputField";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import { AddInvestmentForRound } from "../../../api";
import * as Yup from "yup";
import CustomLabel from "../../../ui-components/CustomLabel";
import CustomValidationText from "../../../ui-components/CustomValidationText";
import { ReactComponent as UploadDocumentsIcon } from "../../../assets/rounds_icons/upload_documents_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/rounds_icons/delete_icon.svg";
import { Add } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { containerRef, currencyInputProps } from "../../../utilities";
import CustomRadio from "../../../ui-components/CustomRadio";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddInvestmentPopup = ({ open, handleClose, handleSubmit, currency }) => {
  const { id: round_id } = useParams();
  const startup_id = useSelector((state) => state.startupDetails.data)?._id;
  // console.log(startup_id,"const {id: round_id} = useParams();")

  const {
    mutateAsync: addInvestmentForRoundAsync,
    isLoading: isAddingInvestmentForRound,
    status: addInvestmentForRoundStatus,
    error: addInvestmentForRoundError,
    data: addInvestmentForRoundResponse,
  } = AddInvestmentForRound();
  const [validationObj, setValidationObj] = React.useState({
    email: Yup.string().required("Email is required"),
  });
  const bankValidationSchema = Yup.object({ ...validationObj });
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      interested_amount: 0,
      allocated_amount: 0,
      sha_documents: [
        {
          document_name: "",
          document: null,
        },
      ],
      call_for_money: false,
      price_per_share: 0,
      no_of_shares: 0,
      status: "",
      // startup_id: '',
      // startup_round_id: ''
    },
    validationSchema: bankValidationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      // const formData = new FormData();
      // formData.append("first_name[0]", values?.first_name?.trim());
      // formData.append("last_name[0]", values?.last_name?.trim());
      // formData.append("email[0]", values?.email?.trim());
      // formData.append("interested_amount[0]", values?.interested_amount);
      // formData.append("allocated_amount[0]", values?.allocated_amount);
      // formData.append("call_for_money[0]", values?.call_for_money);
      // formData.append("price_per_share[0]", values?.price_per_share);
      // formData.append("no_of_shares[0]", values?.no_of_shares);
      // // values?.sha_documents?.forEach((item, index) => {
      // //     formData.append(`document_name[${index}]`,(item?.document_name)?.trim())
      // //     formData.append(`documents${index}`,item?.document)
      // // })
      // formData.append("status[0]", values?.status);
      // // formData.append("startup_id",startup_id)
      // formData.append("startup_round_id", round_id);
      // addInvestmentForRoundAsync({ payload: formData })?.then(() => {
        handleClose();
      // });

      // console.log({...values, startup_id: startup_id, startup_round_id: round_id})
    },
  });

  const theme = useTheme();

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setValues({ ...formik.values, [name]: value });
  };

  const placeholders = {
    first_name: "Enter first name",
    last_name: "Enter last name",
    email: "Enter email",
    interested_amount: "Enter interested amount",
    allocated_amount: "Enter allocated amount",
    // sha_documents: [],
    // call_for_money: false,
    price_per_share: "Enter price per share",
    no_of_shares: "Enter no. of shares",
    status: "Select status",
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <IconButton
          sx={{ position: "absolute", top: 15, right: 20, padding: 0 }}
        >
          <CloseIcon width={40} height={40} onClick={handleClose} />
        </IconButton>
        <DialogTitle sx={{ marginInline: "auto" }}>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: 700,
              lineHeight: "28px",
              color: theme.palette.customColors.g300,
              paddingLeft: "0.5rem",
            }}
          >
            Investment Details
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 0, paddingInline: 0 }}>
          <PerfectScrollbar
          containerRef={containerRef}
            style={{
              maxHeight: "calc(-250px + 100vh)",
              paddingInline: "2rem",
            }}
          >
            <Grid item container xs={12} columnSpacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInputField
                  // margin="dense"
                  label="FIRST NAME"
                  name="first_name"
                  value={formik.values?.first_name}
                  onChange={handleChange}
                  fullWidth
                  placeholder={placeholders?.first_name}
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInputField
                  // margin="dense"
                  label="LAST NAME"
                  name="last_name"
                  value={formik.values?.last_name}
                  onChange={handleChange}
                  fullWidth
                  placeholder={placeholders?.last_name}
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInputField
                  // margin="dense"
                  label="EMAIL"
                  name="email"
                  value={formik.values?.email}
                  onChange={handleChange}
                  fullWidth
                  placeholder={placeholders?.email}
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInputField
                  // margin="dense"
                  InputProps={{
                    ...currencyInputProps,
                    startAdornment: (
                      <Box sx={{ padding: "0 0 0 1rem" }}>{currency}</Box>
                    ),
                  }}
                  label="INTERESTED AMOUNT"
                  name="interested_amount"
                  value={formik.values?.interested_amount}
                  onChange={handleChange}
                  fullWidth
                  placeholder={placeholders?.interested_amount}
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInputField
                  // margin="dense"
                  InputProps={{
                    ...currencyInputProps,
                    startAdornment: (
                      <Box sx={{ padding: "0 0 0 1rem" }}>{currency}</Box>
                    ),
                  }}
                  label="ALLOCATED AMOUNT"
                  name="allocated_amount"
                  value={formik.values?.allocated_amount}
                  onChange={handleChange}
                  fullWidth
                  placeholder={placeholders?.allocated_amount}
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomLabel>SHA DOCUMENTS</CustomLabel>
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.customColors.g75}`,
                    borderRadius: "8px",
                    padding: "0.5rem",
                  }}
                >
                  <Grid item container xs={12} columnSpacing={1}>
                    <FieldArray
                      name="sha_documents"
                      render={(arrayHelpers) => {
                        return (
                          <>
                            {formik.values.sha_documents?.map((item, index) => {
                              return (
                                <>
                                  <Grid item xs={12} sm={5} mt={index != 0 ? 1 : 0}>
                                    <CustomInputField
                                      // margin="dense"
                                      label="DOCUMENT NAME"
                                      name={`sha_documents.${index}.document_name`}
                                      value={item?.document_name}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          `sha_documents.${index}.document_name`,
                                          e.target.value
                                        )
                                      }
                                      fullWidth
                                      // placeholder={placeholders?.last_name}
                                      placeholder={"Enter document name"}
                                      sx={{ marginBottom: "0.5rem" }}
                                    />
                                  </Grid>
                                  <Grid item xs={8} sm={5}  mt={index != 0 ? 1 : 0}>
                                    <CustomLabel>UPLOAD FILE</CustomLabel>
                                    <Box>
                                      <WHITEBG
                                        component="label"
                                        role={undefined}
                                        tabIndex={-1}
                                        fullWidth
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          border: `1px solid ${theme.palette.customColors.g75}`,
                                          borderRadius: "8px",
                                          height: "52px",
                                          padding: "10px, 14px, 10px, 14px",
                                          color: "#7A7D83",
                                          textTransform: "none",
                                          marginBottom: "-1rem",
                                        }}
                                      >
                                        {item?.document != null
                                          ? String(item?.document?.name)
                                              ?.length > 19
                                            ? String(
                                                item?.document?.name
                                              )?.substring(0, 19) + "..."
                                            : item?.document?.name
                                          : "Upload"}
                                        <UploadDocumentsIcon
                                          style={{
                                            width: 15,
                                            height: 15,
                                            stroke: "#344054",
                                            padding: "8px",
                                            borderRadius: "8px",
                                            border: `1px solid ${theme.palette.customColors.gray300}`,
                                          }}
                                        />
                                        <VisuallyHiddenInput
                                          accept=".pdf,.png,.jpeg,.jpg,.mp4,.mov,.xlsx,.csv,.xls,.doc,.docx"
                                          type="file"
                                          name={`sha_documents.${index}.document`}
                                          //  value={item?.reports}
                                          id={`sha_documents.${index}.document`}
                                          onChange={(e) => {
                                            const file = e.target.files[0];
                                            formik.setFieldValue(
                                              `sha_documents.${index}.document`,
                                              file
                                            );
                                          }}
                                        />
                                      </WHITEBG>
                                    </Box>
                                    {formik?.errors?.sha_documents &&
                                      formik.errors.sha_documents[index] &&
                                      formik?.errors.sha_documents[index]
                                        ?.reports && (
                                        <CustomValidationText
                                          value={
                                            formik?.errors.sha_documents[index]
                                              ?.reports
                                          }
                                        />
                                      )}
                                  </Grid>
                                  <Grid item xs={4} sm={2}  mt={index != 0 ? 1 : 0}>
                                    <CustomLabel sx={{ opacity: 0 }}>
                                      FILE
                                    </CustomLabel>
                                    {/* <B400 fullWidth sx={{ padding: '0.85rem 1.25rem', fontSize: '1rem' }} onClick={() => arrayHelpers?.insert(index + 1, {document_name: '', document: null})}> */}
                                    {index ===
                                    formik.values.sha_documents?.length - 1 ? (
                                      <WHITEBG
                                        // startIcon={<Add />}
                                        sx={{
                                          borderRadius: "8px",
                                          borderWidth: "1px",
                                          padding: "12px 10px",
                                          width: "100%",
                                          // marginBottom: 2,
                                          borderColor:
                                            theme.palette.customColors.g75,
                                          color:
                                            theme.palette.customColors.g200,
                                          fontWeight: 700,
                                        }}
                                        onClick={() =>
                                          arrayHelpers?.insert(index + 1, {
                                            document_name: "",
                                            document: null,
                                          })
                                        }
                                      >
                                        {/* Add */}
                                        <Add />
                                      </WHITEBG>
                                    ) : (
                                      <BLUEBORDER
                                        fullWidth
                                        sx={{
                                          color:
                                            theme.palette.customColors.r200,
                                          border: `1px solid ${theme.palette.customColors.r100}`,
                                          height: "52px",
                                          width: "100%",
                                        }}
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                      >
                                        {/* Delete */}
                                        <DeleteIcon />
                                      </BLUEBORDER>
                                    )}

                                    {/* </B400> */}
                                  </Grid>
                                </>
                              );
                            })}
                          </>
                        );
                      }}
                    />
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} mt={1}>
                <CustomLabel>CALL FOR MONEY</CustomLabel>
                <RadioGroup
                  sx={{ flexDirection: "row", marginBottom: 1 }}
                  name="call_for_money"
                  value={formik.values.call_for_money}
                  onChange={(e) =>
                    formik.setFieldValue(`call_for_money`, e.target.value)
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<CustomRadio disableRipple />}
                    label={"Yes"}
                  />
                  <FormControlLabel
                    value={false}
                    control={<CustomRadio disableRipple />}
                    label={"No"}
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <CustomInputField
                  // margin="dense"
                  InputProps={{
                    ...currencyInputProps,
                  }}
                  label="PRICE PER SHARE"
                  name="price_per_share"
                  value={formik.values?.price_per_share}
                  onChange={handleChange}
                  fullWidth
                  placeholder={placeholders?.price_per_share}
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInputField
                  // margin="dense"
                  InputProps={{
                    ...currencyInputProps,
                  }}
                  label="NO. OF SHARES"
                  name="no_of_shares"
                  value={formik.values?.no_of_shares}
                  onChange={handleChange}
                  fullWidth
                  placeholder={placeholders?.no_of_shares}
                  sx={{ marginBottom: "0.5rem" }}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <CustomInputField
                                // margin="dense"
                                label="NO. OF SHARES"
                                name="no_of_shares"
                                value={formik.values?.no_of_shares}
                                onChange={handleChange}
                                fullWidth
                                placeholder={placeholders?.no_of_shares}
                                sx={{ marginBottom: '0.5rem' }}
                            /> */}
                <CustomLabel>STATUS</CustomLabel>
                <Select
                  name="status"
                  value={formik.values?.status}
                  onChange={handleChange}
                  placeholder={placeholders?.status}
                  sx={{ marginBottom: "0.5rem" }}
                  fullWidth
                >
                  {[
                    {
                      value: "invited",
                      label: "Invited",
                      count: 20,
                      read: true,
                    },
                    {
                      value: "interested",
                      label: "Interest Shown",
                      count: 50,
                      read: true,
                    },
                    {
                      value: "transferred",
                      label: "SHA Document",
                      count: 10,
                      read: false,
                    },
                    {
                      value: "callForMoney",
                      label: "Call for Money",
                      count: 10,
                      read: false,
                    },
                    {
                      value: "invested",
                      label: "Invested",
                      count: 20,
                      read: true,
                    },
                  ]?.map((option) => (
                    <MenuItem value={option?.value}>{option?.label}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </PerfectScrollbar>
        </DialogContent>
        <DialogActions
          sx={{ paddingInline: "1.5rem", paddingBottom: "1.5rem" }}
        >
          {/* <Button onClick={handleClose}>Cancel</Button> */}
          <B300
            type="submit"
            fullWidth
            sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
          >
            Update
          </B300>
          {/* <B300 sx={{ background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", width: '100%' }} onClick={handleSave}>Save</B300> */}
        </DialogActions>
        {/* </Dialog> */}
      </Form>
    </FormikProvider>
  );
};

export default AddInvestmentPopup;
