import { Box, Button, CircularProgress, Divider, FormControlLabel, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Modal, Paper, Radio, RadioGroup, Select, Skeleton, Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Wrapper from 'ui-components/Wrapper'
import bgImage from 'assets/login_screen_image.png'
import { B300 as CustomButton, BLUEBORDER, WHITEBG } from 'ui-components/CustomButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import startupInformationIcon from "assets/startup_information.png"
import { ReactComponent as gpsIcon } from "assets/gps.svg"
import CustomAutoComplete from 'ui-components/CustomAutoComplete'
import { FieldArray, Form, FormikProvider, useFormik } from 'formik'
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import CustomInputField from 'ui-components/formik/CustomInputField'
import CustomBackgroundComponent from 'ui-components/CustomBackgroundComponent'
import CustomLabel from 'ui-components/CustomLabel'
import * as Yup from "yup"
import toast from 'react-hot-toast'
import { UploadPitchDeckOnboarding, FetchAllSectors, FetchAllStages, FetchStartupDetails, AddStartup, FetchAllCategories, FetchAllSubCategories, FetchAllRoundTypes, FetchAllCurrencyLists, FetchAllBusinessModel } from 'api'
import Cookies from 'js-cookie'
import { useTheme } from '@emotion/react'
import CustomToast from 'ui-components/CustomToast'
import AuthWrapper1 from 'ui-components/AuthWrapper1'
import CustomHeaderIcon from 'ui-components/CustomHeaderIcon'
import { containerRef, currencyInputProps, getFileCategory, getFileIcon, handleFormSubmit } from 'utilities'
import CustomValidationText from 'ui-components/CustomValidationText'
import { styled } from '@mui/styles'
import CustomRadio from 'ui-components/CustomRadio'
import { ReactComponent as UploadDocumentsIcon } from "assets/rounds_icons/upload_documents_icon.svg";
import { Build, LocalMallOutlined, MemoryOutlined, MiscellaneousServicesOutlined, StorefrontOutlined } from '@mui/icons-material'
import { FocusError } from 'focus-formik-error'
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const referral_types = [
    {
        value: "Friends",
        label: "Friends",
    },
    {
        value: "Incubator",
        label: "Incubator",
    },
    {
        value: "LinkedIn",
        label: "LinkedIn",
    },
    {
        value: "Google",
        label: "Google",
    },
    {
        value: "Other",
        label: "Other",
    },
];

const inputProps = {
    maxLength: 1, // Restrict input to a single character
    inputMode: 'numeric', // Use numeric keyboard on mobile devices
    pattern: "[0-9]*", // Only allow digits 0-9
    // title: "Please enter a number between 0 and 9",
    style: {
        textAlign: 'center', // Center the text in the input
    },
    min: 0,
    onKeyDown: (event) => {
        // Prevent the minus sign (-) from being entered
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Get the input value
        const inputValue = event.key;

        // Allow only numeric characters (0-9) and certain control keys
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];

        // Check if the pressed key is in the allowedKeys array
        if (!allowedKeys.includes(inputValue)) {
            // If not, prevent the key from being entered
            event.preventDefault();
        }
    },
    onInput: (event) => {
        // Get the input value
        const inputValue = event.target.value;

        // Remove non-numeric characters using a regular expression
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        // Update the input field value with the filtered numeric value
        event.target.value = numericValue;
    },
    onKeyDown: (event) => {
        // Prevent the input of minus sign (key code 189)
        if (event.keyCode === 189 || event.key === "-") {
            event.preventDefault();
        }
    },
}

const UploadPitchDeck = ({ step, formationDate, setFormationDate, formationDateError, currentStep, setCurrentStep, isLoading, }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
    // let stepPitchDeck = new URLSearchParams(window.location.search).get("page");
    let stepPitchDeck = parseInt(Cookies.get("step"));
    const [searchParams, setSearchParams] = useSearchParams()
    const [progress, setProgress] = React.useState(1);
    const {
        mutateAsync: AddStartupAsync,
        status: AddStartupStatus,
        error: AddStartupError,
        isLoading: isUpdatingStartup,
        data: AddStartupResponse,
    } = AddStartup({ setProgress });

    const [currentStepPitchDeck, setCurrentStepPitchDeck] = React.useState(Number(stepPitchDeck) || 4);
    const { data: round_types, isRefetching: isRefetchingRoundTypes, isLoading: isLoadingRoundTypes, refetch: refetchRoundTypes } = FetchAllRoundTypes();
    const [currentSubCategory, setCurrentSubCategory] = React.useState('');
    const [searchSubCategory, setSearchSubCategory] = React.useState('');

    const {
        mutateAsync: uploadPitchDeckAsync,
        status: UploadPitchStatus,
        error: UploadPitchError,
        isLoading: isUploadingPitchDeck,
        data: UploadPitchDeckResponse,
    } = UploadPitchDeckOnboarding();
    const { data: startupDetailsData, isRefetching: isStartupDetailsRefetching, isLoading: isStartupDetailsLoading, refetch: refetchStartupDetails } = FetchStartupDetails()

    const validationObj =
        currentStepPitchDeck == 4
            ? {
                stage_id: Yup.string().required("Stage is required").min(2),
                startup_name: Yup.string("Enter business name")
                    .required("Business name is required")
                    .trim(),
                raised_round: Yup.boolean("This field is required").typeError("This field is required")
                    .test("", "This field is required", (value) => {
                        return value !== null;
                    }).required("This field is required")
            } :
            currentStepPitchDeck == 5 ? {
                category_id: Yup.string().required("Category is required."),
                sector_id: Yup.string().required('Sector is required.').matches(/^[a-fA-F0-9]{24}$/, 'Sector is required.'),
                business_model_id : Yup.array().required("Business Model is required.").min(1,"Business Model is required."),
            } :
                {
                    pitch_deck: Yup.mixed()
                        .test("file-upload", "Please upload pitch deck", (value) => {
                            return value !== null;
                        })
                        .test("file-type", "Only PDF files are allowed", (value) => {
                            if (value && typeof value === 'object') {
                                return value.type === 'application/pdf';
                            }
                            return true; // If value is not an object, skip type validation
                        })
                        .test("file-size", "File size should not exceed 5MB", (value) => {
                            if (value && typeof value === 'object') {
                                return value.size <= 50 * 1024 * 1024; // 100MB in bytes
                            }
                            return true; // If value is not an object, skip size validation
                        })
                        .required("Pitch deck is required"),
                    round_name: Yup.string().required("Round name is required"),
                    round_type: Yup.string().required("Round type is required"),
                    total_round_size: Yup.string().required("Total round size is required"),
                    currency_id: Yup.string().required("Please select currency"),
                }


    // console.log(startupDetailsData?.status,startupDetailsData?.data, "startupDetailsData")

    const validationSchema = Yup.object({ ...validationObj });
    const formik = useFormik({
        initialValues: !isStartupDetailsLoading && startupDetailsData ? startupDetailsData?.data : {
            pitch_deck: null,
            // description: "",
            startup_name: "",
            raised_round: null,
            sector_id: "",
            category_id: '',
            business_model_id:[],
            stage_id: '',
            round_name: '',
            round_type: '',
            total_round_size: '',
            currency_id: '',
        },
        validationSchema: validationSchema,
        // validate: (values) => { console.log(values, "valueseferfe"); console.log(formik.errors, "err") },
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            const formData = new FormData();

            if (currentStepPitchDeck == 4) {
                formData.append("startup_name", values.startup_name);
                formData.append("stage_id", values.stage_id);
                formData.append("raised_round", values?.raised_round);
            }
            else if (currentStepPitchDeck == 5) {
                formData.append("sector_id", [values.sector_id])
                formData.append("category_id", values.category_id);
                values.business_model_id?.forEach((current) => {
                    formData.append("business_model_id" , current)
                  })

            }
            else if (currentStepPitchDeck == 6) {
                formData.append("pitch_deck", values.pitch_deck);
                formData.append("is_profile_completed", true)
                formData.append("round_name", values.round_name);
                formData.append("round_type", values.round_type);
                formData.append("total_round_size", values.total_round_size);
                formData.append("currency_id", values.currency_id);
            }

            // formData.append("startup_name", values.startup_name);
            // if (currentStepPitchDeck === 3) {
            //     uploadPitchDeckAsync({ payload: formData, startup_id: formik.values?._id }).catch(err => {

            //         toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            //             duration: 4000,
            //             position: "top-right",
            //             style: {
            // padding: "15px 30px",
            //             },
            //         });
            //     });
            // }
            // else {
            AddStartupAsync(formData).catch(err => {

                toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                    duration: 4000,
                    position: "top-right",
                    style: {
                        padding: "15px 30px",
                    },
                });
            });
            // }
        },
    });

    // React.useEffect(() => {
    //     if (currentStepPitchDeck === 3 && UploadPitchDeckResponse && UploadPitchDeckResponse?.data?.statusCode == 200) {
    //         // console.log(AddStartupResponse,"res")
    //         Cookies.set("is_profile_complete", true);
    //         navigate("/thank-you");
    //     }
    //     window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
    // }, [UploadPitchDeckResponse?.data?.statusCode]);

    React.useEffect(() => {
        if (currentStepPitchDeck <= 5 && AddStartupResponse && AddStartupResponse?.data?.statusCode == 200) {
            setCurrentStepPitchDeck(currentStepPitchDeck + 1);
            Cookies.set("step", currentStep + 1)
            // setSearchParams({ page: currentStepPitchDeck + 1 });
        }
        else if (currentStepPitchDeck == 6 && AddStartupResponse && AddStartupResponse?.data?.statusCode == 200) {
            Cookies.set("is_profile_complete", true);
            Cookies.remove("step");
            navigate("/thank-you");
        }
        window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
    }, [AddStartupResponse?.data?.statusCode]);
    // const [selectedBusinessModel, setSelectedBusinessModel] = React.useState("");
    const { data: startup_business_model , isRefetching: isRefetchingBusinessModel, isLoading: isLoadingBusinessModel, refetch: refetchBusinessModel } = FetchAllBusinessModel({  })
    const [currentBusinessModel, setCurrentBusinessModel] = React.useState([]);
    const [searchBusinessModel, setSearchBusinessModel] = React.useState('');
    const [selectedCategory, setSelectedCategory] = React.useState("");
    // const { mutateAsync: uploadPitchDeckAsync, status: AddStartupStatus, error: AddStartupError, isLoading: isVerifying, data: AddStartupResponse } = AddStartup();
    const { data: startup_stages, isRefetching: isRefetchingStages, isLoading: isLoadingStages, refetch: refetchStages } = FetchAllStages()
    const { data: startup_categories, isRefetching: isRefetchingCategories, isLoading: isLoadingCategories, refetch: refetchCategories } = FetchAllCategories()
    const { data: startup_sub_categories, isRefetching: isRefetchingSubCategories, isLoading: isLoadingSubCategories, refetch: refetchSubCategories } = FetchAllSubCategories({ category: selectedCategory })

    // Effect to set selected category based on formik values
    React.useEffect(() => {
        if (!isStartupDetailsLoading && !isLoadingCategories && startupDetailsData?.data && Array.isArray(startup_categories)) {
            const category = startup_categories.find(item => item?._id === formik?.values?.category_id);
            if (category) {
                setSelectedCategory(category.name);  // Or whichever property you want to use
            }
        }
    }, [isStartupDetailsLoading, isLoadingCategories, startupDetailsData?.data, startup_categories, formik?.values?.category_id]);

    // Effect to refetch subcategories when selectedCategory changes
    React.useEffect(() => {
        if (!isRefetchingCategories && !isLoadingCategories && selectedCategory) {
            refetchSubCategories();
        }
    }, [selectedCategory, isRefetchingCategories || isLoadingCategories]);

    React.useEffect(() => {
        if (formik.values.sector_id && startup_sub_categories) {
            const matchedSubCategory = startup_sub_categories.find(subCat => subCat._id === formik.values.sector_id);
            if (matchedSubCategory) {
                setCurrentSubCategory({ label: matchedSubCategory?.sub_category, value: matchedSubCategory?._id, description: matchedSubCategory?.description });
            }
        }
    }, [formik.values.sector_id, startup_sub_categories]);

    React.useEffect(() => {
        if (formik.values.round_type && round_types) {
            const matchedRoundType = round_types.find(subCat => subCat._id === formik.values.round_type);
            // console.log(matchedRoundType, "matchedRoundType")
            if (matchedRoundType) {
                setCurrentRoundType({ label: matchedRoundType?.name, value: matchedRoundType?._id, description: matchedRoundType?.sub_title });
            }
        }
    }, [formik.values.round_type, round_types]);
    React.useEffect(() => {
        if (!isLoading && !isLoadingBusinessModel && formik?.values && Array.isArray(startup_business_model)) {
            const businessModel = startup_business_model?.filter(item => formik?.values?.business_model_id?.includes(item?._id) );
            if (businessModel.length) {
                setCurrentBusinessModel(
                    businessModel.map(item => ({
                        label: item?.name,
                        value: item?._id,
                        description: item?.description,
                    }))
                );
            }
        }
    }, [isLoading, isLoadingBusinessModel, formik?.values, startup_business_model, formik?.values?.business_model_id ]);
    const handleSearchSubCategoryChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchSubCategory(value);
        // refetchCities();
    };
    const handleSelectionSubCategoryChange = (event, sector) => {
        // console.log(value, "value")
        formik.setFieldValue("sector_id", sector ? sector?.value : '');
        setCurrentSubCategory(sector)
        event.preventDefault();
    };
    const handleSearchUpdateSubCategory = () => {
        refetchSubCategories();
    }
    const handleSearchBusinessModelChange = (event, value) => {
        setSearchBusinessModel(value);
      };
      
      const handleSelectionBusinessModelChange = (event, selectedModels) => {
        formik.setFieldValue(
          "business_model_id",
          selectedModels ? selectedModels.map(item => item.value) : []
        );
        setCurrentBusinessModel(selectedModels || []); // Ensure it is always an array
        event.preventDefault();
      };
      
      const handleSearchUpdateBusinessModel = () => {
        refetchBusinessModel();
      };
      
      // Ensure currentBusinessModel is always an array
      const availableOptions = startup_business_model?.filter(
        item =>
          !(currentBusinessModel || []).some(selected => selected.value === item._id)
      ) || [];
      
    const [currentRoundType, setCurrentRoundType] = React.useState('');
    const [searchRoundType, setSearchRoundType] = React.useState('');
    const handleSearchRoundTypeChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchRoundType(value);
        // refetchCities();
    };
    const handleSelectionRoundTypeChange = (event, type) => {
        // console.log(value, "value")
        formik.setFieldValue("round_type", type ? type?.value : '');
        setCurrentRoundType(type)
        event.preventDefault();
    };
    const handleSearchUpdateRoundType = () => {
        refetchRoundTypes();
    }
    const scrollbarRef = React.useRef(null);

    const scrollTo = (fieldName) => {
        setTimeout(() => {
            if (scrollbarRef.current) {
                const targetRef = document.getElementsByName(fieldName)[0];

                if (!targetRef) {
                    console.error(`Element with name "${fieldName}" not found`);
                    return;
                }

                // Scroll the target element into view within the custom scrollbar
                targetRef.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }, 100);
    };

    // const scrollToFirstVisibleValidation = () => {
    //     // Query all elements with the class "validation-message"
    //     const validationMessages = document.querySelectorAll('.validation-message');
    //     // console.log(validationMessages, "dddd")

    //     // Find the first visible validation message
    //     for (const message of validationMessages) {
    //         if (message && message.offsetParent !== null) {
    //             message.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //             break;
    //         }
    //     }
    // };

    // const [isPressed, setIsPressed] = useState(true)

    const { data: currencyList, isLoading: isLoadingCurrency } =
        FetchAllCurrencyLists();

    React.useEffect(() => {
        if (!isLoadingCurrency && formik.values) {
            formik.setFieldValue("currency_id", formik.values?.currency_id ? formik.values?.currency_id : currencyList[0]?._id)
            formik.setFieldValue("currency", currencyList?.find(item => formik.values?.currency_id === item?._id)?.code || currencyList[0]?.code);
        }
    }, [isLoadingCurrency, formik.values])

    React.useEffect(() => {
        formik.setFieldValue("raised_round", startupDetailsData?.data?.raised_round)
    }, [isStartupDetailsLoading])

    return (
        <>
            <Modal open={isLoadingStages}>
                <Wrapper className="skeleton" style={{
                    display: 'grid', height: '100vh',
                    width: "100vw",
                    placeItems: 'center'
                }}>
                    {/* <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} /> */}
                </Wrapper>
            </Modal>
            {/* <AuthWrapper1>
                <Grid xs={12} container display={"flex"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }} sx={{
                    maxWidth: '1440px !important',
                    marginInline: 'auto',
                    height: '100%'
                }}>
                    <Grid item xs={12} sm={12} md={6.6} display={"grid"} sx={{ padding: { xs: "1rem 1rem 1rem 1rem", sm: "1rem 2rem", md: "2rem 2rem 1rem 2rem", lg: "2rem 4rem 2rem 5rem" }, height: '100%' }} gap={"1rem"}> */}
            <FormikProvider value={formik}>
                <Form onSubmit={(e) => e.preventDefault()} onKeyDown={(e) => {

                    // if (currentStep == 7 || currentStep == 8) {
                    //     if (e.key === 'Enter' && !e.shiftKey) {
                    //         formik.handleSubmit();
                    //     }
                    // }
                    // else {
                    //     return;
                    // }
                }} style={{ height: '100%', display: 'grid', alignContent: 'space-between' }}>
                    <FocusError formik={formik} />
                    <Grid xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"column"} sx={{ maxHeight: "80vh", overflow: "hidden" }}>
                        <CustomHeaderIcon customIcon={startupInformationIcon} />
                        <Typography variant='title' marginTop={1} sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" }, position: 'relative' }} textAlign={{ xs: "center", md: "start" }} >{currentStepPitchDeck === 4 ? "Startup Information" : currentStepPitchDeck === 6 ? "Round Details" : 'Select Category of Your Startup'}</Typography>
                        {currentStepPitchDeck === 4 && <>
                            <Typography marginBottom={2} color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginTop={1}>Provide the following information for your startup</Typography>
                        </>}
                        {currentStepPitchDeck === 5 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginTop={1} marginBottom={2}>Provide sector information for your startup</Typography>}
                        {currentStepPitchDeck === 6 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginTop={1} marginBottom={2}>Provide the following information for your current round</Typography>}
                        {/* {console.log(currentStepPitchDeck ,"currentStepPitchDeck")} */}
                        <Box
                            //  containerRef={containerRef}
                            ref={scrollbarRef}
                            style={{
                                minHeight: "35vh", height: '100%',
                                paddingRight: isDesktop ? "0.5rem" : 0,
                                //  maxHeight: 'calc(100vh - 205px)',
                                overflow: 'hidden auto',
                                //  marginTop: (currentStep === 5 || currentStep === 6 ? 0 : "1.5rem"), paddingBottom:  currentStep === 5 || currentStep === 6 ? 0 : '1.5rem'
                            }} className="scrollbar-container" id='onboarding-scroller'>
                            {currentStepPitchDeck === 4 &&
                                <>
                                    <Grid item xs={12} sm={12} height={"min-content"}>
                                        <CustomInputField
                                            name="startup_name"
                                            label="BUSINESS NAME"
                                            pattern="^[^@$()]*$"  // This regex pattern excludes @, $, (, and )
                                            placeholder="Enter business name"
                                            value={formik.values?.startup_name}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value.length <= 30 && /^[^@$()]*$/.test(value)) {
                                                    formik.setFieldValue(
                                                        "startup_name",
                                                        value.charAt(0).toUpperCase() + value.slice(1)
                                                    );
                                                }
                                            }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <>
                                        <Grid item container xs={12} gap={1} display={"flex"} paddingBottom={4}>
                                            {/* <Grid xs={12}>
                                                    <CustomLabel>WHAT IS THE STAGE OF YOUR STARTUP? {formik.errors.stage_id && (
                                                        <span style={{
                                                            color: "rgb(239, 83, 80)", fontWeight: "400",
                                                            fontSize: '0.75rem',
                                                            lineHeight: "1.66",
                                                            letterSpacing: "0.03333em",
                                                            textAlign: "left",
                                                            marginTop: "3px",
                                                            marginRight: "14px",
                                                            marginBottom: "0",
                                                            marginLeft: "14px"
                                                        }}>
                                                            ({formik.errors.stage_id})
                                                        </span>
                                                    )}</CustomLabel>
                                                </Grid> */}
                                            <Grid item xs={12}>
                                                <CustomLabel sx={{ marginBlock: "0rem" }}>WHAT IS THE STAGE OF YOUR STARTUP? </CustomLabel>
                                            </Grid>

                                            {!isLoadingStages && !isRefetchingStages ? startup_stages?.map((stage, index) => <Grid key={index} onClick={() => formik.setFieldValue("stage_id", stage?._id)} item xs={12} sm={5.8} md={5.6} lg={5.75} flex={1}>
                                                <button key={index} onClick={(e) => e.preventDefault()} style={{
                                                    minHeight: "7rem",
                                                    height: "100%",
                                                    maxHeight: '7rem',
                                                    width: "100%",
                                                    // padding: "0.5rem 0rem 6rem 0.5rem",
                                                    padding: "1rem 0rem 0rem 0.5rem",
                                                    background: formik.values.stage_id === stage?._id ? "#FFFAE6" : "#FFF",
                                                    fontSize: "0.875rem",
                                                    cursor: 'pointer',
                                                    border: `1px solid ${formik.values.stage_id === stage?._id ? "#FFC900" : "#A1A3A7"}`,
                                                    borderRadius: "8px",
                                                    overflow: "hidden",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    position: 'relative'
                                                }}>
                                                    {/* width: "15.625rem" */}
                                                    <span style={{ position: 'absolute', fontWeight: 600, top: "5px" }}>
                                                        {/* {stage?.name} */} {index < 2 ? stage?.name : index === 2 ? "Early Stage" : index === 3 ? "Growth" : 'Expansion'}
                                                    </span>
                                                    {<Typography sx={{ position: 'absolute', top: "2rem", fontSize: { xs: 0, md: '0.875rem' } }}>
                                                        {index < 2 ? (index === 0 ? "Ideation stage" : "Product development") : index === 2 ? "Product market fit" : index === 3 ? "Scaling up" : 'IPO readiness'}
                                                    </Typography>}
                                                    <img rel='preload' src={stage?.image_url} style={{ ...stage?.size, marginLeft: 'auto' }} />
                                                </button>
                                            </Grid>
                                            ) : <Grid item xs={12}><Skeleton height={260} sx={{ transform: "scale(1)" }} width={"100%"} /> </Grid>}

                                            <Grid item xs={12} lg={12} height={"min-content"} pb={1}>
                                                {formik.errors.stage_id && (
                                                    <CustomValidationText value={formik.errors.stage_id} style={{ textTransform: 'none', marginTop: 0 }} />
                                                )}
                                                <CustomLabel sx={{ margin: "8px" }}>
                                                    HAVE YOU RAISED FUNDS EARLIER?
                                                </CustomLabel>
                                                <RadioGroup
                                                    sx={{ flexDirection: "row", paddingBottom: 2 }}
                                                    name="raised_round"
                                                    value={formik.values.raised_round}
                                                    defaultValue={formik.values.raised_round}
                                                    onChange={(e) =>
                                                        formik.setFieldValue(
                                                            `raised_round`,
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <FormControlLabel
                                                        value={true}
                                                        control={<CustomRadio disableRipple />}
                                                        label={"Yes"}
                                                    />
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<CustomRadio disableRipple />}
                                                        label={"No"}
                                                    />
                                                    {formik.errors.raised_round && formik.touched.raised_round && (
                                                        <CustomValidationText value={formik.errors.raised_round} />
                                                    )}
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </>
                                </>
                            }
                            {currentStepPitchDeck === 5 &&
                                <>

                                    <Grid container xs={12} gap={0} paddingBottom={0} paddingRight={1}>
                                        {/* <Grid xs={12} item container style={{ display: 'flex', alignItems: 'flex-start' }}> */}
                                        <Grid item xs={12}>
                                            <CustomLabel sx={{ marginTop: "0rem" }}>Select Category</CustomLabel>
                                        </Grid>
                                        {/* <Grid item container xs={12} display={"flex"} paddingBottom={{ xs: 1 }} sx={{ justifyContent: 'space-between'}}> */}
                                        <FieldArray
                                            name="category_id"
                                            render={arrayHelpers => (
                                                <>
                                                    <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap', padding: 0, paddingBottom: 1, marginTop: 0, justifyContent: 'space-between' }} gap={2}>
                                                        {Array.isArray(startup_categories) && startup_categories?.map((category, index) => {
                                                            const isSelected = formik?.values?.category_id?.includes(category?._id);
                                                            return (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={5.8}
                                                                    md={5.75}
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        gap: 0,
                                                                        background: isSelected ? "#FFFAE6" : "#FFF",
                                                                        fontSize: "0.875rem",
                                                                        border: `1px solid ${isSelected ? "#FFC900" : "#A1A3A7"}`,
                                                                        borderRadius: "8px",
                                                                        paddingRight: "1rem",
                                                                        paddingLeft: "1rem",
                                                                        paddingBlock: '1.5rem',
                                                                        height: "2.5rem",
                                                                        cursor: "pointer",
                                                                        textTransform: 'none',
                                                                        color: 'inherit',
                                                                        // opacity: formik?.values?.category_id?.length >= 1 && !isSelected ? 0.5 : 1, // Dims unselected options when max is reached
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        formik.setFieldValue("category_id", category?._id)
                                                                        setSelectedCategory(category?.name);
                                                                        formik.setFieldValue("sector_id", '');
                                                                        setCurrentSubCategory('')
                                                                        // if (isSelected) {
                                                                        // arrayHelpers.remove(formik.values?.category_id.indexOf(category?._id));
                                                                        // } else if (formik.values?.category_id?.length < 1) {
                                                                        // arrayHelpers.push(category?._id);
                                                                        // }
                                                                    }}
                                                                    key={category?._id}
                                                                >
                                                                    {/* <img
                                                                            src={sector?.image_url}
                                                                            rel='preload'
                                                                            style={{
                                                                                minHeight: "1rem",
                                                                                maxHeight: "1rem",
                                                                                height: "100%",
                                                                            }}
                                                                            loading='lazy'
                                                                        /> */}
                                                                    {/* <i style={{ ...sector?.style }}>

                                                                                    </i> */}

                                                                    {index === 0 && <MemoryOutlined sx={{ fontSize: '1.6rem', marginRight: '0.5rem' }} />}
                                                                    {index === 1 && <StorefrontOutlined sx={{ fontSize: '1.4rem', marginRight: '0.5rem' }} />}
                                                                    {index === 2 && <LocalMallOutlined sx={{ fontSize: '1.4rem', marginRight: '0.5rem' }} />}
                                                                    {index === 3 && <MiscellaneousServicesOutlined sx={{ fontSize: '1.4rem', marginRight: '0.5rem' }} />}

                                                                    <span> {category?.name} </span>
                                                                    {/* <span> {index == 0 ? "Technology  (Product / Software)" : index == 1 ? 'Consumer Brand' : index === 2 ? "Marketplace" : 'Services'} </span> */}
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                    {formik.errors.category_id && <CustomValidationText value={formik.errors.category_id} />}
                                                </>
                                            )}
                                        />
                                        {/* </Grid> */}
                                        {/* </Grid> */}
                                        <Grid item xs={12} mt={1.5}>
                                            {<CustomAutoComplete
                                                options={!isLoadingSubCategories && !isRefetchingSubCategories && Array.isArray(startup_sub_categories) ? startup_sub_categories?.map(item => ({ label: item?.sub_category, value: item?._id, description: item?.description })) : []}
                                                label="Select Sector"
                                                name={"sector_id"}
                                                noOptionsText={!isLoadingSubCategories && !isRefetchingSubCategories ? "No Options" : "Searching..."}
                                                placeholder="Type a sector name"
                                                icon={null}
                                                handleSearchChange={handleSearchSubCategoryChange}
                                                handleSelectionChange={handleSelectionSubCategoryChange}
                                                search={searchSubCategory}
                                                renderOption={(props, option) => (
                                                    <li {...props} style={{ display: 'grid' }}>
                                                        <span style={{ fontWeight: 600 }}>{option.label}</span>
                                                        <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                                                        {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                                    </li>
                                                )}
                                                value={currentSubCategory}
                                                defaultValue={startup_sub_categories?.find(item => item?._id == formik?.values?.sector_id)}
                                                loading={isRefetchingSubCategories}
                                                handleSearchUpdate={handleSearchUpdateSubCategory}
                                            />}
                                        </Grid>
                                        <Grid item xs={12}>
                                    <CustomAutoComplete
                                        options={
                                        !isLoadingBusinessModel &&
                                        !isRefetchingBusinessModel &&
                                        Array.isArray(availableOptions)
                                            ? availableOptions.map(item => ({
                                                label: item.name,
                                                value: item._id,
                                                description: item.description,
                                            }))
                                            : []
                                        }
                                        label="SELECT BUSINESS MODEL"
                                        name={"business_model_id"}
                                        multiple={true}
                                        noOptionsText={
                                        !isLoadingBusinessModel && !isRefetchingBusinessModel
                                            ? "No Options"
                                            : "Searching..."
                                        }
                                        placeholder="Business model"
                                        icon={null}
                                        handleSearchChange={handleSearchBusinessModelChange}
                                        handleSelectionChange={handleSelectionBusinessModelChange}
                                        search={searchBusinessModel}
                                        renderOption={(props, option) => (
                                        <li {...props} style={{ display: 'grid' }}>
                                            <span style={{ fontWeight: 600 }}>{option.label}</span>
                                            <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                                        </li>
                                        )}
                                        value={currentBusinessModel || []}
                                        defaultValue={startup_business_model?.filter(item =>
                                        formik?.values?.business_model_id?.includes(item._id)
                                        )}
                                        loading={isRefetchingBusinessModel}
                                        handleSearchUpdate={handleSearchUpdateBusinessModel}
                                    />
                                    </Grid>

                                    </Grid>
                                </>
                            }
                            {currentStepPitchDeck === 6 &&
                                <>
                                    <Grid container xs={12} gap={1} paddingBottom={4} paddingRight={1}>
                                        {/* <Grid xs={12} item container style={{ display: 'flex', alignItems: 'flex-start' }}> */}
                                        {/* <CustomLabel>UPLOAD PITCH DECK</CustomLabel> */}
                                        <Grid item xs={12} sm={12}>
                                            <CustomLabel>UPLOAD PITCH DECK</CustomLabel>
                                            <Grid
                                                item
                                                xs={12}
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                                sx={{
                                                    background:
                                                        theme?.palette.customColors
                                                            ?.whiteBG,
                                                    padding: "0.8rem 1rem",
                                                    borderRadius: "8px",
                                                    border: `1px solid ${theme.palette.customColors.g75}`,
                                                }}
                                                height={"min-content"}
                                            >
                                                <Grid display={"flex"} alignItems={"center"} gap={1} onClick={() => { window.open(formik.values?.pitch_deck) }}>
                                                    <Box
                                                        width={isSmallScreen ? 35 : 40}
                                                        height={isSmallScreen ? 35 : 40}
                                                        bgcolor={theme?.palette?.customColors?.indigo100}
                                                        borderRadius={"50%"}
                                                        display={"flex"}
                                                        alignItems="center"
                                                        justifyContent={"center"}
                                                    >
                                                        <img
                                                            width={20} height={20}
                                                            src={getFileIcon("pitch_deck.pdf")}
                                                        />
                                                    </Box>
                                                    <span>
                                                        {formik.values?.pitch_deck?.name ? formik.values?.pitch_deck?.name : formik.values?.pitch_deck != null ? "pitch_deck.pdf" : "Pitch Deck PDF Only (Max: 50 MB)"}
                                                        {/* ? formik.values.pitch_deck.name ?? formik.values.pitch_deck.filename
                                                                        : "Pitch Deck PDF Only (Max: 5 MB)"
                                                                    } */}
                                                    </span>
                                                </Grid>
                                                <Grid
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "1rem",

                                                    }}
                                                >
                                                    {(formik.values?.pitch_deck?.name || formik.values?.pitch_deck?.filename) && (
                                                        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

                                                            {/* <img style={{ position: 'absolute', top: 0, right: 0, height: 16, width: 16, cursor: 'pointer' }} src={ClearIcon} /> */}
                                                        </Box>
                                                    )}
                                                    {/* {console.log(document?._id, "document?._id")} */}
                                                    {<WHITEBG
                                                        component="label"
                                                        role={undefined}
                                                        tabIndex={-1}
                                                        sx={{
                                                            minWidth: "0 !important",
                                                            border: `1px solid #D0D5DD !important`,
                                                            padding: '0.7rem'
                                                        }}
                                                    >
                                                        <UploadDocumentsIcon
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                                stroke: "#344054"
                                                            }}
                                                            onClick={() => {

                                                            }
                                                            }
                                                        />
                                                        <VisuallyHiddenInput
                                                            accept=".pdf"
                                                            type="file"
                                                            name={`pitch_deck`}
                                                            id={`pitch_deck`}
                                                            onChange={(e) => {
                                                                const file = e.target.files[0];
                                                                formik.setFieldValue(`pitch_deck`, file)
                                                            }
                                                            }
                                                        />
                                                    </WHITEBG>}
                                                </Grid>
                                            </Grid>
                                            {/* <CustomUploadButton name={"pitch_deck"} label={"UPLOAD PITCH DECK"} handleUpdateFile={(file) => { formik.setFieldValue("pitch_deck", file) }} selectedFile={formik.values.pitch_deck} accept='.pdf' typeSpecifyText='PDF Only (Max: 5 MB)' customUploadIcon={getFileIcon("pdf")} /> */}
                                            {formik.errors.pitch_deck && <CustomValidationText value={formik.errors.pitch_deck} />}
                                        </Grid>
                                        {/* </Grid> */}
                                        <Grid item xs={12} sm={12} marginTop={0.5}>
                                            <CustomInputField
                                                name="round_name"
                                                label="ROUND NAME"
                                                pattern="^[^@$()]*$"  // This regex pattern excludes @, $, (, and )
                                                placeholder="Enter round name"
                                                value={formik.values?.round_name}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (value.length <= 30 && /^[^@$()]*$/.test(value)) {
                                                        formik.setFieldValue(
                                                            "round_name",
                                                            value.charAt(0).toUpperCase() + value.slice(1)
                                                        );
                                                    }
                                                }}
                                                sx={{ marginBottom: 0 }}
                                                fullWidth
                                            /></Grid>
                                        <Grid item xs={12} mt={0.5}>
                                            {<CustomAutoComplete
                                                options={!isLoadingRoundTypes && !isRefetchingRoundTypes && Array.isArray(round_types) ? round_types?.map(item => ({ label: item?.name, value: item?._id })) : []}
                                                label="Round Type"
                                                name={"round_type"}
                                                noOptionsText={!isLoadingRoundTypes && !isRefetchingRoundTypes ? "No Options" : "Loading..."}
                                                placeholder="Search a round type"
                                                icon={null}
                                                highlight
                                                handleSearchChange={handleSearchRoundTypeChange}
                                                handleSelectionChange={handleSelectionRoundTypeChange}
                                                search={searchRoundType}
                                                value={currentRoundType}
                                                defaultValue={round_types?.find(item => item?._id == formik?.values?.round_type)}
                                                loading={isRefetchingRoundTypes}
                                                handleSearchUpdate={handleSearchUpdateRoundType}
                                                InputStyles={{
                                                    marginBottom: 0
                                                }}
                                            />
                                            }
                                            {/*  <>
                                                            <Box className="skeleton" sx={{ height: 17.6, width: '100%', marginBottom: 1 }}></Box>
                                                            <Box className="skeleton" sx={{ height: 50.6, width: '100%', marginBottom: 2 }}></Box>
                                                        </> */}
                                        </Grid>
                                        <Grid item xs={12} mt={0.5} height={"min-content"}>
                                            <CustomInputField
                                                // inputProps={{ ...currencyInputProps }}
                                                InputProps={{
                                                    ...currencyInputProps,
                                                    startAdornment: (
                                                        <Box sx={{ padding: "0 0 0 1rem" }}>
                                                            {formik.values.currency}
                                                        </Box>
                                                    ),
                                                    endAdornment: (
                                                        !isLoadingCurrency && currencyList && <Select
                                                            className="selecttt"
                                                            sx={{
                                                                "::before": { border: "none !important" },
                                                                border: "none !important",
                                                            }}
                                                            value={formik.values?.currency_id}
                                                            onChange={(e) => {
                                                                // console.log(currencyList?.find(item => item?._id === e.target.value),"edefe")
                                                                formik.setFieldValue(
                                                                    "currency",
                                                                    currencyList?.find(item => item?._id === e.target.value)?.code
                                                                );
                                                                formik.setFieldValue(
                                                                    "currency_id",
                                                                    e.target.value
                                                                )
                                                            }
                                                            }
                                                        >
                                                            {/* <MenuItem value={"$"}>USD</MenuItem>
                                  {<MenuItem value={"₹"}>INR</MenuItem>} */}
                                                            {currencyList?.map((item) => (
                                                                <MenuItem type="button" value={item?._id}>
                                                                    {item?.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    ),
                                                }}
                                                name={`total_round_size`}
                                                label={"TOTAL ROUND SIZE"}
                                                placeholder={"Enter Total round size"}
                                                value={formik.values?.total_round_size}
                                                onChange={(e) =>
                                                    formik.setFieldValue(
                                                        `total_round_size`,
                                                        e.target.value
                                                    )
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }

                        </Box>
                    </Grid>


                    <Grid item container maxHeight={"5.5vh"} xs={12} display={"flex"} alignItems={"flex-end"} justifyContent={"center"} gap={3}>
                        <Grid xs={5} md={5.5} lg={5.65} item>

                            <BLUEBORDER sx={{ textTransform: "none", padding: 2, borderWidth: 1, borderRadius: "8px", height: '56.5px' }} onClick={(e) => {
                                e.preventDefault();
                                if (currentStepPitchDeck === 4) {
                                    navigate("/thank-you")
                                } else {
                                    setCurrentStepPitchDeck(currentStepPitchDeck - 1);
                                    Cookies.set("step", currentStep - 1)
                                    // setSearchParams({ page: currentStepPitchDeck - 1 });

                                }
                            }} fullWidth>Back</BLUEBORDER>
                        </Grid>
                        <Grid xs={5} md={5.5} lg={5.65} item>
                            <CustomButton sx={{ padding: 2, borderRadius: "8px", height: '56.5px' }} fullWidth onClick={(e) => {
                                handleFormSubmit(e,formik)
                            }}>{isUpdatingStartup || isUploadingPitchDeck ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} /> : currentStepPitchDeck === 6 ? "Submit" : "Next"}</CustomButton>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>

            {/* </Grid>
                    <CustomBackgroundComponent bgImage={bgImage} />
                </Grid>
            </AuthWrapper1> */}
        </>
    )
}

export default UploadPitchDeck