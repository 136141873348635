import { Box } from '@mui/material'
import React from 'react'
import theme from '../themes/theme'

const CustomLabel = ({children, sx ={},...props}) => {
    return (
        <Box component="label" className='custom-label' sx={{ color: theme.palette.customColors.g500, marginBottom: '8px', display: 'block', fontSize: "0.875rem", textTransform: 'uppercase',...sx}} {...props}>
            {children}
        </Box>
    )
}

export default CustomLabel