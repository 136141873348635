import { Box, FormControlLabel, Grid, MenuItem, RadioGroup, Select, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import { AddStartup, FetchAllCategories, FetchAllCurrencyLists, FetchAllRoundTypes, FetchAllSectors, FetchAllStages, FetchAllSubCategories } from 'api'
import CustomLabel from 'ui-components/CustomLabel'
import { useTheme } from '@emotion/react'
import CustomValidationText from 'ui-components/CustomValidationText'
import { LocalMallOutlined, MemoryOutlined, MiscellaneousServicesOutlined, StorefrontOutlined } from '@mui/icons-material'
import CustomAutoComplete from 'ui-components/CustomAutoComplete'
import { ReactComponent as UploadDocumentsIcon } from "assets/rounds_icons/upload_documents_icon.svg";
import { B300 as CustomButton, BLUEBORDER, WHITEBG } from 'ui-components/CustomButton'
import { containerRef, currencyInputProps, getFileCategory, getFileIcon } from 'utilities'
import { styled } from '@mui/styles'
import CustomInputField from 'ui-components/formik/CustomInputField'
import CustomRichTextEditor from 'ui-components/formik/CustomRichTextEditor'
import { VisuallyHiddenInput } from 'ui-components/VisuallyHiddenInput'

// const Visually = styled("input")({
//     clip: "rect(0 0 0 0)",
//     clipPath: "inset(50%)",
//     height: 1,
//     overflow: "hidden",
//     position: "absolute",
//     bottom: 0,
//     left: 0,
//     whiteSpace: "nowrap",
//     width: 1,
// });

const StartupDetails = () => {
    const formik = useFormikContext()
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <Grid container xs={12} gap={0} paddingBottom={"1rem"}>
            <Grid item xs={12} sm={12}>
                <CustomLabel>HAVE VIDEO PITCH? <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span> </CustomLabel>
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        background:
                            theme?.palette.customColors
                                ?.whiteBG,
                        padding: "0.8rem 1rem",
                        borderRadius: "8px",
                        border: `1px solid ${theme.palette.customColors.g75}`,
                    }}
                    height={"min-content"}
                >
                    <Grid display={"flex"} alignItems={"center"} gap={1} onClick={() => { formik.values?.elevator_pitch != null && formik.values?.elevator_pitch && formik.values?.elevator_pitch != "" && window.open(formik.values?.elevator_pitch) }}>
                        <Box
                            width={isSmallScreen ? 35 : 40}
                            height={isSmallScreen ? 35 : 40}
                            bgcolor={theme?.palette?.customColors?.indigo100}
                            borderRadius={"50%"}
                            display={"flex"}
                            alignItems="center"
                            justifyContent={"center"}
                        >
                            <img
                                width={20} height={20}
                                src={getFileIcon("elevator_pitch.mp4")}
                            />
                        </Box>
                        <span>
                            {formik.values?.elevator_pitch?.name ? formik.values?.elevator_pitch?.name : formik.values?.elevator_pitch != null ? "video_pitch.mp4" : "Elevator Pitch MP4, M4V Only (Max: 100 MB)"}
                        </span>
                    </Grid>
                    <Grid
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",

                        }}
                    >
                        {(formik.values?.elevator_pitch?.name || formik.values?.elevator_pitch?.filename) && (
                            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>

                                {/* <img style={{ position: 'absolute', top: 0, right: 0, height: 16, width: 16, cursor: 'pointer' }} src={ClearIcon} /> */}
                            </Box>
                        )}
                        {/* {console.log(document?._id, "document?._id")} */}
                        {<WHITEBG
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            sx={{
                                minWidth: "0 !important",
                                border: `1px solid #D0D5DD !important`,
                                padding: '0.7rem'
                            }}
                        >
                            <UploadDocumentsIcon
                                style={{
                                    width: 20,
                                    height: 20,
                                    stroke: "#344054"
                                }}
                                onClick={() => {

                                }
                                }
                            />
                            <VisuallyHiddenInput
                                accept='video/mp4,video/x-m4v,video/'
                                type="file"
                                name={`elevator_pitch`}
                                id={`elevator_pitch`}
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    formik.setFieldValue(`elevator_pitch`, file)
                                }
                                }
                            />
                        </WHITEBG>}
                    </Grid>
                </Grid>
                {formik.errors?.elevator_pitch && <span className='validation-message' style={{
                    color: "rgb(239, 83, 80)",
                    fontWeight: "400",
                    fontSize: "0.75rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.03333em",
                    textAlign: "left",
                    marginTop: "3px",
                    marginRight: "14px",
                    marginBottom: "0",
                    marginLeft: "14px",
                }}>{formik.errors?.elevator_pitch}</span>}
            </Grid>
            <Grid item xs={12} sm={12}>
                <CustomInputField labelStyle={{ marginTop: '1rem' }} value={formik.values.problem_solving} name="problem_solving" onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 160) {
                        formik.setFieldValue("problem_solving", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))
                    }
                    else {
                        formik.setFieldValue("problem_solving", String(e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))?.substring(0, 160))
                    }
                }} label={"WHAT PROBLEM ARE YOU SOLVING?"} placeholder="Enter a description..." multiline minRows={4} maxRows={4} maxLength={160} fullWidth data-problem-solving={160 - formik.values?.problem_solving?.length + `${(160 - formik.values?.problem_solving?.length) > 1 ? " characters" : " character"} left`}
                    sx={{
                        "::after": {
                            content: 'attr(data-problem-solving)',
                            position: "absolute",
                            bottom: formik.errors.problem_solving ? 30 : 5,
                            right: 5,
                            background: 'white',
                            color: formik.values?.problem_solving?.length === 200 ? "red" : 'graytext',
                            padding: "0 5px",
                            fontSize: "0.75rem"
                        },
                    }} />
            </Grid>
            <Grid item xs={12} sm={12} mt={0} height={"min-content"}>
                <CustomRichTextEditor
                    name="description"
                    label={
                        <>
                            STARTUP OVERVIEW
                            {/* <img src="" alt="" /> */}
                        </>
                    }
                    placeholder={"Enter startup overview"}
                    value={formik.values?.description}
                    handleChange={(newValue) =>
                        formik.setFieldValue(
                            "description",
                            newValue
                        )
                    }
                    maxLength={2000}
                />
                {formik.errors.description && formik.touched.description && (
                    <CustomValidationText value={formik.errors.description} />
                )}
            </Grid>
        </Grid>
    )
}

export default StartupDetails
