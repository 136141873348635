import { Box, Grid,useMediaQuery } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'
import CustomPhone from 'ui-components/CustomPhone'
import CustomInputField from 'ui-components/formik/CustomInputField'
import { AddStartup, FetchAllCities, FetchAllCountries, FetchAllCountriesWithPhoneCodes, FetchAllPopularCities, FetchAllPopularCountries, FetchAllStages, FetchAllStates } from '../../../api'
import CustomAutoComplete from 'ui-components/CustomAutoComplete'
import CustomLabel from 'ui-components/CustomLabel'
import { ReactComponent as gpsIcon } from "../../../assets/gps.svg"
import { useTheme } from '@emotion/react'


const SelectStateCity = ({currentCountry,setCurrentCountry}) => {
    const formik = useFormikContext()
    const theme = useTheme();
    const [searchCountry, setSearchCountry] = React.useState("")
    const [search, setSearch] = React.useState("")
    const [searchState, setSearchState] = React.useState("")
    const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: searchCountry })
    // const [currentCountry, setCurrentCountry] = React.useState(countries?.find(item => item?._id == formik?.values?.country_id))
    const { data: states, isRefetching: isRefetchingStates, isLoading: isLoadingStates, refetch: refetchStates } = FetchAllStates({ search: searchState, countryID: currentCountry !== null ? countries?.find(item => item?._id == formik.values?.country_id)?.value : currentCountry?.value })
    const [currentState, setCurrentState] = React.useState(states?.find(item => item?.value === formik?.values?.state_id)?.label || '')
    const { data: cities, isRefetching: isRefetchingCities, isLoading: isLoadingCities, refetch: refetchCities } = FetchAllCities({ search, stateID: currentState !== null ? states?.find(item => item?._id == formik.values?.state_id)?.value : currentState?.value, isPopular: 0 });
    const [currentCity, setCurrentCity] = React.useState(cities?.find(item => item?.value === formik?.values?.city_id)?.label || '');
    const { data: popular_countries, isRefetching: isRefetchingPopularCountries, isLoading: isLoadingPopularCountries, refetch: refetchPopularCountries } = FetchAllPopularCountries()

    const handleSearchStateChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchState(value);
        // refetchCities();
    };
    const handleSearchUpdateState = () => {
        refetchStates();
    }
    const handleSearchCityChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearch(value);
        // refetchCities();
    };
    const handleSelectionCityChange = (event, value) => {
        // console.log(value, "value")

        formik.setFieldValue("city_id", value ? value?._id : '');
        setCurrentCity(value)
        event.preventDefault();
    };
    const handleSearchUpdateCity = () => {
        refetchCities();
    }
    const handleSelectionStateChange = (event, value) => {
        // console.log(value, "value")
        formik.setFieldValue("state_id", value ? value?._id : null);
        setCurrentState(value)
        setCurrentCity(null);
        formik.setFieldValue("city_id", null)
        setSearch("")
        refetchData('city')
        event.preventDefault();
    };

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesUpXs = useMediaQuery(theme.breakpoints.up('xs'));
    const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
    const dimensions = matchUpLg ? { width: "5rem", height: "5rem", marginTop: 'auto' } : matchesUpMd ? { width: "4.5rem", height: "4.5rem", marginTop: 'auto' } : matchesUpMd ? { width: "6rem", height: "6rem", marginTop: 'auto' } : { width: "4rem", height: "4rem", marginTop: 'auto' }
    const refetchData = (type) => {
        if (type === "city") {
            refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 0)
            })
        }
        else {
            formik.values?.country_id != undefined && refetchStates().then(res => {
                setCurrentState(res?.data?.filter(item => item?._id == formik.values.state_id)[0]?.label || '');
                setSearchState("");
                // setSearchState(res?.data?.find(item => item?.value == formik.values.state_id)?.label || '');
            })

            formik.values?.state_id != undefined && refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response?.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 200)
            })

        }
        // refetchPopularCities()
    }

  return (
    <>
      {!isLoadingStates && !isRefetchingStates ? <CustomAutoComplete
        options={!isLoadingStates && !isRefetchingStates && states?.length > 0 ? states : []}
        label={`SEARCH STATE IN ${String(currentCountry?.label || countries?.find(item => item?._id === formik.values.country_id)?.label)?.toUpperCase()}`}
        placeholder="Type a state name"
        name="state_id"
        noOptionsText={!isLoadingStates && !isRefetchingStates ? "No Options" : "Searching..."}
        icon={gpsIcon}
        handleSearchChange={handleSearchStateChange}
        handleSelectionChange={handleSelectionStateChange}
        search={searchState}
        value={currentState?.label}
        defaultValue={states?.find(item => item?._id === formik?.values?.state_id)?.label}
        loading={false}
        handleSearchUpdate={handleSearchUpdateState}
    /> : <>
        <Box className="skeleton" sx={{ height: 17.6, width: '100%', marginBottom: 1 }}></Box>
        <Box className="skeleton" sx={{ height: 50.6, width: '100%', marginBottom: 2 }}></Box>
    </>}
    {!isLoadingCities && !isRefetchingCities && (currentState !== null ? states?.find(item => item?._id == formik.values?.state_id)?.value : currentState?.value) && <CustomAutoComplete
        options={!isLoadingCities && !isRefetchingCities && cities?.length > 0 ? cities : []}
        noOptionsText={!isLoadingCities && !isRefetchingCities ? "No Options" : "Searching..."}
        label={`SEARCH CITY IN ${String(currentState?.label || states?.find(item => item?._id === formik.values.state_id)?.label)?.toUpperCase()}`}
        placeholder="Type a city name"
        name="city_id"
        icon={gpsIcon}
        handleSearchChange={handleSearchCityChange}
        handleSelectionChange={handleSelectionCityChange}
        search={search}
        value={currentCity?.label}
        defaultValue={cities?.find(item => item?._id === formik?.values?.city_id)?.label}
        handleSearchUpdate={handleSearchUpdateCity}
    />}
    </>
  )
}

export default SelectStateCity
