// import React from 'react';
// import { Formik, Field, Form } from 'formik';
// import { Autocomplete } from '@mui/material';
// import TextField from '@mui/material/TextField';
// import Chip from '@mui/material/Chip';
// import CustomInputField from 'ui-components/CustomInputField';

// const EmailAutocomplete = ({ field, form, label }) => {
//     const validateEmail = (email) => {
//         const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         return regex.test(email);
//     };

//     const handleKeyDown = (event) => {
//         if ((event.key === 'Enter' || event.key === 'Tab') && validateEmail(event.target.value)) {
//             const newEmail = event.target.value;
//             if (!field.value.includes(newEmail)) {
//                 form.setFieldValue(field.name, [...field.value, newEmail]);
//             }
//             event.target.value = ''; // Clear input
//         }
//     };

//     const handleInputBlur = (event) => {
//         if (validateEmail(event.target.value)) {
//             const newEmail = event.target.value;
//             if (!field.value.includes(newEmail)) {
//                 form.setFieldValue(field.name, [...field.value, newEmail]);
//             }
//             event.target.value = ''; // Clear input
//         }
//     };

//     return (
//         <Autocomplete
//             multiple
//             options={field.value}
//             freeSolo
//             onChange={(event, newValue) => form.setFieldValue(field.name, newValue)}
//             renderTags={(value, getTagProps) =>
//                 value.map((option, index) => (
//                     <Chip label={option} {...getTagProps({ index })} key={option} />
//                 ))
//             }
//             onBlur={handleInputBlur}
//             renderInput={(params) => (
//                 <CustomInputField
//                     sx={{
//                         "& > .MuiOutlinedInput-root": {
//                             padding: "13px 15px",
//                             "& >.MuiAutocomplete-input": {
//                                 padding: 0
//                             },
//                             "& >.MuiAutocomplete-tag" : {
//                                 margin: 0
//                             }
//                         }
//                     }}
//                     {...params}
//                     // label="Email Addresses"
//                     label={label}
//                     variant="outlined"
//                     onKeyDown={handleKeyDown}
//                 />
//             )}
//             value={field.value}
//         />
//     );
// };

// export default EmailAutocomplete;
import React, { useState } from 'react';
import { Autocomplete } from '@mui/material';
import Chip from '@mui/material/Chip';
import CustomInputField from 'ui-components/CustomInputField';

const EmailAutocomplete = ({ field, form, label, placeholder = "Enter email", options = [], inputStyle = {} }) => {
    const [inputValue, setInputValue] = useState('');

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleKeyDown = (event) => {
        if ((event.key === 'Enter' || event.key === 'Tab') && validateEmail(inputValue)) {
            const newEmail = inputValue.trim();
            if (!field.value.some((item) => item.email === newEmail)) {
                form.setFieldValue(field.name, [...field.value, { email: newEmail }]);
            }
            setInputValue(''); // Clear input value after adding email
            event.preventDefault();
        }
    };

    const handleInputBlur = () => {
        if (validateEmail(inputValue) && !field.value.some((item) => item.email === inputValue.trim())) {
            form.setFieldValue(field.name, [...field.value, { email: inputValue.trim() }]);
        }
        setInputValue(''); // Clear input value after losing focus
    };

    return (
        <Autocomplete
            multiple
            options={options} // Use the provided options array
            freeSolo
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : `${option.name || option.email}`
            }
            filterOptions={(options, { inputValue }) => {
                const filter = inputValue.toLowerCase();
                return options.filter((option) => {
                    const label = `${option.name || option.email}`;
                    return label.toLowerCase().includes(filter);
                });
            }}
            onChange={(event, newValue) => {
                const validSelections = newValue
                    .map((option) => {
                        if (typeof option === 'string') {
                            return { email: option.trim() };
                        } else {
                            return {
                                name: option.name,
                                email: option.email.trim(),
                            };
                        }
                    })
                    .filter((item) => validateEmail(item.email))
                    // Remove duplicates by checking if an email already exists in the array
                    .reduce((acc, current) => {
                        if (!acc.some((item) => item.email === current.email)) {
                            acc.push(current);
                        }
                        return acc;
                    }, []);

                form.setFieldValue(field.name, validSelections);
            }}
            renderTags={(value, getTagProps) =>
                value.map((item, index) => (
                    <Chip
                        label={item.name || item.email} // Show the name if available, otherwise the email
                        sx={{ marginRight: 1 }}
                        {...getTagProps({ index })}
                        key={item.email}
                    />
                ))
            }
            renderOption={(props, option) => {
                const isSelected = field.value.some(
                    (selectedItem) => selectedItem.email === option.email
                );

                return (
                    <li
                        {...props}
                        style={{
                            backgroundColor: isSelected ? '#f0f8ff' : 'inherit', // Change color for selected options
                            fontWeight: isSelected ? 'normal' : 'normal',
                        }}
                    >
                        {option?.name ? `${option?.name} (${option.email})` : option.email}
                    </li>
                );
            }}
            inputValue={inputValue} // Control input value
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            onBlur={handleInputBlur}
            renderInput={(params) => (
                <CustomInputField
                    sx={{
                        "& > .MuiOutlinedInput-root": {
                            padding: "13px 15px",
                            gap: '0.2rem',
                            "& > .MuiAutocomplete-input": {
                                padding: 0,
                            },
                            "& > .MuiAutocomplete-tag": {
                                margin: 0,
                            },
                        },
                        ...inputStyle
                    }}
                    {...params}
                    placeholder={placeholder}
                    label={label}
                    variant="outlined"
                    onKeyDown={handleKeyDown}
                />
            )}

            value={field.value}
        />
    );
};

export default EmailAutocomplete;
