import React from 'react';
import { Box, TextField, styled } from '@mui/material';
import theme from '../../themes/theme';
import { useField } from 'formik';
import CustomLabel from '../CustomLabel';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: theme.palette.customColors.whiteBG,
    border: `1px solid ${theme.palette.customColors.g75}`,
    borderRadius: '4px',
    padding: '0px 0px 0px',
    '&:hover': {
      backgroundColor: theme.palette.customColors.whiteBG,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.customColors.whiteBG,
      borderColor: theme.palette.customColors.g75,
    },
    '& .MuiFilledInput-input': {
      padding: '13px 15px',
      height: 'auto',
      boxSizing: 'border-box',
    },
  },

  '& .MuiInputBase-input::placeholder': {
    color: theme.palette.customColors.g100,
    opacity: 1,
  },
}));

const CustomInputField = ({
  name,
  label,
  placeholder,
  multiline = false,
  minRows = 1,
  type = 'text',
  startAdornment,
  endAdornment,
  fullWidth = true,
  sx,
  labelStyle = {},
  InputProps = {},
  ...props
}) => {
  const [field, mata] = useField(name);

  const configTextfield = {
    ...field,
    ...props,
    fullWidth,
    autoComplete: 'off',
    variant: 'filled',
    placeholder,
    multiline,
    minRows,
    type,
    InputProps: {
      disableUnderline: true,
      ...InputProps
    },
    "& .MuiFormControl-root":{
      backgroundColor: "rgba(0,0,0,0)",
    },
    sx: { ...sx },
  };

//   if (mata && mata.touched && mata.error) {
//     // configTextfield.error = true;
//     configTextfield.helperText = mata.error;
//     configTextfield.helperText = <span style={{ color: '#ef5350' }}>{mata.error}</span>;
// }

  return (
    <Box sx={{ width: fullWidth ? "100%" : sx.width }}>
      {label && (
        <CustomLabel
          sx={{
            color: theme.palette.customColors.g500,
            marginBottom: '8px',
            display: 'block',
            fontSize: "0.875rem",
            textTransform: 'uppercase',
            ...labelStyle
          }}
        >
          {label}
        </CustomLabel>
      )}
      <CustomTextField
        helperText={mata && mata.error && mata.touched && <span className='validation-message' style={{ color: '#ef5350' }}>{mata.error}</span>}
        {...configTextfield}
      />
    </Box>
  );
};

export default CustomInputField;
