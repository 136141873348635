import React, { useState } from 'react';
import { Formik, Form, Field, FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Box,
  useMediaQuery,
  Select,
  Divider,
  CircularProgress,
  capitalize,
} from '@mui/material';
import { B400, BLUEBORDER, B300 as CustomButton, SECONDARY, WHITEBG } from '../../ui-components/CustomButton';
import { ReactComponent as DeleteIcon } from "../../assets/rounds_icons/delete_icon.svg";
import CustomInputField from '../../ui-components/formik/CustomInputField';
import CustomDatePicker from '../../ui-components/formik/CustomDatePicker';
import SelectField from '../../ui-components/CustomDropdown';
import CustomLabel from '../../ui-components/CustomLabel';
import CustomMenu from '../../ui-components/CustomMenu';
import PerfectScrollbar from "react-perfect-scrollbar";
import theme from '../../themes/theme';
import { ReactComponent as UploadDocumentsIcon } from "../../assets/rounds_icons/upload_documents_icon.svg";
import CustomTable from '../../ui-components/CustomTable';
import { TabContext, TabPanel } from '@mui/lab';
import { useTheme } from '@emotion/react';
import CustomTabs from '../../ui-components/CustomTabs';
import { makeStyles, styled } from '@mui/styles';
import ThemeBGWrapper from '../../ui-components/ThemeBGWrapper';
import { ReactComponent as RoundInvestorsIcon } from "../../assets/rounds_icons/round_investor_icon.svg"
import { ReactComponent as LifeBoyIcon } from '../../assets/nav_icons/life-buoy_nav_icon2.svg';
import { DateRangePicker } from 'rsuite';
import format from 'date-fns/format';
import { ReactComponent as InfoIcon } from '../../assets/help-circle.svg';
import { MailOutlineRounded } from '@mui/icons-material';
import ModalComponent from '../../ui-components/ModalComponent';
import InviteInvestorPopopup from './popups/InviteInvestorPopup';
import { AddReport, DeleteReportDocument, FetchAllDocumentTypes, FetchReportDetails } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import BreadcrumbHeader from '../../layout/BreadcrumbsHeader';
import CustomValidationText from '../../ui-components/CustomValidationText';
import CustomToast from '../../ui-components/CustomToast';
import toast from 'react-hot-toast';
import { containerRef, handleFormSubmit } from '../../utilities';
import NeedHelpButton from 'common-components/NeedHelpButton';
import AsyncTableListing from 'ui-components/AsyncTableListing';


const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const useStyles = makeStyles((theme) => ({
  scrollTabs: {
    '& > .Mui-disabled.MuiTabs-scrollButtons': {
      display: 'none',
      transition: 'all 1s ease'
    },
  },
  DateRangePicker: {
    '& > .rs-picker-input-group > input': {
      fontSize: "1rem",
      padding: '13.2px 15px',
    },
    '& .rs-input-group:focus-within': {
      borderColor: theme.palette.customColors.g75,
      boxShadow: 'none',
      outline: 'none',
      borderRadius: '4px'


    },
    '& .rs-input-group': {
      borderColor: theme.palette.customColors.g75 + " !important",
      // borderRadius:'8px'
    }


  }
}));

const validationSchema = Yup.object({
  name: Yup.string().required('Report name is required'),
  overview: Yup.string().required('Overview is required'),
  duration: Yup.object().required("Duration is required").nonNullable("Duration is required"),
  report_documents: Yup.array().of(
    Yup.object().shape({
      document_name: Yup.string().required('Document name is required'),
      type_id: Yup.string()
        .required('Document type is required')
        .test("type-id", "Document type is required", (value) => value !== '0'),
      reports: Yup.string().required('Document file is required'),
    })
  ).required('At least one document is required')
});

const initialValues = {
  name: '',
  overview: '',
  duration: null,
  // duration: {
  //   from_date: '',
  //   to_date: ''
  // },
  report_documents: [
    {
      document_name: '',
      type_id: "0",
      reports: null
    }
  ]
};

const CreateReport = () => {
  const { data: document_types, isRefetching: isRefetchingDocumentTypes, isLoading: isLoadingDocumentTypes, refetch: refetchDocumentTypes } = FetchAllDocumentTypes()
  const startupDetails = useSelector((state) => state?.startupDetails?.data);
  const [step, setStep] = useState(1);
  const { id: report_id } = useParams();
  const {
    mutateAsync: addReportAsync,
    isLoading: isAddingReport,
    status: addReportStatus,
    error: addReportError,
    data: addReportResponse
  } = AddReport();
  const {
    mutateAsync: deleteReportDocumentAsync,
    isLoading: isdeletingReportDocument,
    status: deleteReportDocumentStatus,
    error: deleteReportDocumentError,
    data: deleteReportDocumentResponse
  } = DeleteReportDocument();
  const { data: reportDetailData, isRefetching: isRefetchingReportsDetail, isLoading: isLoadingReportDetail, refetch: refetchReportDetail } = FetchReportDetails(report_id)
  // console.log(reportDetailData ,"+++++++++++++++++++++++")
  const [roundID, setRoundID] = React.useState(report_id || null)
  const isEditMode = !!report_id
  const reportTabs = [
    {
      label: `${isEditMode ? "Edit" : "Create"} Report`,
      icon: LifeBoyIcon,
      useStroke: true,
      value: "report",
    },
    // {
    //   label: "Investors",
    //   icon: RoundInvestorsIcon,
    //   value: "investors",
    // },
  ]

  const handleSubmit = (values) => {
    // console.log(values);
    const formData = new FormData();
    formData.append("name", values.name)
    formData.append("overview", values.overview)
    formData.append("from_date", values?.duration?.from_date)
    formData.append("to_date", values?.duration?.to_date)

    values?.report_documents?.forEach((doc, index) => {
      if (doc?.reports?.size) {
        formData.append(`document_name[${index}]`, doc?.document_name)
        formData.append(`type_id[${index}]`, doc?.type_id)
        formData.append(`reports${index}`, doc?.reports)
      } else {
        formData.append(`document_name[${index}]`, doc?.document_name)
        formData.append(`type_id[${index}]`, doc?.type_id)
        formData.append(`reports${index}`, doc?.reports)
      }
    });

    formData.append(`startup_id`, startupDetails?._id)

    if (isEditMode) {
      if (value != "investors") {
        formData.append("report_id", roundID)
        addReportAsync(formData)
      }
      else {
        navigate("/reports")
      }
    }
    else {
      if (value != "investors") {
        addReportAsync(formData)
      }
      else {
        navigate("/reports")
      }
    }

  };

  const formik = useFormik({
    initialValues: isEditMode && !isLoadingReportDetail ? reportDetailData?.report : initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    enableReinitialize: true,
  })

  React.useEffect(() => {
    if (addReportResponse && addReportResponse?.data?.statusCode == 200) {
      // if (step == 2 && value !== "investors") {
      //   // toast.success(`Report ${!isEditMode ? 'Created' : 'Updated'}!`)
      //   setValue("investors");

      //   addReportResponse?.data?.data?._id && navigate(`/reports/${window.location.pathname?.includes("create_report") ? "create_report" : 'edit_report'}/${addReportResponse?.data?.data?._id}`)
      //   addReportResponse?.data?.data?._id && setRoundID(addReportResponse?.data?.data?._id)
      //   addReportResponse?.data?.data?._id && refetchReportDetail()
      //   addReportResponse?.data?.data?._id && formik.setFieldValue("report_documents", [])
      //   // addReportResponse?.data?.data?._id && 
      //   window.document
      //     .getElementById("sidebar-scroller")
      //     .scrollTo(0, 0);

      // }
      // else 
      if (step == 1 && value !== "investors") {
        // toast.success(`Report ${!isEditMode ? 'Created' : 'Updated'}!`)
        navigate("/reports");
      }
      else {
        navigate("/reports");
      }
    }
    else if (addReportResponse && addReportResponse?.data?.statusCode != 200) {
      // toast.error(
      //   <CustomToast message={createRoundError?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
      //   {
      //     duration: 2000,
      //     position: "top-right",
      //   }
      // );
      // toast.error("Internal Server Error")
      // console.log("error", addReportError)
    }
  }, [addReportResponse?.data?.statusCode]);
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let stepIndex = new URLSearchParams(window.location.search).get("page");
  const [value, setValue] = React.useState(stepIndex || "report")
  const columns = React.useMemo(
    () => [
      { Header: 'Document Name', accessor: 'document_name', minWidth: 300 },
      { Header: 'Document Type', accessor: 'type_info.name', minWidth: 300 },
      {
        Header: "", accessor: 'actions', minWidth: 50
      },
    ],
    []
  );
  // const documents = [
  //   {
  //       filename: 'Tech requirements.pdf',
  //       type: 'MIS Reports',
  //   },
  //   {
  //       filename: 'Tech requirements.pdf',
  //       type: 'MIS Reports',
  //   }
  // ]


  const columnsInvestors = React.useMemo(
    () => [
      { Header: "", accessor: 'checkbox', minWidth: 50 },
      { Header: 'Name', accessor: 'name', minWidth: 200 },
      { Header: 'Sent', accessor: 'amountCommitted', minWidth: 200 },
      { Header: 'Viewed', accessor: 'amountAllocated', minWidth: 100 },

    ],
    []
  );
  const dataInvestors = React.useMemo(
    () => [
      {
        "name": "Olivia Rhye",
        "amountCommitted": "20 July, 2024",
        "amountAllocated": "Yes",
      },
      {
        "name": "Phoenix Baker",
        "amountCommitted": "19 July, 2024",
        "amountAllocated": "Yes",
      },
      {
        "name": "Lana Steiner",
        "amountCommitted": "Send Now",
        "amountAllocated": "No",
      },
      {
        "name": "Candice Wu",
        "amountCommitted": "Send Now",
        "amountAllocated": "No",
      },
    ]
    ,
    []
  );
  const [openInvitePopup, setOpenInvitePopup] = React.useState(false);

  const handleInvitePopupOpen = () => {
    setOpenInvitePopup(true);
  }

  const handleInvitePopupClose = () => {
    setOpenInvitePopup(false);
  }

  const handleInviteInvestor = () => {
    handleInvitePopupClose()
  }

  const Actions = () => {
    return <React.Fragment>
      <B400 sx={{ background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem" }} startIcon={<MailOutlineRounded sx={{ width: 20, height: 20 }} />} onClick={() => handleInvitePopupOpen()}>Invite{isSmallScreen ? "" : " Investor"}</B400>
    </React.Fragment>
  }
  const rowActions = (formik) => {
    // console.log(formik?.rows?.original,"formik")
    let document_id = formik?.row?.original?._id;
    return <React.Fragment>
      <IconButton onClick={() => {
        if (window.confirm("Are you sure you want to delete this document?") == true) {
          deleteReportDocumentAsync({ document_id: document_id }, {
            onSuccess: (succ) => {
              // console.log(succ, 'succ')
              toast.success(
                <CustomToast message={"Document Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                {
                  duration: 2000,
                  position: "top-right",
                }
              );
              refetchReportDetail();
            },
            onError: (err) => {
              toast.error(
                <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                {
                  duration: 2000,
                  position: "top-right",
                }
              );
            }
          });
        }
        else {
          return;
        }

      }
      }>
        <DeleteIcon />
      </IconButton>
    </React.Fragment>
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.document
      .getElementById("sidebar-scroller")
      .scrollTo(0, 0);
  }

  const BreadCrumbActions = () => {
    return (
      <>
        {/* {(isCreatingRound || isUploadingDocuments) && matchesUpMd && <Typography sx={{ marginRight: "1rem" }} display={"flex"} alignItems={'center'} gap={1} fontSize={"14px"} fontWeight={600} color={theme.palette.customColors.g200}>
                    <SavingIcon width={24} height={24} className="saving-icon" />
                    <span>Saving...</span>
                </Typography>} */}
        <NeedHelpButton />
      </>
    );
  };

  const scrollbarRef = React.useRef(null);
  const buttonRef = React.useRef(null);

  const scrollToBottom = () => {

    // Find the first visible validation message
    setTimeout(() => {
      if (buttonRef && buttonRef.current && buttonRef.current.offsetParent !== null) {
        buttonRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 10)
  }

  return (
    <ThemeBGWrapper>
      <BreadcrumbHeader
        title={`${isEditMode ? "Edit" : "Create"} Report`}
        // title={isLoadingRoundDetails ? "Loading..." : isEditMode ? `Edit ${matchesUpMd ? roundDetails?.round_name : 'Report'}` : 'Create Report'}
        Actions={BreadCrumbActions}
      />
      {(isLoadingReportDetail && isEditMode && !(window.location.pathname?.includes("create_report"))) ?
        <Grid container xs={12} display={"flex"} height={"100%"} maxHeight={"85vh"} sx={{ padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 0rem 1rem 0rem" }, width: '100%' }} marginInline={"auto"} maxWidth={{ xs: "80%", sm: '90%', md: '800px', lg: '995px' }}>
          <Grid item xs={12} className="skeleton" height={"100%"} width={"100%"} borderRadius={"24px"}>

          </Grid>
        </Grid>
        :
        <FormikProvider value={formik}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 140px)'
            }}
          >
            <Box
              sx={{
                backgroundColor: '#FFFAF5',
                padding: '12px 24px',
                borderRadius: '8px',
                border: "1px solid #FFE06B",
                width: '100%',
                height: '100%',
                maxWidth: { xs: "80%", sm: '90%', md: '800px', lg: '995px' },
              }}
            >

              <TabContext value={value}>
                <CustomTabs data={reportTabs} bgColor='transparent' value={value} tabStyle={{ padding: '1rem 2rem' }} isSmallScreen={isSmallScreen} className={classes} handleChange={handleChange} key={"Tabs Tabs"} />
                <PerfectScrollbar containerRef={containerRef} style={{
                  minHeight: "58vh",
                  height: "100%",
                  width: "100%",
                  maxHeight: "calc(100vh - 300px)",
                  paddingRight: "1rem",
                  marginTop: 0,
                  paddingBottom: "1rem",
                }}
                  ref={scrollbarRef}
                  id='sidebar-scroller'
                  className="scrollbar-container"
                >
                  <TabPanel sx={{ paddingInline: 0 }} value='report'>

                    <Form onSubmit={e => e.preventDefault()}>
                      <Grid container columnSpacing={2} rowSpacing={0}>
                        <Grid item xs={12} sm={6}>
                          <CustomInputField
                            fullWidth
                            name="name"
                            label="NAME"
                            placeholder="Enter Name"
                            value={formik?.values?.name}
                            onChange={(e) => formik?.setFieldValue("name", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))}
                          // sx={{
                          //   marginBottom: "0.5rem"
                          // }}
                          />
                        </Grid>


                        <Grid item xs={12} sm={6} height={"min-content"}>
                          <CustomLabel>DURATION</CustomLabel>
                          <DateRangePicker
                            className={`${classes.DateRangePicker}`}
                            ranges={[]}
                            style={{ width: '100%' }}
                            placeholder="Select Date"
                            format="dd-MMM-yyyy"
                            onChange={(value) => {
                              formik?.setFieldValue(
                                'duration',
                                value !== null
                                  ? { from_date: moment(value[0])?.toISOString(), to_date: moment(value[1])?.toISOString() }
                                  : null
                              );
                            }}
                            value={
                              formik.values?.duration !== null
                                ? [new Date(formik.values?.duration?.from_date), new Date(formik.values?.duration?.to_date)]
                                : null
                            }
                            renderValue={([from_date, to_date]) => {
                              return (
                                format(from_date, 'dd-MMM-yyyy') + ' - ' + format(to_date, 'dd-MMM-yyyy')
                              );
                            }}
                          />

                          <CustomValidationText value={formik.errors.duration} />
                        </Grid>


                        <Grid item xs={12} marginTop={{ xs: 2, sm: 0 }}>
                          <CustomInputField
                            fullWidth
                            name="overview"
                            placeholder="Enter overview details"
                            label="OVERVIEW"
                            multiline
                            // rows={3}
                            minRows={3} maxRows={4} maxLength={250} data-problem-solving={250 - formik.values?.overview?.length + `${(250 - formik.values?.overview?.length) > 1 ? " characters" : " character"} left`}
                            sx={{
                              "::after": {
                                content: 'attr(data-problem-solving)',
                                position: "absolute",
                                bottom: formik?.errors?.overview?.length > 0 ? 30 : 5,
                                right: 5,
                                background: 'white',
                                color: 'graytext',
                                padding: "0 5px",
                                fontSize: "0.75rem"
                              },
                            }}
                            // sx={{
                            //   marginBottom: 0
                            // }}
                            value={formik?.values?.overview}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value.length <= 250) {
                                formik.setFieldValue(`overview`, capitalize(e.target.value))
                              }
                              else {
                                formik.setFieldValue(`overview`, String(capitalize(e.target.value))?.substring(0, 250))
                              }
                            }}
                          />
                        </Grid>

                        <FieldArray
                          name="report_documents"
                          render={(arrayHelpers) => (
                            <React.Fragment>
                              {formik?.values?.report_documents?.length > 0 &&
                                formik?.values.report_documents?.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <Grid container item columnSpacing={2}>
                                      <Grid item xs={12} sm={3} pt={1}>
                                        <CustomInputField
                                          name={`report_documents.${index}.document_name`}
                                          label="DOCUMENT NAME"
                                          placeholder="Enter document name"
                                          value={item?.document_name}
                                          onChange={(e) => formik?.setFieldValue(`report_documents.${index}.document_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))}
                                          fullWidth
                                          sx={{
                                            '& > .MuiInputBase-root > input.MuiInputBase-input': { padding: "13.5px 14px 13.5px 14px" },
                                            borderRadius: '8px',
                                            height: "100%",
                                            margin: 0,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={3} height={"min-content"} pt={1}>
                                        <CustomLabel>DOCUMENT TYPE</CustomLabel>
                                        <Select
                                          size="small"
                                          fullWidth
                                          name={`report_documents.${index}.type_id`}
                                          placeholder='Select document type'
                                          value={item?.type_id}
                                          // onChange={(e) => {}
                                          //   // formik.setFieldValue(
                                          //   //   "runway_left",
                                          //   //   e.target.value
                                          //   // )
                                          // }
                                          onChange={formik?.handleChange}
                                          sx={{
                                            background: theme.palette.customColors.whiteBG,
                                            paddingBlock: "6px",
                                            margin: 0,
                                          }}
                                        >
                                          <MenuItem value={"0"}>Select document type</MenuItem>
                                          {document_types && document_types?.length > 0 && document_types?.filter(item => item?.name != "Pitch Deck" && item?.name != "Pitch Deck Round")?.map((type, index) => {
                                            return <MenuItem key={index} value={type?.value}>{type?.name}</MenuItem>
                                          })}
                                        </Select>
                                        {formik?.errors?.report_documents && formik.errors.report_documents[index] && formik.errors.report_documents[index].type_id && <CustomValidationText value={formik?.errors?.report_documents[index]?.type_id} />}
                                      </Grid>
                                      <Grid item xs={12} sm={3} pt={1}>
                                        <CustomLabel>UPLOAD FILE</CustomLabel>
                                        <CustomMenu>
                                          <WHITEBG
                                            component="label"
                                            role={undefined}
                                            tabIndex={-1}
                                            fullWidth
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              border: `1px solid ${theme.palette.customColors.g75}`,
                                              borderRadius: "8px",
                                              height: "52px",
                                              padding: "10px, 14px, 10px, 14px",
                                              color: '#7A7D83',
                                              textTransform: 'none'
                                            }}
                                          >
                                            {item?.reports != null ? String(item?.reports?.name)?.length > 19 ? String(item?.reports?.name)?.substring(0, 19) + "..." : item?.reports?.name : "Upload"}
                                            <UploadDocumentsIcon
                                              style={{
                                                width: 15,
                                                height: 15,
                                                stroke: "#344054",
                                                padding: "8px",
                                                borderRadius: "8px",
                                                border: `1px solid ${theme.palette.customColors.gray300}`,
                                              }}
                                            />
                                            <VisuallyHiddenInput
                                              accept=".pdf,.png,.jpeg,.jpg,.mp4,.mov,.xlsx,.csv,.xls,.doc,.docx"
                                              type="file"
                                              name={`report_documents.${index}.reports`}
                                              //  value={item?.reports}
                                              id={`report_documents.${index}.reports`}
                                              onChange={(e) => {
                                                const file = e.target.files[0];
                                                formik.setFieldValue(`report_documents.${index}.reports`, file);
                                              }
                                              }
                                            />
                                          </WHITEBG>
                                        </CustomMenu>
                                        {formik?.errors?.report_documents && formik.errors.report_documents[index] && formik?.errors.report_documents[index]?.reports && <CustomValidationText value={formik?.errors.report_documents[index]?.reports} />}
                                      </Grid>

                                      <Grid item xs={12} sm={3} pt={1}>
                                        <CustomLabel sx={{ opacity: 0, display: { xs: 'none', sm: "block" } }}> Delete</CustomLabel>

                                        <Grid item xs={12}>
                                          <BLUEBORDER

                                            onClick={() => {
                                              arrayHelpers.remove(index);

                                            }}
                                            fullWidth
                                            sx={{
                                              color: theme.palette.customColors.r200,
                                              padding: "12px, 24px, 12px, 24px",
                                              border: `1px solid ${theme.palette.customColors.r100}`,
                                              height: "52px",
                                            }}
                                          >
                                            Delete
                                          </BLUEBORDER>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                ))}
                              {/* {formik?.values?.report_documents?.length === 0 && */}
                              <Grid container item columnSpacing={2}>
                                <Grid item xs={12} sm={3} pt={2} ml={"auto"}>
                                  <BLUEBORDER
                                    ref={buttonRef}
                                    onClick={() => {
                                      arrayHelpers.insert(formik.values?.report_documents?.length, {
                                        document_name: '',
                                        type_id: "0",
                                        reports: null
                                      });
                                      scrollToBottom()
                                    }}
                                    fullWidth
                                    sx={{
                                      // padding: "12px, 24px, 12px, 24px",
                                      border: `1px solid ${theme.palette.customColors.b75}`,
                                      height: "52px",
                                    }}
                                  >
                                    Add Document
                                  </BLUEBORDER>

                                </Grid>
                              </Grid>
                            </React.Fragment>
                          )}
                        />

                        {isEditMode && reportDetailData?.documents && reportDetailData?.documents?.length > 0 && <Grid item container xs={12} paddingTop={2} id="report-documents-table">
                          <AsyncTableListing columns={columns} data={reportDetailData?.documents} actions={() => { }} rowActions={rowActions} backgroundColor="#fff" />
                        </Grid>}

                      </Grid>
                    </Form>
                  </TabPanel>
                  <TabPanel sx={{ paddingInline: 0, paddingTop: "3rem" }} value='investors'>
                    <ModalComponent open={openInvitePopup} onClose={handleInvitePopupClose} style={{ maxWidth: 640, width: "100%" }}>
                      <InviteInvestorPopopup handleClose={handleInvitePopupClose} handleSubmit={handleInviteInvestor} />
                    </ModalComponent>
                    <Grid item container spacing={2} xs={12} width="100%" marginInline={"auto"}>
                      <Grid xs={12} >
                        <AsyncTableListing columns={columnsInvestors} data={dataInvestors} title={"Investors"} actions={Actions} pagination={false} />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </PerfectScrollbar>
              </TabContext>

              <Grid container item spacing={2} paddingInline={"1.5rem"}>
                {/* {value !== 'investors' && (<Grid item xs={6} sm={6}>
                  <BLUEBORDER
                    fullWidth
                    disabled={isAddingReport}
                    onClick={(e) => { setStep(1); handleFormSubmit(e, formik) }}
                    sx={{
                      border: `1px solid ${theme.palette.customColors.b100}`,
                      padding: '12px, 20px, 12px, 20px',
                      borderRadius: '8px',
                      height: '60px',

                    }}
                  >
                    {step == 1 && isAddingReport ?
                      <CircularProgress /> :
                      "Save"}
                  </BLUEBORDER>
                </Grid>)} */}

                <Grid item xs={value === 'investors' ? 12 : 6} marginInline={"auto"}>
                  <CustomButton
                    fullWidth
                    onClick={(e) => { setStep(2); handleFormSubmit(e, formik) }}
                    sx={{
                      padding: '12px, 20px, 12px, 20px',
                      border: `1px solid ${theme.palette.customColors.b75}`,
                      borderRadius: '8px',
                      height: '60px',
                    }}
                    disabled={isAddingReport}
                  >
                    {step == 2 && isAddingReport ?
                      <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.3rem'} /> :
                      <>
                        Save
                      </>
                    }
                  </CustomButton>
                </Grid>
                {/* {value === 'investors' ? "Send Report" : "Save & Next"} */} {/* revert after mvp */}
              </Grid>
            </Box>

          </Box>
        </FormikProvider >}
    </ThemeBGWrapper >
  )
}

export default CreateReport