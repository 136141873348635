import React from 'react'

const CustomValidationText = ({ value = "", style= {}, ...props }) => {
    return (
        <span
            className='validation-message'
            style={{
                color: "rgb(239, 83, 80)",
                fontWeight: "400",
                fontSize: "0.75rem",
                lineHeight: "1.66",
                letterSpacing: "0.03333em",
                textAlign: "left",
                marginTop: "3px",
                marginRight: "14px",
                marginBottom: "0",
                marginLeft: "14px",
                ...style
            }}
            {...props}
        >
            {String(value)?.charAt(0)
                ?.toUpperCase() +
                (String(value)?.slice(1))?.toLowerCase()}
        </span>
    )
}

export default CustomValidationText