import { useTheme } from '@emotion/react'
import { TabList } from '@mui/lab'
import { Box, Card, Chip, Tab, Typography } from '@mui/material'
import React from 'react'


const CustomTabs = ({ data = [], value, handleChange = () => { }, className: classes, isSmallScreen, titleStyle, tabStyle, variant = "menu", indicatorFullSize = false ,bgColor, sx = {}}) => {
    const theme = useTheme()

    const variantSwitch = (tabItem, index) => {
        switch (variant) {

            case 'submenu': return <Tab disableRipple value={tabItem?.value} sx={{ padding:  tabStyle ? tabStyle?.padding : `${isSmallScreen ? "1rem 1rem 1rem 1rem" : '0rem 2rem 1rem'}` }} label={<>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "0.4rem" }} >

                    {tabItem?.icon && <Box width={isSmallScreen ? "1rem" : "2rem"} height={isSmallScreen ? '1rem' : "2rem"} display={"grid"} sx={{ placeContent: 'center', background: 'transparent', borderRadius: '16px', 
                    // transition: 'all 0.25s ease'
                     }}>
                        <tabItem.icon width={isSmallScreen ? 16 : 20} height={isSmallScreen ? 16 : 20}
                        sx={{color: value === tabItem?.value ? theme.palette.customColors.indigo700 : '#191F29'}}
                        //  stroke={tabItem?.useStroke ? (value === tabItem?.value ? theme.palette.customColors.indigo700 : '#191F29') : ''} 
                        //  fill={value === tabItem?.value ? theme.palette.customColors.indigo700 : '#191F29'}
                          />
                    </Box>}
                    <Typography sx={{
                        fontSize: titleStyle ? titleStyle.fontSize : '1.25rem',
                        fontWeight: titleStyle ? titleStyle.fontWeight : 700,
                        lineHeight: '24px',
                        textAlign: "center",
                        textTransform: 'capitalize',
                        color: theme.palette.customColors.g300
                    }}>
                        {tabItem?.label}
                    </Typography>
                </div>

            </>} />

            default: return <Tab disableRipple value={tabItem?.value} sx={{ padding:  tabStyle ? tabStyle?.padding : `${isSmallScreen ? "1rem 1rem 1rem 1rem" : '0rem 2rem 1rem'}` }} label={<>
                <div style={{ display: 'flex', alignItems: 'center', gap: "0.5rem" }} >

                    {tabItem?.icon && <Box width={isSmallScreen ? "1rem" : "3rem"} height={isSmallScreen ? '1rem' : "3rem"} display={"grid"} sx={{ placeContent: 'center', background: value === tabItem?.value ? theme.palette.customColors.indigo50 : 'transparent', borderRadius: '16px', 
                    // transition: 'all 0.25s ease'
                     }}>
                        <tabItem.icon width={isSmallScreen ? 16 : 24} height={isSmallScreen ? 16 : 24} stroke={tabItem?.useStroke ? (value === tabItem?.value ? theme.palette.customColors.indigo700 : '#191F29') : ''} fill={tabItem?.useStroke ? 'transparent' : value === tabItem?.value ? theme.palette.customColors.indigo700 : '#191F29'} />
                    </Box>}
                    <Typography sx={{
                        fontSize: titleStyle ? titleStyle.fontSize : '1.25rem',
                        fontWeight: titleStyle ? titleStyle.fontWeight : 700,
                        lineHeight: '24px',
                        textAlign: "center",
                        textTransform: 'capitalize',
                        color: theme.palette.customColors.g300
                    }}>
                        {tabItem?.label}
                    </Typography>
                </div>

            </>} />
        }
    }

    return (
        <>
            {/* <TabList
                sx={{
                    width: '100%',
                    overflow: 'hidden', // Ensure only one tab is visible at a time
                }}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                onChange={handleChange}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                    sx: {
                        bgcolor: indicatorFullSize ? theme.palette.customColors.indigo700 : 'transparent',
                        height: '1px',
                    },
                }}
            >
                {data?.map((tabItem, index) => (variantSwitch(tabItem, index)))}
            </TabList> */}

            <TabList sx={{ width: '100%', background: bgColor ? bgColor : "#fff", paddingBottom:  variant === "submenu" ? '0.5rem': '', border: variant === "submenu" ? '1px solid #e0e0e0' : '', "& > .MuiTabs-scroller > .MuiTabs-flexContainer" : { gap: variant === "submenu" ? {xs: '1rem',sm: '2.5rem', md: '5rem' } : 0, paddingInline: variant === "submenu" ? '2rem' : ''}, borderRadius: variant === "submenu" ? '8px 8px 0 0' : 0, overflow: variant === "submenu" ? 'hidden' : 'none', ...sx }} variant='scrollable' allowScrollButtonsMobile={false} className={classes.scrollTabs} 
            //  TabIndicatorProps={{ sx: { bgcolor: indicatorFullSize ? 'rgb(122 198 255 / 20%)' : theme.palette.customColors.indigo700,
             // MuiTabs-flexContainer : {marginLeft: '2rem', gap: '5rem'}
             TabIndicatorProps={{ sx: { bgcolor: theme.palette.customColors.indigo700,
              height: indicatorFullSize ? 0 : '1.5px',borderRadius: variant === "submenu" ? '0 0' : 0 } }}
                onChange={handleChange} aria-label="lab API tabs example">
                {data?.map((tabItem, index) => variantSwitch(tabItem, index)
                )}
            </TabList>
        </>
    )
}

export default CustomTabs