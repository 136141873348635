import React from 'react'
import BreadcrumbHeader from '../../layout/BreadcrumbsHeader'
import { useTheme } from '@emotion/react'
import ThemeBGWrapper from '../../ui-components/ThemeBGWrapper'
import { B300 } from '../../ui-components/CustomButton'
import { Add } from '@mui/icons-material'
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, LinearProgress, Menu, MenuItem, Tooltip, Typography, useMediaQuery, Dialog } from '@mui/material'
import fundingRoundsBackground from "../../assets/rounds_icons/funding_rounds_background.webp"
import { FetchAllRounds, ChangeRoundStatus } from '../../api'
import { ReactComponent as SecurityIcon } from "../../assets/rounds_icons/security_icon.svg"
import { ReactComponent as ViewIcon } from "../../assets/rounds_icons/view_icon.svg"
import { ReactComponent as MoreIcon } from "../../assets/rounds_icons/more_icon.svg"
import { useNavigate } from 'react-router-dom'
import rocket_rounds_image from "../../assets/rounds_icons/rocket_rounds_image.png"
import PerfectScrollbar from "react-perfect-scrollbar"
import moment from 'moment'
import NoRounds from './NoRounds'
import { containerRef, handleDateValues } from '../../utilities'
import { useSelector } from 'react-redux'
import { FetchStartupDetails } from 'api/profile'
import { ReactComponent as EditIcon } from "assets/rounds_icons/edit_icon.svg";
import UpdateRoundStatusPopup from './popups/UpdateRoundStatusPopup'
import ModalComponent from "ui-components/ModalComponent"

const Rounds = () => {
  const theme = useTheme()
  const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate()
  const details = useSelector((state) => state?.startupDetails?.data);
  // const {data: details, isLoading} = FetchStartupDetails();
  const { data: roundsData, isLoading: isLoadingRounds, mutateAsync: mutateAsyncRounds } = FetchAllRounds()
  const { isLoading: isChangingRoundStatus, mutateAsync: changeRoundStatusAsync } = ChangeRoundStatus()

  React.useEffect(() => {
    details && mutateAsyncRounds({ startup_id: details ? details?._id : '' })
  }, [details])

  const refetchRoundsData = () => {
    mutateAsyncRounds({ startup_id: details ? details?._id : '' });
  }

  const RoundActions = () => {
    return (
      <B300 onClick={() => navigate("/rounds/create_round")} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 600, minWidth: isSmallScreen ? "0" : '64px', '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' } }} startIcon={<Add fontSize='1.25rem' />}>
        {!isSmallScreen ? "Add Round" : ''}
      </B300>
    )
  }

  const [selectedRound, setSelectedRound] = React.useState(null)

  const handleOpenStatusModal = ({ round }) => {
    setSelectedRound(round);
  }

  const handleCloseStatusModal = () => {
    setSelectedRound(null)
  }

  const handleUpdateRoundStatus = ({round}) => {
    // console.log(round,"round")
    changeRoundStatusAsync({payload: round , startup_id: details?._id}).then(() => {
      handleCloseStatusModal();
      refetchRoundsData()
    }).catch((err) => console.log("error", 'err'))
  }

  const RoundCard = ({ data: cardData, key: index }) => {
    // popper more menu
    // const [currentIndex, setCurrentIndex] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event, index) => {
      setAnchorEl(event.currentTarget);
      // setCurrentIndex(index)
      // console.log(event.currentTarget,"re")
    };
    const handleCloseMenu = (event) => {
      // console.log(event,"event")
      setAnchorEl(null);
      // setCurrentIndex(null);
    };

    const [copied, setCopied] = React.useState(false);
    return (
      <Grid key={index} item xs={12} sm={6}
      // sm={5.8}
      //  md={5.8}
      //   lg={5.9}
      >
        <Card sx={{ borderRadius: "10px", border: `1px solid ${theme.palette.customColors.b50}`, overflow: 'hidden', boxShadow: 'none' }}>
          <CardHeader
            sx={{ background: theme.palette.customColors.white, borderBottom: `1px solid ${theme.palette.customColors.b50}`, "& > .MuiCardHeader-avatar": { maxWidth: { xs: "43%", md: "50%" }, marginRight: "8px" }, "& > .MuiCardHeader-action": { maxWidth: "50%" } }}
            avatar={
              <Box display={"flex"} sx={{ gap: 1, alignItems: "center" }} maxWidth="100%">
                <Typography fontWeight={700} width="100%" fontSize={{ xs: "0.8rem", md: "0.9rem" }} color={theme.palette.customColors.g300} sx={{ cursor: 'pointer', wordBreak: 'break-all', overflowWrap: "break-word", whiteSpace: "normal" }} onClick={() => navigate(`/rounds/view/${cardData?._id}`)}>
                  {cardData?.round_name && cardData?.round_name != "" ? cardData?.round_name : '-'} {cardData?.round_type_info?.name && cardData?.round_type_info?.name != "" ? `(${cardData?.round_type_info?.name})` : "-"}
                </Typography>
              </Box>
            }


            action={
              <>
                <Box display={"flex"} gap={2}>
                  {/* <Tooltip title="View">
                  <IconButton sx={{ padding: 0, display: { xs: "none", md: 'inline-flex' } }} onClick={() => navigate(`/rounds/view/${cardData?._id}`)} aria-label="view">
                    <ViewIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton sx={{
                    padding: 0, width: 32, height: 32, display: { xs: "none", lg: 'inline-flex' }, border: "1px solid rgb(161, 163, 167)",
                    background: "#f5f8ff", ":hover": { bgcolor: "#f5f8ff" }
                  }} onClick={() => navigate(`/rounds/edit_round/${cardData?._id}`)} aria-label="edit">
                    <EditIcon
                      style={{
                        cursor: "pointer",
                        width: 20,
                        height: 20,
                      }}
                    />
                  </IconButton>
                </Tooltip> */}
                  <Grid item xs={12} display={"flex"} justifyContent={"space-between"} >
                    <Box display={"flex"} flexDirection={{ xs: "column", lg: 'row' }} sx={{ gap: { xs: 0.5, lg: 1 }, alignItems: "center" }}>
                      <Box>
                        <Typography sx={{ fontSize: '0.8rem', bgcolor: '#D2F2E7', color: "#1B8662", fontWeight: 700, padding: "6px 8px", borderRadius: "4px", textTransform: 'uppercase', lineHeight: "10px", ":hover": {bgcolor: "ActiveColor"}, cursor: 'pointer' }} onClick={() => handleOpenStatusModal({ round: cardData })}>
                          {cardData?.round_status_info ? isNotLargeScreen ? cardData?.round_status_info?.name : cardData?.round_status_info?.name : '-'}
                        </Typography>
                      </Box>
                      <Box display={"flex"} sx={{ gap: 0.5, alignItems: "center" }}>
                        <SecurityIcon width={'0.8rem'} height={'0.8rem'} />
                        <Typography sx={{ color: theme.palette.customColors.g300, fontWeight: 500, fontSize: '0.8rem' }}>
                          {cardData?.security_type_info?.name ? cardData?.security_type_info?.name : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Box height={"100%"} display={"contents"}>
                    <IconButton sx={{ padding: 0, marginBlock: 'auto' }} onClick={(e) => handleClickMenu(e, index)} aria-label="more">
                      <MoreIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={openMenu}
                      aria-controls={openMenu ? 'fade-menu' : 'fade-menu'}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : 'false'}
                      onClose={handleCloseMenu}
                      onClick={handleCloseMenu}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          padding: "12px",
                          borderRadius: "8px",
                          "& > ul.MuiList-root": { padding: "0 !important" },
                          bgcolor: theme.palette.customColors.g400,
                          color: theme.palette?.customColors?.whiteBG,
                          '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 11,
                            width: 10,
                            height: 10,
                            bgcolor: theme.palette.customColors.g900,
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => navigate(`/rounds/view/${cardData?._id}`)}>
                        View Details
                      </MenuItem>
                      <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => navigate(`/rounds/view/${cardData?._id}?page=round_investors`)}>
                        Investors
                      </MenuItem>
                      <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => navigate(`/rounds/view/${cardData?._id}?page=documents`)}>
                        Documents
                      </MenuItem>
                      <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => handleOpenStatusModal({ round: cardData })}>
                        Change Status
                      </MenuItem>
                      <MenuItem disableRipple sx={{ padding: '0.625rem 1rem' }} onClick={() => navigate(`/rounds/edit_round/${cardData?._id}`)}>
                        Edit Round
                      </MenuItem>
                    </Menu>
                  </Box>
                  {/* {currentIndex === index && */}
                  {/* } */}
                </Box>
              </>
            }
          />
          <CardContent sx={{ paddingBottom: "0 !important" }}>
            <Grid container sx={{
              padding: '0.75rem',
              gap: '1rem',
              borderRadius: '8px',
              border: `1px solid ${theme.palette.customColors.b75}`,
              background: theme.palette.customColors.b50,
              display: 'flex',
              justifyContent: "space-between",
              flexWrap: 'wrap'
            }}>
              <Grid item width={{ xs: '100%', md: '59%' }} display={"grid"} gap={2}>
                <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
                  <Grid item>
                    <Typography fontSize={{ xs: "0.9rem", sm: "0.8rem", md: "0.9rem", lg: "1rem" }} fontWeight={700} color={theme.palette.customColors.g300}>
                      {cardData?.total_round_size_final ? cardData?.total_round_size_final : "-"}
                    </Typography>
                    <Typography fontSize={{ xs: "0.9rem", sm: "0.8rem", md: "0.9rem", lg: "1rem" }}>
                      Total Round Size
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontSize={{ xs: "0.9rem", sm: "0.8rem", md: "0.9rem", lg: "1rem" }} fontWeight={700} color={theme.palette.customColors.g300}>
                      {cardData?.total_interested_amount_final ? cardData?.total_interested_amount_final : '-'}
                    </Typography>
                    <Typography fontSize={{ xs: "0.9rem", sm: "0.8rem", md: "0.9rem", lg: "1rem" }}>
                      Committed Amount
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} display={"flex"} flexDirection={"column-reverse"}>
                  <LinearProgress value={cardData?.percentage} variant='determinate' sx={{ borderRadius: '5px', background: "white", "& > .MuiLinearProgress-bar": { background: theme.palette.customColors.purple_dark } }} />
                </Grid>
              </Grid>
              <Grid item width={{ xs: "100%", md: "35%" }} sx={{ background: theme.palette.customColors.purple_dark, color: theme.palette.customColors.whiteBG, padding: '0.75rem 1rem', borderRadius: '8px' }}>
                <Typography fontSize={{ xs: "0.9rem", sm: "0.8rem", md: "0.9rem", lg: "1rem" }} fontWeight={700}>
                  {/* {cardData?.currency_info}132K */}
                  {cardData?.total_allocated_amount_final ? cardData?.total_allocated_amount_final : '-'}
                </Typography>
                <Typography fontSize={{ xs: "0.9rem", sm: "0.8rem", md: "0.9rem", lg: "1rem" }}>
                  Amount Received
                </Typography>
              </Grid>
            </Grid>
            <Grid container display={"flex"} justifyContent={"space-between"} xs={12} mt={"1rem"} paddingBottom={2}>
              <Grid item xs={12} display={"grid"}>
                {/* <Grid item xs={12} display={"flex"} justifyContent={"space-between"} mb={1}>
                  <Box display={"flex"} sx={{ gap: 1, alignItems: "center" }}>
                    <SecurityIcon width={'1.25rem'} height={'1.25rem'} />
                    <Typography sx={{ color: theme.palette.customColors.g300, fontWeight: 500, fontSize: '1.125rem' }}>
                      {cardData?.security_type_info?.name ? cardData?.security_type_info?.name : "-"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ bgcolor: '#D2F2E7', color: "#1B8662", fontWeight: 700, padding: "6px 8px", borderRadius: "4px", textTransform: 'uppercase' }}>
                      {cardData?.round_status_info ? isNotLargeScreen ? cardData?.round_status_info?.name : cardData?.round_status_info?.name + " ROUND" : '-'}
                    </Typography>
                  </Box>
                </Grid> */}
                <Grid item container display={"flex"} alignItems={"center"} justifyContent={"space-between"} xs={12}>
                  <Grid item xs={5.8} md={5.8} lg={5.9} sx={{ padding: '0.75rem', border: `1px solid ${theme.palette.customColors.g50}`, borderRadius: '8px' }}>
                    <Typography fontSize={{ xs: "0.9rem", sm: "0.8rem", md: "0.9rem", lg: "1rem" }} fontWeight={700} color={theme.palette.customColors.g300}>
                      {/* {cardData?.investors ? cardData?.investors?.length : 0} */}
                      {cardData?.total_investor ? cardData?.total_investor : 0}
                    </Typography>
                    <Typography fontSize={{ xs: "0.9rem", sm: "0.8rem", md: "0.9rem", lg: "1rem" }}>
                      Investors
                    </Typography>
                  </Grid>
                  <Grid item xs={5.8} md={5.8} lg={5.9} sx={{ padding: '0.75rem', border: `1px solid ${theme.palette.customColors.g50}`, borderRadius: '8px' }}>
                    <Typography fontSize={{ xs: "0.9rem", sm: "0.8rem", md: "0.9rem", lg: "1rem" }} fontWeight={700} color={theme.palette.customColors.g300}>
                      {cardData?.round_valuation_final ? cardData?.round_valuation_final : "-"}
                    </Typography>
                    <Typography fontSize={{ xs: "0.9rem", sm: "0.8rem", md: "0.9rem", lg: "1rem" }}>
                      Valuation
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography bgcolor={"#E2F0FF"} color={"#0075FF"} fontWeight={700} fontSize={"0.75rem"} padding={"6px 8px"} mt={1} width={"max-content"} borderRadius={"4px"}>
                    {handleDateValues(cardData?.final_round_start_date, "No Start Date")} - {handleDateValues(cardData?.final_round_closing_date, "No Closing Date")}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Grid item xs={3}>
                              <img src={rocket_rounds_image} style={{ height: 188, width: 188, marginBottom: -6 }} />
                            </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }



  return (
    <>
      {/* /Modals Start/ */}
      <Dialog open={Boolean(selectedRound)} onClose={handleCloseStatusModal} aria-labelledby="update-Status-dialog" sx={{ "& > .MuiDialog-container > .MuiPaper-root" :{ minWidth: 400} }}>
        <UpdateRoundStatusPopup selectedRound={selectedRound} handleClose={handleCloseStatusModal} handleSubmit={handleUpdateRoundStatus} isLoading={false} open={Boolean(selectedRound)}  />
      </Dialog>
      {/* /Modals End/   */}
      <ThemeBGWrapper bgColor={theme.palette.customColors.yellow_bg}>
        <BreadcrumbHeader title="Funding Rounds" breadcrumbTitle='Funding Rounds' customBackgroundColor={theme.palette.customColors.indigo25} Actions={RoundActions} />
        {
          isLoadingRounds ?
            <PerfectScrollbar
              containerRef={containerRef}
              style={{ height: "calc(100vh - 120px)", overflowX: 'hidden' }}>
              {/* <Grid item xs={12} display={"grid"} height={"100vh"} sx={{ placeContent: 'center' }}> */}
              {/* <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} /> */}

              <Grid container xs={12} padding={{ xs: "0 1rem 1rem 1rem", md: "0 2rem 2rem 2rem" }} justifyContent={"space-between"} rowGap={4} columnGap={2}>
                {/* {!isNotLargeScreen && <Grid className="skeleton" item xs={12} sx={{ height: 232, width: '100%', borderRadius: '10px' }}>

              </Grid>} */}
                <Grid className="skeleton" item xs={12} sm={5.8} lg={5.9} sx={{ minHeight: { xs: 300, sm: 350, md: 380 }, width: '100%', borderRadius: '10px' }}>

                </Grid>
                <Grid className="skeleton" item xs={12} sm={5.8} lg={5.9} sx={{ minHeight: { xs: 300, sm: 350, md: 380 }, width: '100%', borderRadius: '10px' }}>

                </Grid>
                <Grid className="skeleton" item xs={12} sm={5.8} lg={5.9} sx={{ minHeight: { xs: 300, sm: 350, md: 380 }, width: '100%', borderRadius: '10px' }}>

                </Grid>
                <Grid className="skeleton" item xs={12} md={5.8} lg={5.9} sx={{ minHeight: { xs: 300, sm: 350, md: 380 }, width: '100%', borderRadius: '10px' }}>

                </Grid>
              </Grid>
              {/* </Grid>  */}
            </PerfectScrollbar>
            :
            <>
              {roundsData?.data?.data && roundsData?.data?.data?.length > 0 &&
                <PerfectScrollbar
                  containerRef={containerRef}
                  style={{ height: "calc(100vh - 120px)" }}>
                  {/* {!isNotLargeScreen && <Box sx={{ paddingInline: "2rem" }}>
                  <img loading='lazy' src={fundingRoundsBackground} style={{ width: '100%', maxHeight: '232.3px', height: '100%' }} />
                </Box>} */}
                  <Grid container xs={12} padding={{ xs: "0 0rem 1rem 1rem", md: "0 1rem 2rem 2rem" }} spacing={2} justifyContent={"space-between"}>
                    {
                      roundsData?.data?.data?.map((round, index) => <RoundCard data={round} key={index} />)
                    }
                  </Grid>

                </PerfectScrollbar>}
              {(roundsData?.data?.data?.length === 0) && <NoRounds />}
            </>}
      </ThemeBGWrapper>
    </>
  )
}

export default Rounds