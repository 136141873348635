import toast, { Toaster } from 'react-hot-toast';
import ThemeRoutes from "./routes";
import { ThemeProvider } from "@mui/material";
import theme from "./themes/theme";

function App() {
  return (
    <>
      <Toaster position='top-right' />
      <ThemeProvider theme={theme}>
        <ThemeRoutes />
      </ThemeProvider>
    </>
  );
}

export default App;
