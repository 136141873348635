import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Cookies from 'js-cookie';
import CreatProfileRoutes from './CreatProfileRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    var routes = (Cookies.get('jwt') !== "" && Cookies.get('jwt') !== undefined) ? ((Cookies.get("is_profile_complete") != "" && Cookies.get("is_profile_complete") !== undefined) && Cookies.get("is_profile_complete") != "false" ? MainRoutes : CreatProfileRoutes) : AuthenticationRoutes;
    return useRoutes([routes])
}
