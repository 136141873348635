import { Box, CircularProgress, Divider, FormControlLabel, Grid, InputAdornment, InputLabel, LinearProgress, Modal, Paper, Radio, RadioGroup, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import Wrapper from '../../../ui-components/Wrapper'
import bgImage from '../../../assets/login_screen_image.png'
import jaipur from '../../../assets/cities_jaipur.png'
import delhi from '../../../assets/cities_delhi.png'
import ahmedabad from '../../../assets/cities_ahemdabad.png'
import hyderabad from '../../../assets/cities_hyderabad.png'
import mumbai from '../../../assets/cities_mumbai.png'
import kolkata from '../../../assets/cities_kolkatta.png'
import personalInformationImage from '../../../assets/personal_information.png'
import selectCountryImage from '../../../assets/select_country.png'
import startupInformationIcon from "../../../assets/startup_information.png"
import selectCityImage from '../../../assets/select_city.png'
import { BLUEBORDER, B300 as CustomButton } from '../../../ui-components/CustomButton'
import CustomField from '../../../ui-components/CustomField'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import { ReactComponent as gpsIcon } from "../../../assets/gps.svg"
import CustomInputField from '../../../ui-components/formik/CustomInputField'
import CustomUrlField from '../../../ui-components/CustomUrlField'
import CustomLabel from '../../../ui-components/CustomLabel'
import CustomBackgroundComponent from '../../../ui-components/CustomBackgroundComponent'
import { Form, FormikProvider, useFormik, useFormikContext } from 'formik'
import * as Yup from 'yup';
import CustomAutoComplete from '../../../ui-components/CustomAutoComplete'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AddStartup, FetchAllCities, FetchAllCountries, FetchAllCountriesWithPhoneCodes, FetchAllPopularCities, FetchAllPopularCountries, FetchAllStages, FetchAllStates } from '../../../api'
import CustomPhone from '../../../ui-components/CustomPhone'
import Cookies from "js-cookie"
import { LoaderIcon } from 'react-hot-toast'
import { useTheme } from '@emotion/react'
import AuthWrapper1 from '../../../ui-components/AuthWrapper1'
import CustomHeaderIcon from '../../../ui-components/CustomHeaderIcon'
import { containerRef, handleFormSubmit } from '../../../utilities'

import CustomValidationText from '../../../ui-components/CustomValidationText'
import { styled } from '@mui/styles'
import CustomRadio from '../../../ui-components/CustomRadio'
import ContactInformation from './ContactInformation'
import SelectCountry from './SelectCountry'
import SelectStateCity from './SelectStateCity'
import StartupProfile from './StartupProfile'
import { ConnectedFocusError } from 'focus-formik-error'

// import RedirectionPopup from './popups/login/RedirectionPopup'
// import ModalComponent from '../../../ui-components/ModalComponent'

const OtpInputProps = {
    maxLength: 1, // Restrict input to a single character
    inputMode: 'numeric', // Use numeric keyboard on mobile devices
    pattern: "[0-9]*", // Only allow digits 0-9
    // title: "Please enter a number between 0 and 9",
    style: {
        textAlign: 'center', // Center the text in the input
    },
    min: 0,
    onKeyDown: (event) => {
        // Prevent the minus sign (-) from being entered
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Get the input value
        const inputValue = event.key;

        // Allow only numeric characters (0-9) and certain control keys
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab']

        // Check if the pressed key is in the allowedKeys array
        if (!allowedKeys.includes(inputValue)) {
            // If not, prevent the key from being entered
            event.preventDefault();
        }
    },
    onInput: (event) => {
        // Get the input value
        const inputValue = event.target.value;

        // Remove non-numeric characters using a regular expression
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        // Update the input field value with the filtered numeric value
        event.target.value = numericValue;
    },
    onKeyDown: (event) => {
        // Prevent the input of minus sign (key code 189)
        if (event.keyCode === 189 || event.key === "-") {
            event.preventDefault();
        }
    },
}

const numberInputProps = {
    inputMode: 'numeric', // Use numeric keyboard on mobile devices
    pattern: "[0-9]*", // Only allow digits 0-9
    // title: "Please enter a number between 0 and 9",
    style: {
        textAlign: 'center', // Center the text in the input
    },
    min: 0,
    onKeyDown: (event) => {
        // Prevent the minus sign (-) from being entered
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Get the input value
        const inputValue = event.key;

        // Allow only numeric characters (0-9) and certain control keys
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab']

        // Check if the pressed key is in the allowedKeys array
        if (!allowedKeys.includes(inputValue)) {
            // If not, prevent the key from being entered
            event.preventDefault();
        }
    },
    onInput: (event) => {
        // Get the input value
        const inputValue = event.target.value;

        // Remove non-numeric characters using a regular expression
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        // Update the input field value with the filtered numeric value
        event.target.value = numericValue;
    },
    onKeyDown: (event) => {
        // Prevent the input of minus sign (key code 189)
        if (event.keyCode === 189 || event.key === "-") {
            event.preventDefault();
        }
    },
    disableUnderline: true
}
const countryCodes = [
    { code: '+1', label: 'US' },
    { code: '+91', label: 'IN' },
]

const ProfileInformation = ({ progress, setProgress, step, currentStep, setCurrentStep, isLoading, isUpdating, }) => {
    const formik = useFormikContext();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState("")
    const [searchState, setSearchState] = React.useState("")
    const [searchCountry, setSearchCountry] = React.useState("")
    const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: searchCountry })
    const { data: countriesWithPhoneCodes, isRefetching: isRefetchingCountriesPC, isLoading: isLoadingCountriesPC, refetch: refetchCountriesPC } = FetchAllCountriesWithPhoneCodes({})
    const { data: popular_countries, isRefetching: isRefetchingPopularCountries, isLoading: isLoadingPopularCountries, refetch: refetchPopularCountries } = FetchAllPopularCountries()
    // const [savedCountriesList,setSavedCountriesList] = React.useState([])
    const [currentCountry, setCurrentCountry] = React.useState(countries?.find(item => item?._id == formik?.values?.country_id))
    const { data: states, isRefetching: isRefetchingStates, isLoading: isLoadingStates, refetch: refetchStates } = FetchAllStates({ search: searchState, countryID: currentCountry !== null ? countries?.find(item => item?._id == formik.values?.country_id)?.value : currentCountry?.value })
    const [currentState, setCurrentState] = React.useState(states?.find(item => item?.value === formik?.values?.state_id)?.label || '')
    // const [savedCitiesList,setSavedCitiesList] = React.useState([])
    const { data: cities, isRefetching: isRefetchingCities, isLoading: isLoadingCities, refetch: refetchCities } = FetchAllCities({ search, stateID: currentState !== null ? states?.find(item => item?._id == formik.values?.state_id)?.value : currentState?.value, isPopular: 0 });
    const [currentCity, setCurrentCity] = React.useState(cities?.find(item => item?.value === formik?.values?.city_id)?.label || '');
    // console.log(cities?.find(item => item?.value === formik?.values?.city_id),"cities?.find(item => item?.value === formik?.values?.city_id)")
    const { data: startup_stages, isRefetching: isRefetchingStages, isLoading: isLoadingStages, refetch: refetchStages } = FetchAllStages()
    // const { data: round_types, isRefetching: isRefetchingRoundTypes, isLoading: isLoadingRoundTypes, refetch: refetchRoundTypes } = FetchAllStages()

    const handleUpdateCityName = (city) => {
        formik.setFieldValue("city_id", city?.value)
        // console.log(city,"city")
        setCurrentCity({ label: city?.label, value: city?.value })
        setSearch(city?.label)
    }
    const handleUpdateCountryName = (country) => {
        formik.setFieldValue("country_id", country?._id)
        // console.log(country, "country")
        setCurrentCountry(country)
    }
    const handleUpdateStateName = (state) => {
        // formik.setFieldValue("state_id", state?._id)
        // console.log(state, "state")
        // setCurrentState({ label: country?.name, value: country?._id, id: country?.id })
    }
    const handleSearchCityChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearch(value);
        // refetchCities();
    };
    const handleSearchCountryChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchCountry(value);
        // refetchCities();
    };
    const handleSearchStateChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchState(value);
        // refetchCities();
    };
    const handleSelectionCityChange = (event, value) => {
        // console.log(value, "value")

        formik.setFieldValue("city_id", value ? value?._id : '');
        setCurrentCity(value)
        event.preventDefault();
    };
    const handleSelectionCountryChange = (event, value) => {
        // console.log(value, "value")
        formik.setFieldValue("country_id", value ? value?._id : '');
        setCurrentCountry(value)
        formik.setFieldValue("state_id", null);
        setCurrentState(null)
        setCurrentCity(null);
        formik.setFieldValue("city_id", null)
        setSearchState("")
        setSearch("")
        refetchData()
        event.preventDefault();
    };
    const handleSelectionStateChange = (event, value) => {
        // console.log(value, "value")
        formik.setFieldValue("state_id", value ? value?._id : null);
        setCurrentState(value)
        setCurrentCity(null);
        formik.setFieldValue("city_id", null)
        setSearch("")
        refetchData('city')
        event.preventDefault();
    };
    const handleSearchUpdateCity = () => {
        refetchCities();
    }
    const handleSearchUpdateCountry = () => {
        refetchCountries();
    }
    const handleSearchUpdateState = () => {
        refetchStates();
    }
    const refetchData = (type) => {
        if (type === "city") {
            refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 0)
            })
        }
        else {
            formik.values?.country_id != undefined && refetchStates().then(res => {
                setCurrentState(res?.data?.filter(item => item?._id == formik.values.state_id)[0]?.label || '');
                setSearchState("");
                // setSearchState(res?.data?.find(item => item?.value == formik.values.state_id)?.label || '');
            })

            formik.values?.state_id != undefined && refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response?.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 200)
            })

        }
        // refetchPopularCities()
    }

    React.useEffect(() => {
        refetchCountries().then(respo => {
            if (formik.values?.country_id != undefined) {
                refetchStates().then(res => {
                    setCurrentState(res?.data?.filter(item => item?._id == formik.values.state_id)[0])
                }).then(() => {
                    if (formik.values.state_id != undefined) {
                        refetchCities().then(response => {
                            setCurrentCity(response?.data?.find(item => item?._id == formik?.values?.city_id)[0])
                        })
                    }
                })
            }
        })
    }, [])

    // console.log("states",states)

    const theme = useTheme();
    const scrollbarRef = React.useRef(null);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesUpXs = useMediaQuery(theme.breakpoints.up('xs'));
    const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));

    const dimensions = matchUpLg ? { width: "5rem", height: "5rem", marginTop: 'auto' } : matchesUpMd ? { width: "4.5rem", height: "4.5rem", marginTop: 'auto' } : matchesUpMd ? { width: "6rem", height: "6rem", marginTop: 'auto' } : { width: "4rem", height: "4rem", marginTop: 'auto' }

    // const scrollToFirstVisibleValidation = () => {
    //     // Query all elements with the class "validation-message"
    //     const validationMessages = document.querySelectorAll('.validation-message');
    //     // console.log(validationMessages, "dddd")

    //     // Find the first visible validation message
    //     for (const message of validationMessages) {
    //         if (message && message.offsetParent !== null) {
    //             message.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //             break;
    //         }
    //     }
    // };

    return (
        <>

            {/* <Modal open={isLoading == true || isLoadingCountriesPC}>
                <Wrapper style={{
                    display: 'grid', height: '100vh',
                    width: "100%",
                    placeItems: 'center'
                }}>
                    <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} />
                </Wrapper>
            </Modal> */}
            {/* <Grid xs={12} container display={"flex"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }} sx={{
                maxWidth: '1440px !important',
                marginInline: 'auto',
                height: '100%',
                maxHeight: 960
            }}>
                <Grid item xs={12} sm={12} md={6.6} display={"grid"} sx={{ padding: { xs: "1rem 1rem 1rem 1rem", sm: "1rem 2rem", md: "2rem 2rem 1rem 2rem", lg: "2rem 4rem 2rem 5rem" }, height: '100%' }} gap={"1rem"}> */}

            <ConnectedFocusError />
            <Grid xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"column"} sx={{ maxHeight: "80vh", height: '100%', overflow: "hidden" }}>
                <CustomHeaderIcon customIcon={currentStep === 4 ? startupInformationIcon : currentStep === 2 ? selectCountryImage : currentStep === 3 ? selectCityImage : personalInformationImage} />

                <Typography variant='title' sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }} marginTop={1} textAlign={{ xs: "center", md: "start" }} >{currentStep === 4 ? "Startup Information" : currentStep === 2 ? "Select your Country" : currentStep === 3 ? "Select your State & City" : currentStep === 1 ? "Contact Information" : ''}</Typography>
                {currentStep === 1 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginTop={1}>This information will be used to contact you</Typography>}
                {currentStep === 2 && <>
                    <Typography marginBottom={2} color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginTop={1}>Select country where startup is registered</Typography>
                </>}
                {currentStep === 3 && <>
                    <Typography marginBottom={2} color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginTop={1}>Select the state and city where the startup is registered</Typography>
                </>}

                {/* {currentStep === 4 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{xs: "center", md: "start"}} marginTop={1}>Link your linkedin profile</Typography>} */}
                <Box ref={scrollbarRef}
                    //  containerRef={containerRef}
                    style={{
                        minHeight: "45vh", height: '100%',
                        //  maxHeight: 'calc(100vh - 205px)',
                        overflowX: 'hidden', marginTop: currentStep === 2 || currentStep === 3 || currentStep === 4 ? "0rem" : "1rem", paddingBottom: "1rem"
                    }} className="scrollbar-container" id='onboarding-scroller'>
                    {/* <Box sx={{ minHeight: "45vh", height: '100%', overflowX: 'hidden', marginTop: currentStep === 2 || currentStep === 3 || currentStep === 4 ? "0rem" : "1rem", paddingBottom: "1rem" }}> */}
                    {currentStep === 1 &&
                        <>
                            <ContactInformation />
                        </>}
                    {currentStep === 2 &&
                        <>
                            <SelectCountry currentCountry={currentCountry} setCurrentCountry={setCurrentCountry} setSearchCountry={setSearchCountry} />

                        </>}

                    {currentStep === 3 &&
                        <>
                            <SelectStateCity currentCountry={currentCountry} setCurrentCountry={setCurrentCountry} setSearchCountry={setSearchCountry} />

                        </>}
                    {/* </Box> */}
                </Box>
            </Grid>
            <Grid item container maxHeight={"5.5vh"} xs={12} display={"flex"} alignItems={"flex-end"} justifyContent={"center"} gap={3} >
                {currentStep !== 1 && <Grid xs={5} md={5.5} lg={5.65} item>
                    <BLUEBORDER sx={{ textTransform: "none", padding: 2, borderWidth: 1, borderRadius: "8px", height: '56.5px' }} type="no usetr" onClick={(e) => {
                        e.preventDefault()
                        if (currentStep === 1) {
                            Cookies.remove("jwt");
                            navigate("/login");
                        }
                        // else if (currentStep === 3) {
                        //     navigate("/thank-you")
                        // }\
                        else {
                            setCurrentStep(currentStep - 1);
                            // setSearchParams({ page: currentStep - 1 });
                            Cookies.set("step", currentStep - 1)
                        };
                        window.scrollTo(0, 0);
                        //  refetchData();
                    }} fullWidth>Back</BLUEBORDER>
                </Grid>}
                <Grid xs={5} md={5.5} lg={5.65} item>
                    <CustomButton sx={{ padding: 2, borderRadius: "8px", height: '56.5px' }} onClick={(e) => {
                        handleFormSubmit(e, formik)
                        // currentStep == 2 && refetchData();
                        // setCurrentStep(currentStep + 1);
                    }} fullWidth> {isUpdating ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} /> : "Next"} </CustomButton>
                </Grid>
            </Grid>
            {/* </Grid> */}

            {/* <CustomBackgroundComponent />
            </Grid> */}

        </>
    )
}

export default ProfileInformation
