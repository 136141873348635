import { useTheme } from '@emotion/react'
import { Grid ,Box } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'
import CustomUrlField from 'ui-components/CustomUrlField'
import CustomInputField from 'ui-components/formik/CustomInputField'
import CustomLogoButton from 'ui-components/formik/CustomLogoButton'


const FounderInformation = () => {
    const formik =useFormikContext()
    const theme = useTheme()
    

  return (
    <Grid item container xs={12} display={"flex"} gap={"0"} justifyContent={"space-between"} flexDirection={"column"}>
    <Box>
        <Grid xs={12} mb={2}>
            <CustomLogoButton label="PHOTO" handleUpdateFile={(file) => { formik.setFieldValue("founder.image", file) }} name={"founder.image"} value={formik.values?.founder?.image} Pwidth={90} Pheight={90} />
        </Grid>
        <Grid xs={12}>
            <CustomInputField
                label={"NAME"}
                name={`founder.name`}
                value={formik.values?.founder?.name}
                placeholder="Enter founder name"
                onChange={(e) => formik.setFieldValue(`founder.name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))}
            />
        </Grid>
        <Grid xs={12}>
            <CustomInputField
                label={"TITLE"}
                name={`founder.title`}
                value={formik.values?.founder?.title}
                placeholder="Enter founder title"
                onChange={(e) => formik.setFieldValue(`founder.title`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))}
            />
        </Grid>
        <Grid xs={12}>
            <CustomInputField
                label={"BIO"}
                name={`founder.bio`}
                labelStyle={{ marginTop: '0rem' }} onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 250) {
                        formik.setFieldValue("founder.bio", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))
                    }
                    else {
                        formik.setFieldValue("founder.bio", String(e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))?.substring(0, 250))
                    }
                }} multiline minRows={4} maxRows={4} maxLength={250} fullWidth data-problem-solving={250 - formik.values?.founder?.bio?.length + `${(250 - formik.values?.founder?.bio?.length) > 1 ? " characters" : " character"} left`}
                sx={{
                    "::after": {
                        content: 'attr(data-problem-solving)',
                        position: "absolute",
                        bottom: formik.errors.founder?.bio ? 30 : 5,
                        right: 5,
                        background: 'white',
                        color: 'graytext',
                        padding: "0 5px",
                        fontSize: "0.75rem"
                    },
                }}
                value={formik.values?.founder?.bio}
                placeholder="Enter founder bio"
            />
        </Grid>
        <Grid item xs={12} display={"flex"} paddingBottom={2}>
            <CustomUrlField
                label={"LINKEDIN PROFILE URL"}
                placeholder="www.linkedin.com"
                name={`founder.linkedin_url`}
                value={formik.values?.founder?.linkedin_url}
                onChange={(e) => formik.setFieldValue(`founder.linkedin_url`, String(e.target.value)?.startsWith("https://") ? String(e.target.value)?.replace("https://", "") : e.target.value)}
            />
        </Grid>
    </Box>
</Grid>
  )
}

export default FounderInformation
