import { useTheme } from '@emotion/react';
import { Avatar, Box, Chip, Grid, MenuItem, Pagination, Select, Typography, Button, useMediaQuery, Checkbox, Switch, alpha, Modal, Paper } from '@mui/material';
import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';
import PerfectScrollbar from "react-perfect-scrollbar";
import { WHITEBG } from './CustomButton';
import { ReactComponent as PreviousIcon } from "../assets/table-previous.svg";
import { ReactComponent as DownIcon } from "../assets/table-chevron-down.svg";
import { ReactComponent as NextIcon } from "../assets/table-next.svg";
import { containerRef, getFileCategory, stringAvatar } from "../utilities"
import { useNavigate } from 'react-router-dom';
import NoData from './NoData';
import { styled } from '@mui/styles';
import ModalComponent from './ModalComponent'
import UpdateStatusPopup from '../views/rounds/popups/UpdateStatus'
import AddSHADocumentsPopup from '../views/rounds/popups/AddSHADocumentsPopup';
import { Add } from '@mui/icons-material';
import AddInvestmentPopup from '../views/rounds/popups/AddInvestmentPopup';
import AddInvestorPopup from '../views/rounds/popups/AddInvestorPopup';

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#e0e0e0",
        '&:hover': {
            backgroundColor: alpha("#3538CD", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#3538CD",
    },
}));


// function stringAvatar(name) {
//     if (name && name?.includes(" ")) {
//         return {
//             sx: {
//                 bgcolor: "#F9F5FF",
//                 color: "#3538CD",
//                 width: "40px",
//                 height: "40px",
//                 fontSize: '1rem'
//             },
//             children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
//         };
//     } else if (name && !(name?.includes(" "))) {
//         return {
//             sx: {
//                 bgcolor: "#F9F5FF",
//                 color: "#3538CD",
//                 width: "40px",
//                 height: "40px",
//                 fontSize: '1rem'
//             },
//             children: name[0],
//         };
//     }
//     else {
//         return {
//             sx: {
//                 bgcolor: "#F9F5FF",
//                 color: "#3538CD",
//                 width: "40px",
//                 height: "40px",
//                 fontSize: '1rem'
//             },
//             children: '',
//         }
//     }
// }

const StatusChip = ({ cell }) => {
    const [openModal, setOpenModal] = React.useState(false)
    return (
        <>
            {/* <ModalComponent open={openModal} onClose={() => setOpenModal(false)} style={{ maxWidth: 600 }}>
                <Paper>
                    {cell.column.id === "documents" ? <AddSHADocumentsPopup /> : <UpdateStatusPopup handleClose={() => setOpenModal(false)} />}
                </Paper>
            </ModalComponent> */}
            <Chip variant='filled' label={cell?.value} sx={{ marginInline: 'auto', bgcolor: "#F9F5FF", color: "#3538CD" }} clickable onClick={() => { setOpenModal(true) }} />
        </>
    )
}

const CustomTable = ({ columns, data = [], title, actions: Actions, rowActions: RowActions, chipText, pagination = true, emptyTitle = "No Data Available", backgroundColor = '#fff', sx ,tabValue = ""}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const [openAddInvestorModal, setOpenAddInvestorModal] = React.useState(false);

    const handleAddInvestorClose = () => {
        setOpenAddInvestorModal(false)
    }
    const handleAddInvestorSubmit = () => {
        setOpenAddInvestorModal(false)
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // If pagination is disabled, page will equal data
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination // Apply pagination only if enabled
    );
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const DocumentIcon = ({ row, value }) => {
        const theme = useTheme();
        // console.log(row,cell.value,"rc")
        return <Box width={isSmallScreen ? 35 : 40} height={isSmallScreen ? 35 : 40} bgcolor={theme?.palette?.customColors?.indigo100} borderRadius={"50%"} display={"flex"} alignItems="center" justifyContent={"center"}>
            <img src={getFileCategory(value)} width={20} height={20} />
        </Box>
    }


    return (
        <>
        {/* {console.log(tabValue)} */}
            <ModalComponent open={openAddInvestorModal} onClose={handleAddInvestorClose} style={{ maxWidth: 640, width: "100%" }}>
                <AddInvestorPopup value={tabValue} handleClose={handleAddInvestorClose} handleSubmit={handleAddInvestorSubmit} />
            </ModalComponent>
            {data?.length > 0 ? <Grid container xs={12} className='table-container' border={`1px solid ${theme.palette.customColors.gray200}`} borderRadius={sx ? sx?.borderRadius : '8px'} bgcolor={backgroundColor}
                maxHeight={"60vh"}
                height={"100%"} overflow={"hidden"}>
                {title && <Grid container item xs={12} display={"flex"} justifyContent={"space-between"}
                    flexWrap={"nowrap"}
                    padding={"1rem 1.5rem"} alignItems={"center"}
                    maxWidth={"100%"}
                // padding={"1.625rem 1.5rem"}
                >
                    <Box display={"flex"} alignItems={"center"} gap={"0.5rem"} maxWidth={"80%"}>
                        {typeof title === "string" ? <Typography color={theme.palette.customColors.g300} sx={{ fontSize: '18px', fontWeight: 500, lineHeight: "28px", textAlign: 'left' }}>
                            {title}
                        </Typography> : <Box maxWidth={"100%"}> {title} </Box>}
                        {chipText && <Chip label={chipText} size='small' sx={{ padding: '0.125rem 0.5rem !important', color: "#6941C6", bgcolor: '#F9F5FF', "& > .MuiChip-label": { padding: 0 } }} />}
                    </Box>
                    {Actions && <Actions />}
                </Grid>}

                <PerfectScrollbar style={{ height: '100%', maxHeight: matchesUpMd ? title ? '44vh' : '50vh' : title ? '40vh' : '50vh', width: '100%' }}>
                    <table style={{ width: '100%', position: 'relative' }} {...getTableProps()}>
                        <thead style={{ position: 'sticky', zIndex: 1000, top: 0, backgroundColor: theme.palette.background.default }}>
                            {headerGroups?.map(headerGroup => (
                                <tr
                                    key={headerGroup.id}
                                    style={{
                                        fontSize: "12px", fontWeight: 500, lineHeight: '18px', textAlign: "left",
                                        color: theme.palette.customColors.g100
                                    }}
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup?.headers?.map((column, index) => {
                                        if (column?.id === 'checkbox') {
                                            return (
                                                <th style={{ minWidth: column?.minWidth, textAlign: column?.align, position: column.fixed ? 'sticky' : 'initial', left: column.fixed ? 0 : '', zIndex: column.fixed ? 1 : 0 }} align={column?.align} key={column.id} {...column.getHeaderProps()}><Checkbox style={{ height: 20, width: 20, color: "#7c4dff" }} /></th>
                                            )
                                        }
                                        else if (column?.id === 'report_actions') {
                                            {/* console.log(data[0]?.report_id,"column") */ }
                                            return (
                                                <th style={{ minWidth: column?.minWidth, textAlign: column?.align, position: column.fixed ? 'sticky' : 'initial', left: column.fixed ? 0 : '', zIndex: column.fixed ? 1 : 0 }} align={column?.align} key={column.id} {...column.getHeaderProps()}>
                                                    {data?.length > 0 && <Typography
                                                        onClick={() => { navigate(`/reports/edit_report/${data[0]?.report_id}`) }}
                                                        sx={{
                                                            textAlign: "center",
                                                            color: "#0075FF",
                                                            fontSize: '0.75rem',
                                                            cursor: 'pointer'
                                                        }}
                                                    >View All</Typography>}
                                                </th>
                                            )
                                        }
                                        return (
                                            <th style={{ minWidth: column?.minWidth, textAlign: column?.align, position: column.fixed ? 'sticky' : 'initial', left: column.fixed ? 0 : '', zIndex: column.fixed ? 1 : 0 }} align={column?.align} key={column.id} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        )
                                    })}


                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page?.length > 0 && page?.map((row, idx) => {
                                prepareRow(row);
                                return (
                                    <tr key={row.id} {...row.getRowProps()}>
                                        {row?.cells?.map((cell, index) => {
                                            if (cell.column?.id === 'checkbox') {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align }} align={cell.column?.align}><Checkbox style={{ height: 20, width: 20, color: "#7c4dff" }} sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            borderRadius: '8px',  // Change the border-radius value here
                                                        }
                                                    }} /></td>
                                                )
                                            }
                                            if (cell.column.id === "name") {
                                                {/* console.log(cell.row.original.image,"efef") */ }
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            {cell.row.original.image ? <img src={cell.row.original.image} style={{ width: 40, height: 40, background: "#F9F5FF", borderRadius: '50%' }} /> : <Avatar {...stringAvatar(cell.value)} />}
                                                            <Box>
                                                                {cell?.value && cell?.value != "" && <Typography>
                                                                    {cell.value}
                                                                </Typography>
                                                                }<Typography fontSize={cell?.value && cell?.value != "" ? "0.7rem" : '0.9rem'}>
                                                                    {cell.row.original.email}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </td>
                                                );
                                            }

                                            else if (cell.column.id === "document_name") {
                                                const extension = cell?.row?.original?.filename?.split(".")?.pop();
                                                if (cell.column?.trim) {
                                                    return (
                                                        <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                            <Box sx={{
                                                                display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                            }}>
                                                                <DocumentIcon value={String(cell.value)?.substring(0, cell.column?.trim) + "." + extension} row={row} /> {String(cell.value)?.substring(0, cell.column?.trim) + "." + extension}
                                                            </Box>
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <DocumentIcon value={String(cell.value)?.substring(0, cell.column?.trim) + "." + extension} row={row} /> {String(cell.value)?.substring(0, 50) + "." + extension}
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            else if (cell.column.id === "filename") {
                                                const extension = cell?.row?.original?.filename?.split(".")?.pop();
                                                if (cell.column?.trim) {
                                                    return (
                                                        <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                            <Box sx={{
                                                                display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                            }}>
                                                                <DocumentIcon value={String(cell.value)?.substring(0, cell.column?.trim) + "." + extension} row={row} /> {cell.render('Cell')}
                                                            </Box>
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <DocumentIcon value={String(cell.value)?.substring(0, cell.column?.trim) + "." + extension} row={row} /> {cell.render('Cell')}
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            else if (cell.column.id === "actions" || cell.column.id === "report_actions") {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <RowActions cell={cell} row={row} />
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            else if (cell.column.id === "status" || cell.column.id === "pipeline_status" || cell.column.clickable) {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <StatusChip cell={cell} row={row} />
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            else if (cell.column.toggle) {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', justifyContent: cell.column?.align, color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <CustomSwitch checked={cell.value} />
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            if (cell.column.id === "statusWithDate") {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}><Typography>{cell.value}</Typography></td>
                                                );
                                            }
                                            if (cell.column.id === "interestedAmount" || cell.column.id === "allocatedAmount") {
                                                return (
                                                    <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align, position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0 }} align={cell.column?.align}>
                                                        <Box sx={{
                                                            display: 'grid', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            {cell.row?.original?.status != "Invited" && cell?.value != "" ? <Box>
                                                                <Typography>
                                                                    {cell.value}
                                                                </Typography>
                                                                <Typography fontSize={"0.7rem"}>
                                                                    {cell.row.original.date}
                                                                </Typography>
                                                            </Box> : <Box onClick={() => { setOpenAddInvestorModal(true) }} display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{ textDecoration: "underline", textUnderlineOffset: '5px', textDecorationColor: "#3538CD", cursor: 'pointer' }}>
                                                                <Add style={{ width: 20, height: 20, color: "#3538CD" }} />
                                                                <Typography fontSize={"0.9rem"} color={"#3538CD"}> Add </Typography>
                                                            </Box>}
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            return (
                                                <td key={index} {...cell.getCellProps()} style={{ textAlign: cell.column?.align ,position: cell.column.fixed ? 'sticky' : 'initial', left: cell.column.fixed ? 0 : '', zIndex: cell.column.fixed ? 1 : 0}} align={cell.column?.align}>{cell.render('Cell')}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            {page?.length == 0 &&
                                (
                                    <tr>
                                        <td colSpan={columns?.length > 0 ? columns?.length : 1} align='center' style={{ textAlign: 'center' }}>{emptyTitle}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </PerfectScrollbar>

                {pagination && (
                    <Grid container item xs={12} sx={{
                        padding: '12px 24px 16px 24px', display: 'grid', gridTemplateAreas: isSmallScreen ? `"previous page next" "number number number"` : `"previous number page span next"`, gridTemplateColumns: isSmallScreen ? '1fr 2fr 1fr' : '1fr 1fr 10fr 1fr 1fr',
                        gridTemplateRows: isSmallScreen ? '1fr 1fr' : '1fr', borderTop: `1px solid ${theme.palette.customColors.gray200}`, position: 'sticky', bottom: 0, background: theme.palette.customColors.whiteBG,
                        rowGap: 1, columnGap: 1, alignItems: 'center'
                    }}>
                        <Grid item display={"flex"} gap={"0.5rem"} gridArea={'previous'}>
                            <WHITEBG onClick={() => previousPage()} disabled={!canPreviousPage}
                                sx={{
                                    minWidth: "0 !important",
                                    border: `1px solid #D0D5DD !important`,
                                    padding: "8px 14px",
                                    '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' }
                                }}
                                startIcon={<PreviousIcon stroke={!canPreviousPage ? '#D0D5DD' : '#344054'} />}
                            >
                                {isSmallScreen ? '' : "Previous"}
                            </WHITEBG>
                        </Grid>
                        <Grid item gridArea={'number'} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <Select
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                                size="small"
                                IconComponent={DownIcon}
                                sx={{
                                    minWidth: 150,  // Adjust the width as needed
                                    borderRadius: '8px',
                                    border: '1px solid #D0D5DD',  // Border similar to the one in the image
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    color: '#344054',  // Text color
                                    '& .MuiSelect-select': {
                                        padding: '8px 12px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        marginTop: 0,
                                        color: '#344054',  // Arrow icon color
                                    },
                                    '& > .MuiSelect-icon': {
                                        top: '25%'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',  // Remove the default border
                                    },
                                }}
                            >
                                {[10, 20, 25, 30, 40, 50]?.map((size) => (
                                    <MenuItem key={size} value={size}>
                                        {size} Per page
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item display={"flex"} justifyContent={"center"} gridArea={'page'}>
                            <Pagination
                                count={pageCount}
                                page={pageIndex + 1}
                                onChange={(e, page) => gotoPage(page - 1)}
                                shape="rounded"
                                hideNextButton
                                hidePrevButton
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        color: '#6c757d', // Inactive page color
                                        fontWeight: '500',
                                    },
                                    '& .MuiPaginationItem-root.Mui-selected': {
                                        backgroundColor: 'rgba(138, 75, 244, 0.1)', // Light purple background
                                        color: '#8A4BF4', // Active page color
                                    },
                                    '& .MuiPaginationItem-ellipsis': {
                                        color: '#6c757d', // Ellipsis color
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item gridArea={'number'} display={"flex"} alignItems={"center"} justifyContent={"center"}></Grid>
                        <Grid item display={"flex"} flexDirection={"row-reverse"} gridArea={'next'}>
                            <WHITEBG onClick={() => nextPage()} disabled={!canNextPage}
                                sx={{
                                    minWidth: "0 !important",
                                    border: `1px solid #D0D5DD !important`,
                                    padding: "8px 14px",
                                    '& > .MuiButton-icon.MuiButton-endIcon': { marginRight: isSmallScreen ? 0 : '-4px', marginLeft: isSmallScreen ? 0 : '8px' }
                                }}
                                endIcon={<NextIcon stroke={!canNextPage ? '#D0D5DD' : '#344054'} />}
                            >
                                {isSmallScreen ? '' : 'Next'}
                            </WHITEBG>
                        </Grid>
                    </Grid>
                )}
            </Grid> : <NoData Actions={Actions} />}
        </>
    );
};

export default CustomTable;
